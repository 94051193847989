import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { green, yellow } from "@mui/material/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DataTable1 from "components/NewTable/table";
import UserHoc from "hoc/userHoc";
import MDSnackbar from "components/MDSnackbar";
import Loader from "layouts/accountants/user/components/imgLoader/loader";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { eventBus } from "utils/eventbus";
import { canAccess } from "../../../helpers";
import {
  EditClientUserInfo,
  GetclientUserList,
  sendclientReinviteEmailList,
} from "../../../services/clientservices";
import { useAPICall } from "../../../utils/useapicall";
import DashboardLayout from "../../clientuser/user/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../clientuser/user/components/Navbars/DashboardNavbar";
import { useMaterialUIController } from "context";
const cookies = new Cookies();

function User() {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableEmpty, setTableEmpty] = useState("");
  const [search, setSearch] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [clientId, setClientId] = useState();
  const [loaderClass, setLoaderClass] = useState({});
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [controller] = useMaterialUIController();
  const [successMassageText, setSuccessMassageText] = useState("");
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [showReinvoiteEmailDialog, setShowReinvoiteEmailDialog] = useState(false);
  const { darkMode } = controller;
  const [profileInfo, setProfileInfo] = useState(cookies.get("client_profile_info"));
  const action = {
    route: "",
    edittooltip: "Edit",
    resendInvite: "Resend Invite",
    deletetooltip: "Delete",
  };
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetclientUserList, {
    onLoad: false,
  });
  const [
    { isLoading: isRemoveLoading, isError: isRemoveError, response: removeResponse },
    removeClientAPICall,
  ] = useAPICall(EditClientUserInfo, { onLoad: false });
  const [
    {
      isError: isReinviteEmailError,
      isLoading: isReinviteEmailLoading,
      response: ReinviteEmailresponse,
    },
    ReinviteEmailsetAPICall,
  ] = useAPICall(sendclientReinviteEmailList, { onLoad: false });

  useEffect(() => {
    if (isReinviteEmailLoading) {
      return () => null;
    }
    if (isReinviteEmailError) {
      setSuccessMassageText("Faild to send Reinvite email");
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 4000);
      setShowReinvoiteEmailDialog(false);
      setLoading(false);
      return () => null;
    }
    if (ReinviteEmailresponse && ReinviteEmailresponse.data.isSuccess === true) {
      setShowReinvoiteEmailDialog(false);
      setSuccessMassageText("Reinvite email sent successfully");
      setShowSuccessSnackbar(true);
      setTimeout(() => {
        setShowSuccessSnackbar(false);
      }, 4000);
      setLoading(false);
    }
  }, [ReinviteEmailresponse]);
  var resource = "";
  var actions = "";
  const { pathname } = useLocation();
  const limit = localStorage.getItem("PerPagelimit");

  eventBus.$on("onclickchangePages", (data) => OnclickchangePages(data));
  function OnclickchangePages(data) {
    setLoading(true);
    const tt =
      (Number(data) == 0 ? 0 : (Number(data) + 1) * Number(limit) - 10) == -10
        ? 0
        : (Number(data) + 1) * Number(limit) - Number(limit);
    localStorage.setItem("offset", tt);
    setAPICall({
      payload: [{ keyword: `${search}`, offset: tt, limit: limit }],
    });
  }

  eventBus.$on("onclickchangeTotalPerPage", (data) => OnclickchangeTotalPerPage(data));
  function OnclickchangeTotalPerPage(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setLoading(true);
    localStorage.setItem("limit", Number(data));
    localStorage.setItem("offset", 0);
    setAPICall({
      payload: [{ keyword: `${search}`, offset: 0, limit: data }],
    });
  }

  useEffect(async () => {
    localStorage.setItem("PerPagelimit", 10);
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    const ThisOffsetuse = localStorage.getItem("offset");
    if (keyword) {
      setSearch(keyword);
      setLoading(true);
    } else {
      setLoading(true);
      await setAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: limit }],
      });
    }
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    if (!keyword) {
      window.history.pushState("data", "title", `${pathname}`);
    }
  }, [search]);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    loading === true
      ? setLoaderClass({ opacity: "0.6", borderRadius: "13px" })
      : setLoaderClass({});
  }, [loading]);

  useEffect(() => {
    if (isRemoveLoading) {
      return () => null;
    }

    if (isRemoveError) {
      setLoading(true);
      return () => null;
    }

    if (removeResponse && removeResponse.data.isSuccess === true) {
      const ThisOffsetuse = localStorage.getItem("offset");
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 10000);
      setAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: limit }],
      });
      // navigate('/accountants/list');
    }
  }, [removeResponse]);

  useEffect(() => {
    const ThisOffsetuse = localStorage.getItem("offset");
    if (search.length !== 0) {
      const timeOutId = setTimeout(
        async () =>
          await setAPICall({
            payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: limit }],
          }),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  const warningWithConfirmMessage = () => {
    setAlert(true);
  };
  const successDelete = async () => {
    setAlert(false);
    setLoading(true);
    await removeClientAPICall({
      payload: [
        {
          isBlocked: true,
          isArchived: true,
          bussinessId: clientId,
        },
      ],
    });
  };

  const hideAlert = () => {
    setAlert(false);
  };

  function capitalizeFirstLetter(string) {
    if (typeof string !== 'string' || string.length === 0) {
      return ''; // Return an empty string if the input is not valid
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setLoadingCircul(false);
      setLoading(false);
      response?.code === "DATA401" ? navigate("/client/logout") : console.log("somting else");
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setTableEmpty(response.data.data.totalRecords);
      const data = response.data.data.records.map((prop, key) => {
        const Createdate = new Date(prop.createdAt);
        return {
          snum: key + 1,
          firstName: prop.fname,
          business: prop.businessName,
          email: prop.email,
          phone: prop.phone,
          createdat: Createdate.toLocaleDateString(),
          totalaccountants: "-",
          totalclients: "-",
          monthlycost: "-",
          switch:
            prop.joinedAt !== null ? (
              prop.isBlocked ? (
                <MDBox>
                  <MDTypography
                    variant="caption"
                    fontWeight="regular"
                    fontSize="0.875rem"
                    color="error"
                    // sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
                  >
                    Inactive
                  </MDTypography>
                </MDBox>
              ) : (
                <MDBox>
                  <MDTypography
                    variant="caption"
                    fontWeight="regular"
                    fontSize="0.875rem"
                    color="success"
                    // sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
                  >
                    Active
                  </MDTypography>
                </MDBox>
              )
            ) : (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="warning"
                >
                  Pending Confirmation
                </MDTypography>
              </MDBox>
            ),
          role: capitalizeFirstLetter(prop?.permissions[0]?.role),
          action:
            profileInfo && profileInfo.user && profileInfo?.user?._id == prop._id ? (
              ""
            ) : (
              <MDBox display="flex" justifyContent="center">
                {canAccess((actions = "update"), (resource = "employees")) && (
                  <Link to={`/clients/employees/${prop._id}/edit-user`}>
                    <MDTypography
                      variant="body2"
                      color="secondary"
                      sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
                    >
                      <Tooltip title={action.edittooltip} placement="top">
                        <Icon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}>edit</Icon>
                      </Tooltip>
                    </MDTypography>
                  </Link>
                )}
                {canAccess((actions = "delete"), (resource = "employees")) && (
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ marginLeft: "8px", fontSize: "1.20rem" }}
                  >
                    <Tooltip
                      title={action.deletetooltip}
                      placement="top"
                      onClick={(value) => {
                        setClientId(prop._id);
                        warningWithConfirmMessage();
                      }}
                    >
                      <Icon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}>delete</Icon>
                    </Tooltip>
                  </MDTypography>
                )}
                {prop.joinedAt == null && (
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ ml: 1, fontSize: "1.20rem", cursor: "pointer" }}
                  >
                    <Tooltip
                      title={action.resendInvite}
                      placement="top"
                      onClick={(value) => {
                        setClientId(prop._id);
                        setShowReinvoiteEmailDialog(true);
                      }}
                    >
                      {!darkMode ? (
                        <img
                          height={21}
                          width={21}
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD80lEQVR4nO1ZTWgVVxQeW0m1FbGhBbFScCMuFKFVKGjF/m3cdeHClT8LFyVRRKx59yR890UlSnEREJNzRhGEbh74g65UELppGkWjLkxc2EU1gi78pyBiI2fm5eW9eTOZeZN574XyDtzNzP35vnO/c+65M47Tspa1rGUta9n/0YBT8wx4F1keJvBrsjLR0AZdk4cVg2KpCbw5OPAFWR5pOGgb1XgEOLEkoecLbQZyq/mgJYREoS2WAFnpbD5YCW0G3JGAAA83GyhF78JQPAHw5Vm7A1YuJYoDAv84m+LAgO9Snjc7tRiAD3SQAf/dPI/zAwN35+ZC4cPEwIOdvfPAyj6CPG0YeMhTXTOY/xMRidqurr7jnxL4MEH+raPH35Blzh06+XmUrGMJTE3IQ7m8fBt8D8hSXcRYeZsdcHlHkAIdkGXB9XryvJYgVyf71kCgNPmV7l5eFeyXA6/wFp05+CvdvQOrq3YcstybH/xfef+aCRQXeateDzvODeQbY+WP2sFrjeVurN5h/kyl6supelwqAlPBxa+N5QNA/8LKURNzTF5+JiujCcCPal8d44QYcGxxN9xNBLYGPJYtgSkiT/wqsbI2ATBX056xPB4SoOP6TvvEgigzwuCG8urAibMaZXCfrGwJehPgjwliCPK82Iw+q+zjthvwDgKf9zyt5bPuMHjMWDlH1t2umc/vPTHHgH9RWWVMoNRu5PLuT2F61lb+bM/Ro/ONZTJWXsTvtE9ex+jYsKyYFYFSRjHgr6Lm1iRgINdSBPzIfvCXseBnSqBI4h1Z+T2Y073zA/Iw/bz8QC9adSdQtuCbbsu7J2WjMksyLpd31xkMrjFWHoe8vx57vcyKgK/hwQ06p2o+6ZieXvdrHZPDiZWhJMBdjdkByB1fOm57ooC1JQk+VvCRJCDPp7JTfSXU43lfU2Xo7vAFPYn3/nb6k+kcqnIKcc62uhPI5Qd/8OYDnw8hl5tWBjEECHzWqTeBybqJLN8Ler709cPKEYI8qklC/g1trAEE/GxB4JeVBPwCzrtb2BRB7DvhZSQBY+VVPQkAxxb461TXS+SDWz9NGk1AAPJXPSUUS8DGtzgJdWYZxH5hVv6cvys+70tNwPKZSAKd/f0fZfFd1FiB7xB3e2Dxi2VB3JduJ3hrJIHsPu7ybR+o2+5VlZUkaFoAjlae7roI7z8DTi1K+JGXOwjyZ+rALpYS3t2gGshFgnw/GRNB02AOn5N/dRptmpG0EJt5bPFwzf8KsjKVpZbEqWMK/E/ifwT1MgWQ5uu3Ad9MfKFpiJzAXcHApoiAVc1rZnRmm2lJbCDbiufEqCaKYrIY1WJNU2WibNMyp7n2Hl0mzWUZZEXOAAAAAElFTkSuQmCC"
                        ></img>
                      ) : (
                        <img
                          height={21}
                          width={21}
                          style={{ filter: "brightness(0) invert(1)" }}
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC/0lEQVR4nO2ZS2sUQRDHfz7QjYqoKEhWBC/iQRE0gpAoPi/ePHjwZJKDB9F4MugGUfAQ/Qj5AF4Ek6AnDQhe1FV8Htx40IMaIR7iahYhiI601MDQdO/0PHeQ+UPB0t1VXf+equqaWShRokSJEiX+U1SAIaAOtAAvZ2nJ3kPiSyRUgZcdcNqziPKl29X5ZcCrAjjtGUgo30JxrgDOehY560KgXgBHPYs8diFwvwCOeha5hyOOFCwP3gIniIjFovShg45/Ak4DS6I4ri9WNfgCMJej43Oyp17/nYjYHtda4DrwM0PHF4AxYEObsA5FMOP3GeY3ySa/UnT8N3AL2GLYbw/wILDWmYAvU8AOw7ptsmlS56eAnQb7W8X+H219ZAKenPaY5TrfCzyM4bi6bw4Y7K2XUF2w6MUiEGywrgGrNZ1FwHGg4eB4Q9YqHRM2AseAq8B02gR8+Spdot6bLJWyN2PQmZE5tSYK9mvdQSoEfHkPnDSc5gqgBjRFajIWxDpgEJiUk26JqN8TwIBUPsT+GQmrVAn48hw4aolnJUF0ASPAdwe7Pnmlg6UqpkIgWFF2tbGtisDTmK30ZhfnkxLwa/pNQ01X98fnhG1FNQ8Cvqh4PS82uyTMXPR6gR5g1jD3zOX10ktRVAVBYt5VZ7fobLeQuJgXgTeBauOSsJ7IrDhvI9EMVKdMCVwWe4OW+TtyE68MOdAeg25/HgQOi71Jw9ylsDAIITBODgT8vumd4eSRW/wG8CViCHly2WVOwK8WP7Rxv4FTDVucJPbEphXzGRNYJeOmfklJX5sy6kTgScYhFEbAc5DpPD5s+Uk8oY0flPHRBLZvtyOwPKXvolfE3oA2fjeQxKMxn8Qpcvi4+zpwkTW1OXUzh6HXYvcbsMZB/98JqW+RjxIktt9K1Axz6kkcCuSEjj6LzWE6gIo0YknDsh7nv4K0UJWWOK7zH6P8R5AVumN+/X4R5YUma1SkJdYT25aww1IZCwfVEvfLPdGQQjEvv8elVDpVmxJ0EH8B1uk46eiPBnAAAAAASUVORK5CYII="
                        ></img>
                      )}
                    </Tooltip>
                  </MDTypography>
                )}
              </MDBox>
            ),
        };
      });
      setClientList(data);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [response]);

  const clientEmployeeList = {
    columns: [
      {
        Header: "Name",
        accessor: "firstName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Role",
        accessor: "role",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "switch",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Created Date",
        accessor: "createdat",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: clientList,
  };

  const handleSearch = async (event) => {
    localStorage.setItem("offset", 0);
    setLoadingCircul(true);
    window.history.pushState(
      "data",
      "title",
      `${pathname}?keyword=${encodeURIComponent(event.target.value)}`
    );
    setSearch(event.target.value);
    // setLoading(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card style={{ backgroundColor: darkMode ? "#202940" : "#ffffff" }} sx={loaderClass}>
          <MDBox
            pt={3}
            px={2}
            sx={{ pr: 3, mb: 2 }}
            component="form"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h5" fontWeight="medium">
              Users
            </MDTypography>
            <MDBox px={2} display="flex">
              <MDBox sx={{ pr: 3, mb: 2 }}>
                <MDInput
                  type="search"
                  label="Search Users"
                  onKeyDown={handleKeyDown}
                  variant="standard"
                  onChange={handleSearch}
                  InputLabelProps={{
                    style: darkMode
                      ? {
                          color: "#ffffff",
                        }
                      : {},
                  }}
                  sx={{
                    "& input": {
                      color: darkMode ? "#ffffff" : "#000000", // Change input text color
                    },
                  }}
                />
              </MDBox>
              {canAccess((actions = "create"), (resource = "employees")) && (
                <MDBox>
                  <MDButton
                    component={NavLink}
                    to="/client/user/new-user"
                    variant="gradient"
                    color="info"
                    disabled={loading}
                  >
                    {" "}
                    Add New User
                  </MDButton>
                </MDBox>
              )}
            </MDBox>
          </MDBox>
          <MDBox>
            {loadingCircul && (
              <CircularProgress
                size={50}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </MDBox>
          <MDBox>
            <Dialog
              open={showReinvoiteEmailDialog}
              onClose={() => setShowReinvoiteEmailDialog(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",
                    backgroundColor: darkMode ? "#424242 !important" : "#ffffff",
                    color: darkMode ? "#ffffff" : "#000000",
                  },
                },
              }}
            >
              <DialogContent sx={{ overflowY: "hidden" }}>
                <DialogContentText
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 4,
                    color: darkMode ? "#ffffff" : "#000000",
                  }}
                >
                  <h3>Are you sure?</h3>
                </DialogContentText>
                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{
                      fontWeight: 300,
                      fontSize: "0.90rem",
                      textAlign: "center",
                      mt: 4,
                      color: darkMode ? "#ffffff" : "#000000",
                    }}
                  >
                    Want to send the reinvite email?
                  </MDTypography>
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
                <MDButton
                  variant="gradient"
                  color="success"
                  onClick={(value) => {
                    setLoading(true);
                    ReinviteEmailsetAPICall({
                      payload: [{ userId: clientId }],
                    });
                  }}
                >
                  Yes, send it!
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="error"
                  onClick={() => {
                    setLoading(false);
                    setShowReinvoiteEmailDialog(false);
                  }}
                >
                  Cancel
                </MDButton>
              </DialogActions>
            </Dialog>
          </MDBox>
          <MDBox>
            <Dialog
              open={alert}
              onClose={hideAlert}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    height: "auto",
                    maxWidth: "450px",
                    backgroundColor: darkMode ? "#424242 !important" : "#ffffff",
                    color: darkMode ? "#ffffff" : "#000000",
                  },
                },
              }}
            >
              <DialogContent>
                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="body2" color="secondary" sx={{ fontSize: 50 }}>
                    <WarningAmberIcon sx={{ color: yellow[500], fontSize: 50 }} />
                  </MDTypography>
                </DialogContentText>
                <DialogContentText
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    color: darkMode ? "#ffffff" : "#7b809a",
                  }}
                >
                  <h3>Are you sure?</h3>
                </DialogContentText>
                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{
                      fontWeight: 300,
                      fontSize: "0.90rem",
                      textAlign: "center",
                      color: darkMode ? "#ffffff" : "#000000",
                    }}
                  >
                    Once you delete this User there is no way of getting any of the documents or
                    data back?
                  </MDTypography>
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center" }}>
                <MDButton variant="gradient" color="success" onClick={successDelete}>
                  Yes, delete it!
                </MDButton>
                <MDButton variant="gradient" color="error" onClick={hideAlert}>
                  Cancel
                </MDButton>
              </DialogActions>
            </Dialog>
          </MDBox>
          <MDBox>
            <MDSnackbar
              color="success"
              icon="done"
              title="Success"
              // autoHideDuration={3000}
              content={successMassageText}
              open={showSuccessSnackbar}
              close={() => setShowSuccessSnackbar(false)}
            />
          </MDBox>
          <MDBox>
            <MDSnackbar
              color="error"
              icon="warning"
              title="Failed."
              content={successMassageText}
              // autoHideDuration={3000}
              open={showErrorSnackbar}
              close={() => setShowErrorSnackbar(false)}
            />
          </MDBox>
          <MDBox my={5} sx={{ mt: 1 }}>
            <DataTable1
              table={clientEmployeeList}
              entriesPerPage={true}
              pagination={true}
              showTotalEntries={true}
              response={tableEmpty}
              showCheck={false}
              ClientHeigth={true}
            />
          </MDBox>
        </Card>
        <MDBox display="flex" justifyContent="center">
          {loading && <Loader />}
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default UserHoc(User);
