import Slide from "@material-ui/core/Slide";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { green } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DataTable1 from "components/NewTable/table";
import { textEllipsis } from "components/textEllipsis";
import { useMaterialUIController } from "context";
import { inLocalTime } from "utils/timeHelper";
import Hoc from "hoc";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { eventBus } from "utils/eventbus";
import { GetReportDetailsList, GetReportListList, MesageToUploader, CloseReportApi} from "../../../services/adminservices";
import { useAPICall } from "../../../utils/useapicall";
import Loader from "../accountants/components/imgLoader/loader";
import DashboardLayout from "../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../components/Navbars/DashboardNavbar";
import InvoiceDetails from "./invoiceDetails";
import image from "./output-onlinegiftools.gif";

const cookies = new Cookies();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ReportList() {
  const action = {
    route: "",
    edittooltip: "Edit",
    deletetooltip: "Delete",
    billingtooltip: "Billing",
    details: "Details",
    download: "Download",
    error: "Error",
    close: "Close",
  };
  const [openIssueReport, setOpenIssueReport] = useState(false);
  const [openConversation, setOpenConversation] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [search, setSearch] = useState("");
  const [showList, setShowList] = useState([]);
  const { pathname } = useLocation();
  const [loaderClass, setLoaderClass] = useState({});
  const [showErrorList, setShowErrorList] = useState(false);
  const [totalStuck, setTotalStuck] = useState("");
  const PerPagelimit = localStorage.getItem("PerPagelimit");
  const [invoiceId, setInvoiceId] = useState("");
  const [issueList, setIssueList] = useState({});
  const [threadId, setThreadId] = useState("");
  const [showComment, setShowComment] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [openInvoiceDetails, setOpenInvoiceDetails] = useState(false);
  const [reportComment, setReportComment] = useState("");
  const [showError, setShowError] = useState(false);
  const [showCloseDiloag, setShowCloseDiloag] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [reportList, setReportList] = useState([]);
  const [{ isError: isError, isLoading: isLoading, response: response }, repostsListAPICall] = useAPICall(GetReportListList, { onLoad: false });
  const [{ isLoading: isReportmesageToUploaderLoading, isError: isReportmesageToUploaderError, response: ReportmesageToUploaderResponse}, ReportmesageToUploaderAPICall] = useAPICall(MesageToUploader, { onLoad: false });
  const [{ response: reportListResponse }, getReportAPICall] = useAPICall(GetReportDetailsList, {onLoad: false});
  const [{ response: reportListOpenResponse }, getReportOpenAPICall] = useAPICall(GetReportDetailsList,{ onLoad: false });
  const [{isLoading: isCloseReportmesageToUploaderLoading, isError: isCloseReportmesageToUploaderError, response: CloseReportmesageToUploaderResponse}, CloseReportmesageToUploaderAPICall] = useAPICall(CloseReportApi, { onLoad: false });

  useEffect(() => {
    if (isCloseReportmesageToUploaderLoading) {
      return () => null;
    }
    if (isCloseReportmesageToUploaderError) {
      setLoading(false);
      return () => null;
    }

    if (CloseReportmesageToUploaderResponse && CloseReportmesageToUploaderResponse.data.isSuccess === true) {
      const ThisOffsetuse = localStorage.getItem("offset");
      setLoading(true);
      repostsListAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
      });
    }
  }, [CloseReportmesageToUploaderResponse]);

  useEffect(() => {
    if (reportListOpenResponse && reportListOpenResponse.data.isSuccess === true) {
      setInvoiceId(reportListOpenResponse?.data?.data?.report?._id);
      setThreadId(reportListOpenResponse?.data?.data?.report?.latestThreadId);
      setOpenInvoiceDetails(true);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [reportListOpenResponse]);

  useEffect(() => {
    if (reportListResponse && reportListResponse.data.isSuccess === true) {
      setInvoiceId(reportListResponse?.data?.data?.report?._id);
      const LogReportdata = (
        <>
          {reportListResponse?.data?.data?.report?.threads.map((thread, index) => (
            <Paper
              style={{
                padding: "20px 20px",
                marginBottom: "10px",
                display: "flex",
                backgroundColor: "#202940 !important",
              }}
              key={index}
            >
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <Avatar alt="Remy Sharp" sx={{ width: 33, height: 33 }} />
                </Grid>
                <Grid justifyContent="left" item xs zeroMinWidth>
                  <div style={{ display: "flex" }}>
                    <h4
                      style={{
                        margin: 0,
                        textAlign: "left",
                        fontFamily: "Arial",
                        fontSize: "0.775rem",
                        fontWeight: "bold",
                        lineHeight: 1.5,
                        textTransform: "uppercase",
                        letterSpacing: "0.02857em",
                        opacity: 1,
                        textTransform: "none",
                        verticalAlign: "unset",
                        textAlign: "left",
                        marginBottom: "5px",
                      }}
                    >{`${thread?.commentBy?.name}`}</h4>
                    <MDTypography sx={{ mt: 0.5, ml: 1 }} variant="caption" color="text">
                      {inLocalTime(thread.createdAt)}
                    </MDTypography>
                  </div>
                  <p
                    style={{
                      margin: 0,
                      fontFamily: "Arial",
                      fontSize: "0.775rem",
                      fontWeight: 300,
                      lineHeight: 1.5,
                      textTransform: "uppercase",
                      letterSpacing: "0.02857em",
                      opacity: 1,
                      textTransform: "none",
                      verticalAlign: "unset",
                      textAlign: "left",
                    }}
                  >
                    {thread.comment}
                  </p>
                </Grid>
              </Grid>
              <Icon
                onClick={(value) => {
                  setThreadId(thread?._id);
                  setOpenInvoiceDetails(true);
                }}
                sx={{ fontSize: "1.5rem !important", color: darkMode ? "#ffffff" : "" }}
              >
                visibility
              </Icon>
            </Paper>
          ))}
        </>
      );
      setReportList(LogReportdata);
      setOpenConversation(true);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [reportListResponse]);

  useEffect(() => {
    if (isReportmesageToUploaderLoading) {
      return () => null;
    }
    if (isReportmesageToUploaderError) {
      setLoading(false);
      return () => null;
    }

    if (ReportmesageToUploaderResponse && ReportmesageToUploaderResponse.data.isSuccess === true) {
      const ThisOffsetuse = localStorage.getItem("offset");
      setLoading(true);
      repostsListAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
      });
    }
  }, [ReportmesageToUploaderResponse]);

  // <Tooltip title={prop?.comment} placement="top">{textEllipsis(prop?.comment, 70)}</Tooltip>
  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setLoadingCircul(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setTotalStuck(response.data.data.totalRecords);
      const data = response.data.data.records.map((prop, key) => {
        const Createdate = new Date(prop?.createdAt);
        return {
          reporterName: prop?.reporter?.name ? prop?.reporter?.name : "-",
          comment: prop?.comment ? (
            <MDBox>
              <MDTypography
                sx={{
                  fontSize: "0.875rem",
                  cursor: "pointer",
                  color: darkMode ? "#ffffff" : "#7b809a",
                  "&:hover": { color: "#1A73E8 !important" },
                }}
                onClick={(value) => {
                  setCommentText(prop?.comment);
                  setShowComment(true);
                }}
              >
                {" "}
                {textEllipsis(prop?.comment, 70)}
              </MDTypography>
            </MDBox>
          ) : (
            "-"
          ),
          conversations:
            prop?.threads !== 0 ? (
              <MDBox>
                <MDTypography
                  sx={{
                    fontSize: "0.875rem",
                    cursor: "pointer",
                    color: "#1A73E8",
                    "&:hover": { color: "#1A73E8 !important" },
                  }}
                  onClick={(value) => {
                    setLoading(true);
                    if (prop?._id) {
                      getReportAPICall({
                        payload: [{ reportId: prop?._id }],
                      });
                    }
                  }}
                >
                  {prop?.threads}
                </MDTypography>
              </MDBox>
            ) : (
              prop?.threads
            ),
          status: (
            <MDBox>
              <MDTypography sx={{ fontSize: "0.875rem", color: darkMode ? "#ffffff" : "#7b809a" }}>
                {prop?.status.charAt(0).toUpperCase() + prop?.status.slice(1)}
              </MDTypography>
            </MDBox>
          ),
          uploadat: Createdate.toLocaleDateString() ? Createdate.toLocaleDateString() : "-",
          durationhrs: prop?.totalEmployees ? prop?.totalEmployees : "-",
          action:
            prop?.ready !== true ? (
              <MDBox display="flex" justifyContent="center">
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: "pointer" }}
                >
                  <Tooltip
                    title={action.details}
                    placement="top"
                    onClick={(value) => {
                      if (prop?._id) {
                        setLoading(true);
                        getReportOpenAPICall({
                          payload: [{ reportId: prop?._id }],
                        });
                      }
                    }}
                  >
                    <Icon sx={{ color: darkMode ? "#ffffff" : "" }}>visibility</Icon>
                  </Tooltip>
                </MDTypography>
                {/* <MDTypography
                                    variant="body2"
                                    color="secondary"
                                    sx={{ marginLeft: "6px", fontSize: "1.20rem", cursor: 'pointer' }}
                                >
                                    <Tooltip title={action.deletetooltip} placement="top" onClick={(value) => {
                                        // setBusinessName(prop?.businesses[0].name);
                                        setInvoiceId(prop?._id);
                                        setOpenIssueReport(true);
                                    }}>
                                        <Icon sx={{ color: darkMode ? '#ffffff':'' }}>delete</Icon>
                                    </Tooltip>
                                </MDTypography> */}
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{ marginLeft: "6px", fontSize: "1.20rem", cursor: "pointer" }}
                >
                  <Tooltip
                    title={action.close}
                    placement="top"
                    onClick={(value) => {
                      // setBusinessName(prop?.businesses[0].name);
                      setInvoiceId(prop?._id);
                      setShowCloseDiloag(true);
                    }}
                  >
                    <Icon sx={{ color: darkMode ? "#ffffff" : "" }}>close</Icon>
                  </Tooltip>
                </MDTypography>
              </MDBox>
            ) : (
              <div>
                <img
                  width={25}
                  height={25}
                  style={{ marginBottom: "-6px", marginRight: "8px" }}
                  src={image}
                />
              </div>
            ),
        };
      });
      setShowList(data);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [response]);

  eventBus.$on("onclickchangePages", (data) => OnclickchangePages(data));
  function OnclickchangePages(data) {
    setLoading(true);
    const tt =
      (Number(data) == 0 ? 0 : (Number(data) + 1) * Number(PerPagelimit) - 10) == -10
        ? 0
        : (Number(data) + 1) * Number(PerPagelimit) - Number(PerPagelimit);
    localStorage.setItem("offset", Number(tt));
    repostsListAPICall({
      payload: [{ keyword: `${search}`, offset: Number(tt), limit: PerPagelimit }],
    });
  }

  eventBus.$on("onclickchangeTotalPerPage", (data) => OnclickchangeTotalPerPage(data));
  function OnclickchangeTotalPerPage(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setLoading(true);
    localStorage.setItem("PerPagelimit", Number(data));
    localStorage.setItem("offset", 0);
    repostsListAPICall({
      payload: [{ keyword: `${search}`, offset: 0, limit: data }],
    });
  }

  useEffect(async () => {
    localStorage.setItem("PerPagelimit", 10);
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    const ThisOffsetuse = localStorage.getItem("offset");
    if (keyword) {
      setSearch(keyword);
      setLoading(true);
    } else {
      setLoading(true);
      await repostsListAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
      });
    }
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    if (!keyword) {
      window.history.pushState("data", "title", `${pathname}`);
    }
  }, [search]);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    loading === true
      ? setLoaderClass({ opacity: "0.6", borderRadius: "13px" })
      : setLoaderClass({});
  }, [loading]);

  const dataTableData = {
    columns: [
      {
        Header: "Reporter name",
        accessor: "reporterName",
        width: "20%",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "status",
        width: "20%",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "No of Conversations",
        accessor: "conversations",
        width: "20%",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Reported Date",
        accessor: "uploadat",
        width: "20%",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Action",
        accessor: "action",
        width: "20%",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: showList,
  };

  const closeIssuePopup = () => {
    setOpenInvoiceDetails(false);
  };

  const reloadApiAfterClose = () => {
    setOpenInvoiceDetails(false);
  };

  function generate(element) {
    const jsonString = JSON.stringify(issueList, null, 2);
    return <ListItemText primary={<pre>{jsonString}</pre>} />;
  }

  const Demo = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  const onChangeReport = (values) => {
    setShowError(false);
    setReportComment(values?.target?.value);
  };

  const onReportApi = (move) => {
    if (reportComment.length > 0) {
      setShowError(false);
      setOpenIssueReport(false);
      setLoading(true);
      ReportmesageToUploaderAPICall({
        payload: [{ mesageToUploader: reportComment, reportIds: invoiceId }],
      });
    } else {
      setShowError(true);
    }
  };

  const handleClose = (move) => {
    if (reportComment.length > 0) {
      setShowError(false);
      setOpenIssueReport(false);
      setLoading(true);
      CloseReportmesageToUploaderAPICall({
        payload: [{ mesageToUploader: reportComment, reportIds: invoiceId }],
      });
    } else {
      setShowError(true);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card sx={loaderClass} style={{ backgroundColor: darkMode ? "#202940" : "#ffffff" }}>
          <MDBox display="flex" justifyContent="center">
            {loading && <Loader />}
          </MDBox>
          <MDBox
            pt={3}
            px={2}
            sx={{ pr: 3, mb: 2 }}
            component="form"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h5" fontWeight="medium">
              Report comment
            </MDTypography>
            <MDBox px={2} display="flex">
              <MDBox sx={{ pr: 3, mb: 2 }}>
                {/* <MDInput type="search" label="Search Report" onKeyDown={handleKeyDown} variant="standard" onChange={handleSearch} /> */}
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox>
            <Dialog
              open={showErrorList}
              onClose={() => setShowErrorList(false)}
              fullWidth={true}
              maxWidth={"lg"}
            >
              <DialogTitle
                id="alert-dialog-title"
                sx={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                Error
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Demo>
                      <List sx={{ mt: 4, mb: 2, ml: 2, mr: 2, flexBasis: "95%!important" }}>
                        {generate()}
                      </List>
                    </Demo>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <MDButton onClick={() => setShowErrorList(false)}>Close</MDButton>
              </DialogActions>
            </Dialog>
          </MDBox>
          <MDBox>
            {loadingCircul && (
              <CircularProgress
                size={50}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </MDBox>
          <MDBox>
            <Dialog
              open={openIssueReport}
              onClose={() => setOpenIssueReport(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    // height: "45%",
                    maxWidth: "450px",
                    backgroundColor: darkMode ? "#424242" : "#ffffff",
                    color: darkMode ? "#ffffff" : "#000000",
                  },
                },
              }}
            >
              <DialogTitle
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  color: darkMode ? "#ffffff" : "#000000",
                }}
              >
                Leave Your Massage
              </DialogTitle>
              <DialogContent sx={{ overflowY: "hidden" }}>
                <textarea
                  style={{
                    width: "100%",
                    height: "110px",
                    padding: "12px 20px",
                    boxSizing: "border-box",
                    border: "1px solid #ccc",
                    borderRadius: "2px",
                    // backgroundColor: "#f8f8f8",
                    fontSize: "16px",
                    resize: "none",
                  }}
                  id="name"
                  required
                  label="Comment"
                  multiline
                  rows={4}
                  placeholder="Leave Your Massage Here..."
                  fullWidth
                  onChange={onChangeReport}
                  type="textarea"
                  name="name"
                />
                {showError && (
                  <MDTypography
                    sx={{ mt: 1 }}
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {" "}
                    Issue is required.
                  </MDTypography>
                )}
              </DialogContent>
              <DialogActions sx={{ justifyContent: "end", mt: 2 }}>
                <MDButton
                  variant="gradient"
                  color="light"
                  onClick={() => setOpenIssueReport(false)}
                >
                  Cancel
                </MDButton>
                <MDButton variant="gradient" color="info" onClick={onReportApi}>
                  Submit
                </MDButton>
              </DialogActions>
            </Dialog>
          </MDBox>
          <MDBox>
            <Dialog
              open={openConversation}
              onClose={() => setOpenConversation(false)}
              aria-labelledby="alert-dialog-title"
              sx={
                darkMode
                  ? {
                      "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                          width: "100%",
                          // height: "45%",
                          maxWidth: "750px",
                          backgroundColor: "#202940",
                          color: "white !important",
                        },
                      },
                    }
                  : {
                      "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                          width: "100%",
                          // height: "45%",
                          maxWidth: "750px",
                        },
                      },
                    }
              }
            >
              <DialogTitle
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  color: darkMode ? "#ffffff" : "#000000",
                }}
              >
                Report Comment
              </DialogTitle>
              <DialogContent sx={{ overflowY: "hidden" }}>
                <>
                  {
                    <>
                      <MDBox
                        my={3}
                        sx={{ maxHeight: "500px", minHeight: "300px", overflowY: "scroll" }}
                      >
                        {reportList}
                      </MDBox>
                    </>
                  }
                </>
              </DialogContent>
            </Dialog>
          </MDBox>
          <MDBox>
            <Dialog
              fullScreen
              open={openInvoiceDetails}
              onClose={() => setOpenInvoiceDetails(false)}
              TransitionComponent={Transition}
            >
              <DialogContent
                sx={
                  darkMode
                    ? { padding: "10px 0px 0px 0px", backgroundColor: "#202940" }
                    : { padding: "10px 0px 0px 0px" }
                }
              >
                <InvoiceDetails
                  invoiceIds={invoiceId}
                  threadId={threadId}
                  onClickClose={() => closeIssuePopup()}
                  reloadApiAfterClose={reloadApiAfterClose}
                  onClose={() => closeIssuePopup()}
                />
              </DialogContent>
            </Dialog>
            <Dialog
              open={showComment}
              onClose={() => setShowComment(false)}
              aria-labelledby="alert-dialog-title"
              sx={
                darkMode
                  ? {
                      "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                          width: "100%",
                          height: "auto",
                          maxWidth: "600px",
                          backgroundColor: "#424242 !important",
                          color: "white !important",
                        },
                      },
                    }
                  : {
                      "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                          width: "100%",
                          height: "auto",
                          maxWidth: "600px",
                        },
                      },
                    }
              }
            >
              <DialogTitle
                sx={
                  darkMode
                    ? {
                        justifyContent: "center",
                        display: "flex",
                        color: "white !important",
                      }
                    : {
                        justifyContent: "center",
                        display: "flex",
                      }
                }
              >
                Comment
              </DialogTitle>
              <DialogContent
                sx={
                  darkMode
                    ? {
                        justifyContent: "start",
                        display: "flex",
                        mt: 5,
                        color: "white !important",
                      }
                    : {
                        justifyContent: "start",
                        display: "flex",
                        mt: 5,
                      }
                }
              >
                {commentText}
              </DialogContent>
              <DialogActions sx={{ justifyContent: "end", mt: 2 }}>
                <MDButton
                  sx={
                    darkMode
                      ? { color: "#ffffff !important", backgroundColor: "transparent !important" }
                      : { backgroundColor: "transparent !important" }
                  }
                  onClick={() => setShowComment(false)}
                >
                  Close
                </MDButton>
              </DialogActions>
            </Dialog>
          </MDBox>
          <MDBox>
            <Dialog
              open={showCloseDiloag}
              onClose={() => setShowCloseDiloag(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    // height: "45%",
                    maxWidth: "450px",
                    backgroundColor: darkMode ? "#424242 !important" : "#ffffff",
                    color: darkMode ? "#ffffff" : "#000000",
                  },
                },
              }}
            >
              <DialogTitle
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  color: darkMode ? "#ffffff" : "#000000",
                }}
              >
                Leave Your Massage
              </DialogTitle>
              <DialogContent sx={{ overflowY: "hidden" }}>
                <textarea
                  style={{
                    width: "100%",
                    height: "110px",
                    padding: "12px 20px",
                    boxSizing: "border-box",
                    border: "1px solid #ccc",
                    borderRadius: "2px",
                    // backgroundColor: "#f8f8f8",
                    fontSize: "16px",
                    resize: "none",
                  }}
                  id="name"
                  required
                  label="Comment"
                  multiline
                  rows={4}
                  placeholder="Leave Your Massage Here..."
                  fullWidth
                  onChange={onChangeReport}
                  type="textarea"
                  name="name"
                />
                {showError && (
                  <MDTypography
                    sx={{ mt: 1 }}
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {" "}
                    Issue is required.
                  </MDTypography>
                )}
              </DialogContent>
              <DialogActions sx={{ justifyContent: "end", mt: 2 }}>
                <MDButton
                  variant="gradient"
                  color="light"
                  onClick={() => setShowCloseDiloag(false)}
                >
                  Cancel
                </MDButton>
                <MDButton variant="gradient" color="info" onClick={handleClose}>
                  Submit
                </MDButton>
              </DialogActions>
            </Dialog>
          </MDBox>
          <MDBox>
            <MDSnackbar
              color="success"
              icon="done"
              title="Success"
              // autoHideDuration={3000}
              content="Client Deleted Successfully."
              open={showSnackbar}
              close={() => setShowSnackbar(false)}
            />
          </MDBox>
          <MDBox my={5} sx={{ mt: 1 }}>
            <DataTable1
              table={dataTableData}
              entriesPerPage={true}
              pagination={true}
              showTotalEntries={true}
              response={totalStuck}
              showCheck={false}
              ClientHeigth={true}
            />
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Hoc(ReportList);
