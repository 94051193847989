import * as React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { Link, useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import RestoreIcon from "@mui/icons-material/Restore";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import DataTable from "../../../components/Tables/DataTable";
import { useAPICall } from "../../../../utils/useapicall";
import { GetDeleteUserList, EditUserInfo } from "../../../../services/userservices";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import UserHoc from "hoc/userHoc";
import Cookies from "universal-cookie";
const cookies = new Cookies();

function DeleteUser() {
  const navigate = useNavigate();
  const action = { route: "", edittooltip: "Restore", deletetooltip: "Delete" };
  const [alert, setAlert] = useState(false);
  const [adminList, setAdminList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableEmpty, setTableEmpty] = useState("");
  const [profileInfo, setProfileInfo] = useState(cookies.get("user_profile_info"));
  const [search, setSearch] = useState("");
  const [userId, setUserId] = useState();
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetDeleteUserList, {
    onLoad: false,
  });
  const [
    { isLoading: isUserStatusLoading, isError: isUserStatusError, response: UserStatusResponse },
    UserStatusAPICall,
  ] = useAPICall(EditUserInfo, { onLoad: false });

  const warningWithConfirmMessage = async () => {
    setAlert(true);
  };
  const successDelete = async () => {
    await UserStatusAPICall({
      payload: [
        {
          isArchived: false,
          isBlocked: false,
          bussinessId: userId,
        },
      ],
    });
    setAlert(false);
  };
  const hideAlert = () => {
    setAlert(false);
  };

  useEffect(async () => {
    setLoading(true);
    await setAPICall({
      payload: [{ isArchived: "1" }],
    });
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(
      async () =>
        await setAPICall({
          payload: [{ keyword: `${search}`, isArchived: "1" }],
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(() => {
    // setProfileInfo(cookies.get('user_profile_info'));
  }, []);

  useEffect(() => {
    if (isUserStatusLoading) {
      return () => null;
    }

    if (isUserStatusError) {
      response?.code === "DATA401" ? navigate("/user/logout") : console.log("somting else");
      return () => null;
    }

    if (UserStatusResponse && UserStatusResponse.data.isSuccess === true) {
      setLoading(true);
      setAPICall({
        payload: [{ isArchived: "1" }],
      });
    }
  }, [UserStatusResponse]);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      response?.code === "DATA401" ? navigate("/user/logout") : console.log("somting else");
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setTableEmpty(response.data.data.totalRecords);
      const data = response.data.data.records.map((prop, key) => {
        const Createdate = new Date(prop.createdAt);
        return {
          snum: key + 1,
          firstName: prop.fname,
          business: prop.businessName,
          email: prop.email,
          phone: prop.phone,
          createdat: Createdate.toLocaleDateString(),
          totalaccountants: "-",
          totalclients: "-",
          monthlycost: "-",
          switch: prop.isBlocked ? (
            <MDBox>
              <MDTypography
                variant="caption"
                fontWeight="regular"
                fontSize="0.875rem"
                color="error"
                // sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
              >
                Inactive
              </MDTypography>
            </MDBox>
          ) : (
            <MDBox>
              <MDTypography
                variant="caption"
                fontWeight="regular"
                fontSize="0.875rem"
                color="success"
                // sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
              >
                Active
              </MDTypography>
            </MDBox>
          ),
          role: prop.role === null ? "" : prop.role.title == "Domain Admin" ? "Admin" : "User",
          action:
            profileInfo?.user?._id == prop._id ? (
              ""
            ) : (
              <MDBox display="flex">
                <Link
                  to={`/accountants/deleted/list`}
                  onClick={async () => {
                    setUserId(prop._id);
                    warningWithConfirmMessage();
                  }}
                >
                  <MDBox display="flex">
                    <MDTypography
                      variant="body2"
                      color="success"
                      sx={{ fontSize: "1.20rem", cursor: "pointer" }}
                    >
                      <Tooltip title={action.edittooltip} placement="top">
                        <Icon>restore</Icon>
                      </Tooltip>
                    </MDTypography>
                  </MDBox>
                </Link>
              </MDBox>
            ),
        };
      });
      setAdminList(data);
      setLoading(false);
    }
  }, [response]);

  const dataTableData = {
    columns: [
      {
        Header: "Name",
        accessor: "firstName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Role",
        accessor: "role",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "switch",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Created Date",
        accessor: "createdat",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: adminList,
  };

  const handleSearch = async (event) => {
    setSearch(event.target.value);
    // setLoading(true);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox
            pt={3}
            px={2}
            sx={{ pr: 3, mb: 2 }}
            component="form"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h5" fontWeight="medium">
              Archived Users
            </MDTypography>
            <MDBox px={2} display="flex">
              <MDBox sx={{ pr: 3, mb: 2 }}>
                <MDInput
                  type="search"
                  label="Search Users"
                  variant="standard"
                  onChange={handleSearch}
                />
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox>{alert}</MDBox>
          {tableEmpty == "0" ? (
            <MDBox my={5} sx={{ mt: 1 }}>
              <DataTable
                table={dataTableData}
                entriesPerPage={false}
                pagination={false}
                showTotalEntries={true}
              />
              <MDTypography
                variant="body2"
                color="secondary"
                sx={{ marginLeft: "43%", fontSize: "1.20rem", marginTop: "-2%" }}
              >
                No records found
              </MDTypography>
            </MDBox>
          ) : (
            <MDBox my={5} sx={{ mt: 1 }}>
              <DataTable
                table={dataTableData}
                entriesPerPage={false}
                pagination={false}
                showTotalEntries={true}
              />
            </MDBox>
          )}
          <MDBox>
            {loading && (
              <CircularProgress
                size={50}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </MDBox>
          <MDBox>
            <Dialog
              open={alert}
              onClose={hideAlert}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    height: "55%",
                    maxWidth: "510px",
                  },
                },
              }}
            >
              <DialogTitle
                id="alert-dialog-title"
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  fontSize: 70,
                }}
              >
                <RestoreIcon sx={{ color: green[500], fontSize: 50 }} />
              </DialogTitle>
              <DialogContent>
                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                  <h1>Are you sure?</h1>
                </DialogContentText>
                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ fontWeight: 200, fontSize: "1.20rem" }}
                  >
                    Are you sure you want to restore account?
                  </MDTypography>
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center" }}>
                <MDButton variant="gradient" color="success" onClick={successDelete}>
                  Yes, restore it!
                </MDButton>
                <MDButton variant="gradient" color="error" onClick={hideAlert}>
                  Cancel
                </MDButton>
              </DialogActions>
            </Dialog>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default UserHoc(DeleteUser);
