import PropTypes from "prop-types";
import { ErrorMessage, Field } from "formik";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";
import { useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useMaterialUIController } from "context";

function FormField({ label, dateMismatch = false, name, icon, editable = false, ...rest }) {
  const [dottedStyle, setDottedStyle] = useState({});
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  useEffect(() => {
    dateMismatch === true
      ? editable
        ? setDottedStyle({ borderBottom: `2px solid red` })
        : setDottedStyle({ borderBottom: `2px solid red` })
      : editable
      ? setDottedStyle({ borderBottom: `dotted 0.8px` })
      : setDottedStyle({});
  }, [editable, dateMismatch]);
  return (
    <MDBox mb={1.5}>
      {dateMismatch ? (
        <Field
          {...rest}
          name={name}
          as={MDInput}
          variant="standard"
          style={darkMode ? { borderBottom: "2px solid #ffffff" } : dottedStyle}
          label={label}
          fullWidth
          autoComplete="off"
          InputLabelProps={{
            style: { color: "red" },
          }}
          sx={
            darkMode && {
              "& .MuiInputBase-input": {
                padding: `6px 1px 1px`,
                height: "1.8876em",
                color: darkMode ? "#ffffff" : "",
              },
              "& .MuiInputLabel-root.Mui-disabled:before": {
                lineHeight: "32px !important",
              },
              "& .MuiInputLabel-root": {
                lineHeight: "32px !important",
                color: darkMode ? "#ffffff" : "",
              },
              "& ::-webkit-calendar-picker-indicator": {
                filter: darkMode ? "invert(1) brightness(1.5)" : "invert(0) brightness(1)",
              },
            }
          }
          InputProps={{
            disableUnderline: dateMismatch,
            endAdornment: (
              <Tooltip title={"Invoice date not found."} placement="top">
                <InputAdornment position="end">
                  <ErrorOutlineIcon
                    sx={{
                      fontSize: "1rem !important",
                      mr: "14px",
                      color: "red",
                      cursor: "default",
                    }}
                  />
                </InputAdornment>
              </Tooltip>
            ),
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ marginRight: "-1px !important" }}
              ></InputAdornment>
            ),
          }}
        />
      ) : (
        <Field
          {...rest}
          name={name}
          as={MDInput}
          variant="standard"
          style={darkMode ? { borderBottom: "2px solid #ffffff" } : dottedStyle}
          label={label}
          fullWidth
          autoComplete="off"
          sx={
            darkMode && {
              "& .MuiInputBase-input": {
                padding: `6px 1px 1px`,
                height: "1.8876em",
                color: darkMode ? "#ffffff" : "",
              },
              "& .MuiInputLabel-root.Mui-disabled:before": {
                lineHeight: "32px !important",
              },
              "& .MuiInputLabel-root": {
                lineHeight: "32px !important",
                color: darkMode ? "#ffffff" : "",
              },
              "& ::-webkit-calendar-picker-indicator": {
                filter: darkMode ? "invert(1) brightness(1.5)" : "invert(0) brightness(1)",
              },
            }
          }
          InputProps={{
            disableUnderline: editable,
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ marginRight: "-1px !important" }}
              ></InputAdornment>
            ),
          }}
        />
      )}
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormField;
