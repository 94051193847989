import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import VerifiedIcon from '@mui/icons-material/Verified';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { green, yellow } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import logo from "assets/images/logos/paynthr-logo-colored.png";
import colors from "assets/theme-dark/base/colors";
import CustomDialog from "components/CustomDialog";
import CustomDialog2 from "components/CustomDialog/customDialog2";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { SITE_URL } from "helpers/config";
import { User2 } from "helpers/getUser";
import { getAccountantPermmison, getAccountantUserType } from "helpers/getaccountantusertype";
import UserHoc from "hoc/userHoc";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import AddOwnerDetailsDialog from "layouts/clients/add-owner";
import ConnectToQuickBooksOnlineButton from "layouts/clients/components/QBOButton";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { eventBus } from "utils/eventbus";
import { canAccess } from "../../../../helpers";
import { DeleteInvoice, GetInvoiceDetails, GetInvoiceList, GetIssueList, GetStatusCount, GetuserCheckStausAll, GetuserClientListAll, GetuserClientLookeupListAll, MoveMenuInvoice, } from "../../../../services/userservices";
import { useAPICall } from "../../../../utils/useapicall";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import Loader from "../components/imgLoader/loader";
import DraggableNewInvoiceDialog from "./components/draggbleNewInvoiceDialog";
import EditInvoiceDialog from "./editinvoicedialog";
import ErrorEditInvoiceDialog from "./erroreditinvoicedialog";
import DataTable from "./invoiceTableCenter";
import image from "./output-onlinegiftools.gif";
import EditStatementDialog from "./editStatementdialog";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  tabs: {
    "&:hover": {
        ".MuiTab-wrapper": {
          color: "#007bff", // Change label color to blue on hover
        },
      },
    "& .MuiTab-root": {
      backgroundColor: "#202940 !important",
      color: "#ffffff !important",
      marginLeft: "3px !important",
      marginRight: "2px !important",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#202940 !important",
      backgroundColor: "#ffffff !important",
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function AccountantInvoice() {
  const classes = useStyles();
  const cookies = new Cookies();
  const AccountantUserType = getAccountantUserType();
  const AccountantPermmison = getAccountantPermmison();
  const [value, setValue] = useState("Classify");
  const navigate = useNavigate();
  const action = { COMPLETED: "Completed", Qbotooltip: "QBO", INPROGRESS: "In Progress", PENDING: "Pendding", ERROR: "Error", Movetooltip: "Move", showtooltip: "View", edittooltip: "Edit" ,deletetooltip: "Delete"};
  const [InvoiceList, setInvoiceList] = useState([]);
  const [hideNodoc ,setHideNodoc ] = useState(true);
  const [InvoiceListForMatch, setInvoiceListForMatch] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableEmpty, setTableEmpty] = useState("");
  const [clientAssigned, setClientAssigned] = useState([]);
  const [search, setSearch] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showMoveSnackbar, setShowMoveSnackbar] = useState(false);
  const [showDeleteSnackbar, setShowDeleteSnackbar] = useState(false);
  const [showErrorMoveSnackbar, setShowErrorMoveSnackbar] = useState(false);
  const [showErrorDeleteSnackbar, setShowErrorDeleteSnackbar] = useState(false);
  const [showCompleteSnackbar, setShowCompleteSnackbar] = useState(false);
  const [moveErrorMessage, setMoveErrorMessage] = useState("");
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
  const [reloadApiClientApi, setReloadApiClientApi] = useState(0);
  const [uploadPermison, setUploadPermison] = useState(true);
  const [unassignedOwners, setUnassignedOwners] = useState([]);
  const [selectedUnAssignedOwner, setSelectedUnAssignedOwner] = useState([]);
  const [profileInfo, setProfileInfo] = useState(cookies.get("user_profile_info"));
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetInvoiceList, {onLoad: false});
  const [{ isLoading: isCountLoading, isError: isCountError, response: CountResponse },CountUserAPICall,] = useAPICall(GetStatusCount, { onLoad: false });
  const [{ isLoading: isClientLoading, isError: isClientError, response: ClientResponse },ClientUserAPICall] = useAPICall(GetuserClientListAll, { onLoad: false });
  const [openInvoice, setOpenInvoice] = useState(false);
  const [openEditInvoice, setOpenEditInvoice] = useState(false);
  const [openEditStatement, setOpenEditStatement] = useState(false);
  const [countAll, setCountAll] = useState(0);
  const [completemassage, setCompletemassage] = useState("");
  const [countApproved, setCountApproved] = useState(0);
  const [countUnreconciled, setCountUnreconciled] = useState(0);
  const [counReconciled, setCounReconciled] = useState(0);
  const [countAskClient, setCountAskClient] = useState(0);
  const [countClassify, setCountClassify] = useState(0);
  const [countExcluded, setCountExcluded] = useState(0);
  const [countPendingApproval, setCountPendingApproval] = useState(0);
  const [countToReview, setCountToReview] = useState(0);
  const [invoiceId, setInvoiceId] = useState("");
  const [issueType, setIssueType] = useState("");
  const [selectedId, setSelectId] = useState("");
  const [startSearchLoader , setStartSearchLoader] = useState(false);
  const [headerFilter, setHeaderFilter] = useState({});
  const [IssuesExist, setIssuesExist] = useState(false);
  const [showrowsData, setShowrowsData] = useState([]);
  const [showrowsDataobj, setShowrowsDataObj] = useState([]);
  const [clientResponce, setClientResponce] = useState([]);
  const [autoCompleteObj, setAutoCompleteObj] = useState([]);
  const [autoCompleteObjForSelect, setAutoCompleteObjForSelect] = useState([]);
  const [showselectedrowsData, setShowSelectedrowsData] = useState("");
  const [businessId, setBusinessId] = useState(location.pathname.split("/").slice(-1)[0]);
  const [onChangeBusinessId, setOnchageBusinessId] = useState("");
  const [openSelectInvoice, setOpenSelectInvoice] = useState(false);
  const [openSelectForInvoiceUpload, setOpenSelectForInvoiceUpload] = useState(false);
  const [openNoInvoice, setOpenNoInvoice] = useState(false);
  const [uploadInvoice, setUploadInvoice] = useState(false);
  const [clientEvent, setClientEvent] = useState("");
  const [clientNewValue, setClientNewValue] = useState("");
  const [clientEventSelect, setClientEventSelect] = useState("");
  const [clientNewValueSelect, setClientNewValueSelect] = useState("");
  const [selectTab, setSelectTab] = useState("Classify");
  const [syncPercent, setSyncPercent] = useState(0);
  const [SelectedRows, setSelectedRows] = useState([]);
  const [issueList, setIssueList] = useState([]);
  const [onIssueClick, setOnIssueClick] = useState(false);
  const [onIssueClickData, setOnIssueClickData] = useState([]);
  const [documentsId, setDocumentsId] = useState();
  const [selectUseEffect, setSelectUseEffect] = useState();
  const [alertDeleteInvoice, setAlertDeleteInvoice] = useState(false);
  const [alertMoveInvoice, setalertMoveInvoice] = useState(false);
  const [disableMoveButton, setDisableMoveButton] = useState(false);
  const [alertIssueInvoice, setalertIssueInvoice] = useState(false);
  const [tabsMove, setTabsMove] = useState("");
  const [titleShow, setTitleShow] = useState("success");
  const [disButton, setDisButton] = useState(false);
  const [showSelectBusiness, setShowSelectBusiness] = useState(false);
  const [iconShow, setIconShow] = useState("done");
  const [invoiceStatusId, setInvoiceStatusId] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState(0);
  const [StoreInvoiceNumber, setStoreInvoiceNumber] = useState("");
  const [storeResponce, setStoreResponce] = useState({});
  const [moduleTypeEdit, setModuleTypeEdit] = useState("");
  const [issueExist, setIssueExist] = useState(false);
  const [movePermmison, setMovePermmison] = useState("");
  const [invalidDocumnet, setInvalidDocumnet] = useState(false);
  const [openErrorEditInvoice, setErrorOpenEditInvoice] = useState(false);
  const [openNoClient, setOpenNoClient] = useState(false);
  const [ownerAssigned, setOwnerAssigned] = useState(false);
  const [assignNewClient, setAssignNewClient] = useState(false);
  const [onwerNotAssigned, setOnwerNotAssigned] = useState(false);
  const [showNotAssignList, setShowNotAssignList] = useState(false);
  const [assignNewClient1, setAssignNewClient1] = useState(false);
  const [ownerName, setOwnerName] = useState("");
  const [dltButton, setDltButton] = useState(false);
  const [availableStatuses, setAvailableStatuses] = useState([]);
  const clientSelect = localStorage.getItem('clientSelect');
  const [currentRecordIndex, setCurrentRecordIndex] = useState(0);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const queryParameters = new URLSearchParams(window.location.search);
  const currentTabs = queryParameters.get("t");

  const [{ isLoading: isDeleteInvoiceLoading, isError: isDeleteInvoiceError, response: DeleteInvoiceResponse }, DeleteInvoiceListAPICall] = useAPICall(DeleteInvoice, { onLoad: false });
  const [{ isLoading: isDetailsLoading, isError: isDetailsError, response: DetailsResponse }, DetailsUserAPICall] = useAPICall(GetInvoiceDetails, { onLoad: false });
  const [{ isLoading: isMoveMenuLoading, isError: isMoveMenuError, response: MoveMenuResponse }, MoveMenuListAPICall] = useAPICall(MoveMenuInvoice, { onLoad: false });
  const [{ isLoading: isIssueLoading, isError: isIssueError, response: IssueResponse }, IssueListAPICall] = useAPICall(GetIssueList, { onLoad: false });
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const ReturnUrl = encodeURI(`${SITE_URL}/qbo-cb`);
  const [loaderClass, setLoaderClass] = useState({});
  const { pathname } = useLocation();
  const checkIsProdServer = process.env.REACT_APP_ENV == "production" ? "production" : "development";
  const limit = localStorage.getItem("limit");
  const ThisOffsetuseAll = localStorage.getItem("offset");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const newOffsetForAll = limit == 10 ? ThisOffsetuseAll : limit;
  const [{ isLoading: isClientLookeupLoading, isError: isClientLookeupError, response: ClientLookeupResponse },ClientLookeupUserAPICall] = useAPICall(GetuserClientLookeupListAll, { onLoad: false });
  const [{ isLoading: isCheckStausLoading, isError: isCheckStausError, response: CheckStausResponse },CheckStausUserAPICall] = useAPICall(GetuserCheckStausAll, { onLoad: false });

  useEffect(()=>{
    localStorage.setItem('ThemeMode',darkMode);
  },[darkMode])

  useEffect(() => {
    if (isCheckStausLoading) {
      return () => null;
    }

    if (isCheckStausError) {
      return () => null;
    }

    if (CheckStausResponse && CheckStausResponse.data.isSuccess === true) {
        const allCompleted = CheckStausResponse?.data?.data.every(item => item.ocrStatus === "COMPLETED");
        const allNotCompleted = CheckStausResponse?.data?.data.every(item => item.ocrStatus !== "COMPLETED");
        if (allCompleted) {
            localStorage.removeItem("idsArray");
        } 
        if(CheckStausResponse?.data?.data?.ocrStatus ==  "COMPLETED" || CheckStausResponse?.data?.data?.ocrStatus !==  "COMPLETED"){
          eventBus.$emit("DisableAllCheckBox", false);
          const limit = localStorage.getItem("limit");
          const queryParameters = new URLSearchParams(window.location.search);
          const LocalTab = localStorage.getItem("Tabs");
          const keyword = queryParameters.get("k");
          const Tabs = queryParameters.get("t");
          const offsets = queryParameters.get("o");
          const documentId = queryParameters.get("document");
          const storedData = JSON.parse(localStorage.getItem('CoulmnFilterObj'));
          const clientSelect = localStorage.getItem('clientSelect');
          setAPICall({
            payload: [
              {
                clientId:  clientSelect,
                invoiceStatus: LocalTab ? LocalTab : Tabs,
                limit: limit,
                keyword: keyword,
                offset: offsets ? offsets :ThisOffsetuseAll,
                vendorName:storedData?.vendor ,
                invoiceNo:storedData?.invoice ,
                createdAt:storedData?.uplaoddate ,
                ocrStatus:storedData?.SELECT,
                clientName:storedData?.clientName,
              },
            ],
          })
          CountUserAPICall({
            payload: [{ businessId: clientSelect }],
          });
        }
    }
  }, [CheckStausResponse]);

  useEffect(() => {
    if (isClientLookeupLoading) {
      return () => null;
    }

    if (isClientLookeupError) {
      setMoveErrorMessage('something went to wrong')
      setShowErrorDeleteSnackbar(true);
      setTimeout(() => {
        setShowErrorDeleteSnackbar(true);
      }, 2000);
      return () => null;
    }

    if (ClientLookeupResponse && ClientLookeupResponse.data.isSuccess === true) {
      const array = ClientLookeupResponse.data.data.records.map((obj) => Object.values(obj));
      array.unshift(["All", "All"]);
      setShowrowsData(array);
      setShowrowsDataObj(array);
      var obj = array.map((x) => ({
        label: x[1],
      }));
      if (array.length > 1) {
        setShowSelectBusiness(true);
      }
      setAutoCompleteObj(obj);
      const arraySelect = ClientLookeupResponse.data.data.records.map((obj) => Object.values(obj));
      var objSelect = arraySelect.map((x) => ({
        label: x[1],
      }));
      setAutoCompleteObjForSelect(objSelect);
    }
  }, [ClientLookeupResponse]);

  useEffect(async () => {
    localStorage.setItem("PerPagelimit", 10);
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("k");
    const Tabs = queryParameters.get("t");
    if (Tabs) {
      setValue(Tabs);
      setSelectTab(Tabs);
    } else {
      setValue("Classify");
      setSelectTab("Classify");
    }
    if (keyword) {
      setSearch(keyword);
      setLoading(true);
    } else {
      if (businessId !== ":id") {
        setLoading(true);
        await setAPICall({
          payload: [
            {
              clientId: businessId,
              invoiceStatus: Tabs ? Tabs : selectTab,
              offset: 0,
              limit: 10,
              keyword: `${keyword ? keyword : search}`,
              clientName:headerFilter?.clientName,
              vendorName: headerFilter?.vendor,
              invoiceNo: headerFilter?.invoice,
              createdAt: headerFilter?.uplaoddate,
              ocrStatus: headerFilter?.SELECT,
            },
          ],
        });
      }
    }
  }, []);

  useEffect(() => {
    const found1 = clientResponce.find((obj) => {
      return obj.name === clientNewValue.label;
    });
    setSyncPercent(found1?.syncPercent ? found1?.syncPercent : 0);
  }, [clientNewValue]);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("k");
    const Tabs = queryParameters.get("t");
    if (Tabs && !keyword) {
      window.history.pushState("data", "title", `${pathname}?t=${encodeURIComponent(Tabs)}`);
    }
  }, [search]);

  useEffect(() => {
    const fetchData = async () => {
      await ClientUserAPICall({
        payload: [{ keyword: `` }],
      });
    };
  
    const interval = setInterval(() => {
       fetchData(); 
    }, 180000); 
  
    return () => clearInterval(interval); 
  }, [reloadApiClientApi]);
  

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    loading === true
      ? setLoaderClass({ opacity: "0.4", borderRadius: "13px" })
      : setLoaderClass({});
  }, [loading]);

  var resource = "";
  var actions = "";
  useEffect(() => {
    localStorage.setItem("limit", 10);
    const urlParams = new URL(window.location.href).searchParams;
    const DocId = urlParams.get("id");
    if (DocId) {
      setLoading(true);
      DetailsUserAPICall({
        payload: [{ InvoiceId: `${DocId}`, businessId: `${businessId}` }],
      });
      setInvoiceId(DocId);
    }
  }, []);

  const removeQueryParams = () => {
    const param = new URL(window.location.href).searchParams;
    if (param) {
      searchParams.delete("id");
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    Object.keys(User2).map((key) =>
      AccountantPermmison[0]?.role === key ? setMovePermmison(User2[key]) : ""
    );
  }, []);

  const Modalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "0",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
    outline: "none",
  };

  const DarkModalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    color: "#FFFFFF",
    bgcolor: colors.background.card,
    border: "0",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
    outline: "none",
  };

  useEffect(() => {
    if (isDetailsLoading) {
      return () => null;
    }

    if (isDetailsError) {
      removeQueryParams();
      setInvalidDocumnet(true);
      setTimeout(() => {
        setInvalidDocumnet(false);
      }, 5000);
      return () => null;
    }

    if (DetailsResponse && DetailsResponse.data.isSuccess === true) {
      setOpenEditInvoice(true);
    }
  }, [DetailsResponse]);
  useEffect(() => {
    if (isIssueLoading) {
      return () => null;
    }
    if (isIssueError) {
      setLoading(false);
      return () => null;
    }
    if (IssueResponse && IssueResponse.data.isSuccess === true) {
      setIssueList(IssueResponse?.data?.data?.records);
      setalertIssueInvoice(true);
      setLoading(false);
    }
  }, [IssueResponse]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    const found1 = issueList.find((obj) => {
      return obj._id === index;
    });

    const findDocumentType = (found1, InvoiceList) => {
      const matchedInvoice = InvoiceList.find(invoice => invoice.id === found1.documentId);
      return matchedInvoice ? matchedInvoice.documentType : null;
    };

    const documentType = findDocumentType(found1, InvoiceList);
    if(documentType !== 'STATEMENT'){
      setBusinessId(found1?.clientId);
      setSelectId(found1?._id);
      setIssueType(found1?.issueType);
      setInvoiceId(found1?.documentId);
      setOnIssueClickData(found1);
      setOpenEditInvoice(true);
      setOnIssueClick(true);
      setModuleTypeEdit(found1?.moduleType);
    }

  };

  function generate(element) {
    return issueList.map((values, index) => (
      <ListItemButton
        style={{
          "&:hover": {
            backgroundColor: darkMode ? "#ffffff !important" : "#f0f0f0",
          },
        }}
        selected={selectedIndex === values._id}
        onClick={(event) => handleListItemClick(event, values._id)}
      >
        <CircleIcon sx={{ mr: 1, fontSize: "small" }} />
        <ListItemText primary={`${values.message}`} />
      </ListItemButton>
    ));
  }

  const Demo = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  useEffect(() => {
    if (isMoveMenuLoading) {
      return () => null;
    }
    if (isMoveMenuError) {
      setDisableMoveButton(false);
      setLoading(false);
      if (response?.data?.message) {
        setMoveErrorMessage(response.data.message);
      }
      setShowErrorMoveSnackbar(true);
      setTimeout(() => {
        setShowErrorMoveSnackbar(false);
      }, 10000);
      setalertMoveInvoice(false);
      return () => null;
    }
    if (MoveMenuResponse && MoveMenuResponse.data.isSuccess === true) {
      setLoading(true);
      const ThisOffsetuse = localStorage.getItem("offset");
      CountUserAPICall({
        payload: [{ businessId: clientSelect }],
      });
      setalertMoveInvoice(false);
      setShowMoveSnackbar(true);
      setDisableMoveButton(false);
      setTimeout(() => {
        setShowMoveSnackbar(false);
      }, 10000);
      setAPICall({
        payload: [
          {
            clientId: clientSelect == 'All' ? 'All' : `${businessId}`,
            invoiceStatus: selectTab,
            limit: limit,
            offset: ThisOffsetuse,
            keyword: `${search}`,
            vendorName: headerFilter?.vendor,
            invoiceNo: headerFilter?.invoice,
            createdAt: headerFilter?.uplaoddate,
            ocrStatus: headerFilter?.SELECT,
            clientName:headerFilter?.clientName,
          },
        ],
      });
    }
  }, [MoveMenuResponse]);

  useEffect(() => {
    if (isDeleteInvoiceLoading) {
      return () => null;
    }
    if (isDeleteInvoiceError) {
      setLoading(false);
      setDltButton(false);
      if (response?.data?.message) {
        setDeleteErrorMessage(response.data.message);
      }
      setShowErrorDeleteSnackbar(true);
      setTimeout(() => {
        setShowErrorDeleteSnackbar(false);
      }, 10000);
      setAlertDeleteInvoice(false);
      return () => null;
    }
    if (DeleteInvoiceResponse && DeleteInvoiceResponse.data.isSuccess === true) {
      const ThisOffsetdelete = localStorage.getItem("offset");
      setAPICall({
        payload: [
          {
            clientId: `${businessId}`,
            invoiceStatus: selectTab,
            limit: limit,
            offset: ThisOffsetdelete,
            keyword: `${search}`,
            vendorName: headerFilter?.vendor,
            invoiceNo: headerFilter?.invoice,
            createdAt: headerFilter?.uplaoddate,
            ocrStatus: headerFilter?.SELECT,
            clientName:headerFilter?.clientName,
          },
        ],
      });
      CountUserAPICall({
        payload: [{ businessId: clientSelect }],
      });
      // setLoading(false);
      setDltButton(false);
      setAlertDeleteInvoice(false);
      setShowDeleteSnackbar(true);
      setTimeout(() => {
        setShowDeleteSnackbar(false);
      }, 10000);
    }
  }, [DeleteInvoiceResponse]);

  const [anchor, setAnchor] = useState(null);
  const [selected, setSelected] = useState(-1);
  const [showApprovedModel, setShowApprovedModel] = useState(false);
  const [loadingCircul, setLoadingCircul] = useState(false);

  useEffect(async () => {
    const ThisOffsetuse = localStorage.getItem("offset");
    if (onChangeBusinessId) {
      await setAPICall({
        payload: [
          {
            clientId: `${onChangeBusinessId}`,
            invoiceStatus: selectTab,
            limit: limit,
            offset: ThisOffsetuse,
            keyword: `${search}`,
            vendorName: headerFilter?.vendor,
            invoiceNo: headerFilter?.invoice,
            createdAt: headerFilter?.uplaoddate,
            ocrStatus: headerFilter?.SELECT,
            clientName:headerFilter?.clientName,
          },
        ],
      });
      await CountUserAPICall({
        payload: [{ businessId: `${clientSelect}` }],
      });
      setBusinessId(onChangeBusinessId);
      setLoading(true);
      setValue(selectTab);
      navigate(`/accountant/document/${onChangeBusinessId}`);
    }
  }, [onChangeBusinessId]);

  eventBus.$on("onclickchangePage", (data) => OnclickchangePage(data));
  function OnclickchangePage(data) {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("k");
    const Tabs = queryParameters.get("t");
    const tt = (Number(data) == 0 ? 0 : (Number(data) + 1) * Number(limit) - 10) == -10 ? 0 : (Number(data) + 1) * Number(limit) - Number(limit);
    window.history.pushState("data","title",`${pathname}?k=${encodeURIComponent(keyword)}&t=${encodeURIComponent(Tabs)}&o=${tt}`);
    localStorage.setItem("offset", tt);
    setLoading(true);
    setAPICall({
      payload: [
        {
          clientId:  clientSelect == 'All' ? 'All' :`${businessId}`,
          invoiceStatus: selectTab,
          limit: limit,
          offset: tt,
          keyword: `${search}`,
          vendorName: headerFilter?.vendor,
          invoiceNo: headerFilter?.invoice,
          createdAt: headerFilter?.uplaoddate,
          ocrStatus: headerFilter?.SELECT,
          clientName:headerFilter?.clientName,
        },
      ],
    });
  }

  eventBus.$on("onclickchangeTotalPerPage", (data) => OnclickchangeTotalPerPage(data));
  function OnclickchangeTotalPerPage(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setLoading(true);
    localStorage.setItem("limit", Number(data));
    localStorage.setItem("offset", 0);
    setAPICall({
      payload: [
        {
          clientId:  clientSelect == 'All' ? 'All' :`${businessId}`,
          invoiceStatus: selectTab,
          limit: data,
          offset: 0,
          keyword: `${search}`,
          vendorName: headerFilter?.vendor,
          invoiceNo: headerFilter?.invoice,
          createdAt: headerFilter?.uplaoddate,
          ocrStatus: headerFilter?.SELECT,
          clientName:headerFilter?.clientName,
        },
      ],
    });
  }

  eventBus.$on("refreshApiInvoiceList", (data) => refreshApiInvoiceList(data));
  function refreshApiInvoiceList(data) {
    setLoading(false);
    const Offsetrefresh = localStorage.getItem("offset");
    setAPICall({
      payload: [
        {
          clientId: `${clientSelect}`,
          invoiceStatus: selectTab,
          limit: limit,
          offset: Offsetrefresh,
          keyword: `${search}`,
          vendorName: headerFilter?.vendor,
          invoiceNo: headerFilter?.invoice,
          createdAt: headerFilter?.uplaoddate,
          ocrStatus: headerFilter?.SELECT,
          clientName:headerFilter?.clientName,
        },
      ],
    });
  }

  eventBus.$on("disableclosebutton", (data) => disableclosebutton(data));
  function disableclosebutton(data) {
    setDisButton(data);
  }

  eventBus.$on("refreshListAfterUpdate", (data) => RefreshListAfterUpdate(data));
  function RefreshListAfterUpdate(data) {
    setLoading(false);
    const OffsetRe = localStorage.getItem("offset");
    setAPICall({
      payload: [
        {
          clientId: `${clientSelect}`,
          invoiceStatus: selectTab,
          limit: limit,
          offset: OffsetRe,
          keyword: `${search}`,
          vendorName: headerFilter?.vendor,
          invoiceNo: headerFilter?.invoice,
          createdAt: headerFilter?.uplaoddate,
          ocrStatus: headerFilter?.SELECT,
          clientName:headerFilter?.clientName,
        },
      ],
    });
    CountUserAPICall({
      payload: [{ businessId: `${clientSelect}` }],
    });
  }

  eventBus.$on("closeDeleteItemPopup", (data) => CloseInvoicesPopup(data));
  function CloseInvoicesPopup(data) {
    setOpenEditInvoice(false);
    const ThisOffsetevent = localStorage.getItem("offset");
    setLoading(false);
    setAPICall({
      payload: [
        {
          clientId:  clientSelect == 'All' ? 'All' : `${businessId}`,
          invoiceStatus: selectTab,
          limit: limit,
          offset: ThisOffsetevent,
          keyword: `${search}`,
          vendorName: headerFilter?.vendor,
          invoiceNo: headerFilter?.invoice,
          createdAt: headerFilter?.uplaoddate,
          ocrStatus: headerFilter?.SELECT,
          clientName:headerFilter?.clientName,
        },
      ],
    });
    CountUserAPICall({
      payload: [{ businessId: `${clientSelect}` }],
    });
  }

  eventBus.$on("closeNewInvoicePopup", (data) => fetchNewInvoices(data));
  function fetchNewInvoices(data) {
    eventBus.$emit("disableclosebutton", false);
    setOpenInvoice(false);
    if (data?.code === "ERR_BAD_REQUEST" || data?.code === "ERR_BAD_RESPONSE") {
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 5000);
      setTimeout(() => {
        setOpenInvoice(false);
      }, 5000);
    } else {
      // setUploadInvoice(true);
      setOpenInvoice(false);
      setLoading(true);
    }
  }

  eventBus.$on("closeNewInvoicePopup1", (data) => closeNewInvoicePopup1(data));
  function closeNewInvoicePopup1(data) {
    eventBus.$emit("disableclosebutton", false);
    setOpenInvoice(false);
    if (data?.code === "ERR_BAD_REQUEST" || data?.code === "ERR_BAD_RESPONSE") {
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 5000);
      setTimeout(() => {
        setOpenInvoice(false);
      }, 5000);
    } else {
      setUploadInvoice(true);
      setOpenInvoice(false);
      setLoading(true);
      const ThisOffseteventclose = localStorage.getItem("offset");
        setAPICall({
          payload: [
            {
              clientId: clientSelect,
              invoiceStatus: selectTab,
              limit: limit,
              offset: ThisOffseteventclose,
              keyword: `${search}`,
              vendorName: headerFilter?.vendor,
              invoiceNo: headerFilter?.invoice,
              createdAt: headerFilter?.uplaoddate,
              ocrStatus: headerFilter?.SELECT,
              clientName:headerFilter?.clientName,
            },
          ],
        });
        CountUserAPICall({
          payload: [{ businessId: clientSelect }],
        });
    }
  }

  const handleChangeBusiness = (event, newValue) => {
    var eventValue = "";
    if (event.target.value) {
      var eventValue = event.target.value;
    } else {
      var eventValue = newValue.label;
    }
    const found1 = clientResponce.find((obj) => {
      return obj.name === eventValue;
    });
    setSyncPercent(found1?.syncPercent ? found1?.syncPercent : 0);
    if (found1?.syncPercent == 100) {
      setUploadPermison(true);
      ClientUserAPICall({
        payload: [{ keyword: `` }],
      });
    } else {
      if(eventValue == "All"){
        setUploadPermison(true);
      }else{
        setUploadPermison(false);
      }
      ClientUserAPICall({
        payload: [{ keyword: `` }],
      });
    }
    const arraybusinessId = showrowsData.map((obj) => Object.values(obj));
      arraybusinessId.map((prop, key) =>
        prop[1] == eventValue ? setOnchageBusinessId(prop[0]) : eventValue == "All" ?  setOnchageBusinessId("All") : ''
      );
      arraybusinessId.map((prop, key) =>
        prop[1] == eventValue ? localStorage.setItem("clientSelect",prop[0]) : eventValue == "All" ?  localStorage.setItem("clientSelect","All") : ''
      );
    setShowSelectedrowsData(eventValue);
    setOpenSelectInvoice(false);
    setLoading(true);
  };

  const handleSelectBusiness = (event, newValue) => {
    var eventValue = "";
    if (event.target.value) {
      var eventValue = event.target.value;
    } else {
      var eventValue = newValue.label;
    }
   
    const arraybusinessId = showrowsData.map((obj) => Object.values(obj));
      arraybusinessId.map((prop, key) =>
        prop[1] == eventValue ? setBusinessId(prop[0]) : ''
      );
      setOpenSelectForInvoiceUpload(false);
      setOpenInvoice(true)
  };

  const handleClose = () => {
    setOpenInvoice(false);
  };

  const handleClickAssignClient = () => {
    setShowNotAssignList(true);
    setOnwerNotAssigned(false);
  };

  const handleAssignClient = () => {
    setShowNotAssignList(false);
    const unassignedOwnerIds = new Set(selectedUnAssignedOwner.map(owner => owner._id));
    const filteredInvoiceList = InvoiceList.filter(invoice => 
      unassignedOwnerIds.has(invoice.clientId)
    );
  
    const uniqueRecords = new Map();
    const records = filteredInvoiceList.filter(record => {
      if (!uniqueRecords.has(record.clientId)) {
        uniqueRecords.set(record.clientId, record);
        return true;
      }
      return false;
    });
  
    // If there are any records, initiate the assignment process
    if (records.length > 0) {
      setAssignNewClient1(false); // Close any currently open dialog
      setFilteredRecords(records); // Set the filtered records
      setCurrentRecordIndex(0); // Start with the first record
      const { clientName, clientId } = records[0];
      setOwnerName(clientName);
      setBusinessId(clientId);
      setAssignNewClient1(true); // Open the dialog for the first record
    }
  };
  
  eventBus.$on("successInAddOwner", (data) => successInAddOwner(data));
  function successInAddOwner(data) {
        ClientUserAPICall({
          payload: [{ keyword: `` }],
        })
        setAssignNewClient1(false);
        setLoadingCircul(true);
        setTimeout(() => {
          const nextIndex = currentRecordIndex + 1;
          if (nextIndex < filteredRecords.length) {
            const { clientName, clientId } = filteredRecords[nextIndex];
            setOwnerName(clientName);
            setBusinessId(clientId);
            setCurrentRecordIndex(nextIndex);
            setAssignNewClient1(true);
            setLoadingCircul(false);
          } else {
            setTabsMove("AskClient");
            setAssignNewClient1(false);
            setLoadingCircul(false);
            setalertMoveInvoice(true);
          }
        }, 1000);
  }


  const handleSkipAssign = () => {
      const filteredInvoiceList = InvoiceListForMatch.filter(invoice => 
        !unassignedOwners.some(owner => owner._id === invoice.clientId)
      );
      const idsArray = filteredInvoiceList.map(invoice => invoice.id);
      setSelectedRows(idsArray);
      setShowNotAssignList(false);
      setTabsMove("AskClient");
      setalertMoveInvoice(true);
  };


  const handleCloseSelect = () => {
    navigate(-1);
    if (showselectedrowsData) {
      setOpenSelectInvoice(false);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    localStorage.setItem("Tabs", selectTab);
  }, []);

  const IssueIsExisttext = () => {
    return (
      <MDBox sx={{ ml: 3, mr: 3, mb: -1 }}>
        <MDTypography>
          We are unable to approve this document due to the presence of certain issues that have
          been identified during the review process. . We kindly request that you take the necessary
          steps to address and resolve these issues before resubmitting the document for approval.
        </MDTypography>
      </MDBox>
    );
  };

  const handleChange = async (event, newValue) => {
    setHideNodoc(false);
    setInvoiceList([]);
    window.history.pushState("data","title",`${pathname}?k=${encodeURIComponent(search)}&t=${encodeURIComponent(newValue)}`);
    localStorage.setItem("Tabs", newValue);
    setValue(newValue);
    setLoading(true);
    setSelectTab(newValue);
    localStorage.setItem("offset", 0);
    eventBus.$emit("showSelectTabs", newValue);
    await setAPICall({
      payload: [
        {
          clientId: clientSelect == 'All' ? 'All' : businessId,
          invoiceStatus: newValue,
          limit: limit,
          offset: 0,
          keyword: `${search}`,
          vendorName: headerFilter?.vendor,
          invoiceNo: headerFilter?.invoice,
          createdAt: headerFilter?.uplaoddate,
          ocrStatus: headerFilter?.SELECT,
          clientName:headerFilter?.clientName,
        },
      ],
    });
    await CountUserAPICall({
      payload: [{ businessId: clientSelect }],
    });
  };

  useEffect(async () => {
    await ClientUserAPICall({
      payload: [{ keyword: `` }],
    });
    await ClientLookeupUserAPICall({
      payload: [],
    });
    setLoading(true);
    const ThisOffsetSearch = localStorage.getItem("offset");
    const ThisnewpageIndex = localStorage.getItem("newpageIndex");
    const finaloffset = ThisnewpageIndex == 0 ? 0 : ThisOffsetSearch;
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("k");
    const Tabs = queryParameters.get("t");
    if (businessId !== ":id") {
      await setAPICall({
        payload: [
          {
            clientId: businessId,
            invoiceStatus: Tabs ? Tabs : selectTab,
            limit: limit,
            offset: finaloffset,
            keyword: `${keyword ? keyword : search}`,
            vendorName: headerFilter?.vendor,
            invoiceNo: headerFilter?.invoice,
            createdAt: headerFilter?.uplaoddate,
            ocrStatus: headerFilter?.SELECT,
            clientName:headerFilter?.clientName,
          },
        ],
      });
      if(clientSelect !== null){
        await CountUserAPICall({
          payload: [{ businessId: clientSelect }],
        });
      }
    }

    eventBus.$on("invoiceRowSelected", (data) => {
      setSelectUseEffect(data.selectedRowIds.length);
      if (data?.selectedRowIds.length) {
        setSelectedRows(data?.selectedRowIds);
      }
    });
    return () => {
      eventBus.$off("invoiceRowSelected");
    };
  }, []);

  useEffect(() => {
    const ThisOffsetSearch = localStorage.getItem("offset");
    const ThisnewpageIndex = localStorage.getItem("newpageIndex");
    const finaloffset = ThisnewpageIndex == 0 ? 0 : ThisOffsetSearch;
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("k");
    const Tabs = queryParameters.get("t");
    if (businessId !== ":id") {
      const timeOutId = setTimeout(
        async () =>
          await setAPICall({
            payload: [
              {
                clientId: businessId,
                invoiceStatus: Tabs ? Tabs : selectTab,
                limit: limit,
                keyword: `${keyword ? keyword : search}`,
                offset: finaloffset,
                vendorName: headerFilter?.vendor,
                invoiceNo: headerFilter?.invoice,
                createdAt: headerFilter?.uplaoddate,
                ocrStatus: headerFilter?.SELECT,
                offset: 0,
                clientName:headerFilter?.clientName,
              },
            ],
          }),
        900
      );
      return () => clearTimeout(timeOutId);
    }
  }, [search, headerFilter]);


  useEffect(() => {
    if (isClientLoading) {
      return () => null;
    }

    if (isClientError) {
      return () => null;
    }

    if (ClientResponse && ClientResponse.data.isSuccess === true) {
      if(InvoiceListForMatch){
        const result = ClientResponse.data.data.records.reduce((acc, record) => {
          if (record.ownerAssigned === false) {
            acc.push({ _id: record._id, name: record.name,ownerAssigned:record?.ownerAssigned  });
          }
          return acc;
        }, []);

      const resultIds = new Set(result.map(owner => owner._id));
      const filteredInvoiceList = InvoiceListForMatch.filter(invoice => 
          resultIds.has(invoice.clientId)
      );
        setUnassignedOwners(result);
      }
    
      if (businessId == ":id") {
        ClientResponse?.data?.data?.totalRecords > 1 ? setOpenSelectInvoice(true) : "";
      }
      if (ClientResponse?.data?.data?.records[0]?.syncPercent !== 100) {
        setReloadApiClientApi(reloadApiClientApi + 1);
      }
      setClientResponce(ClientResponse?.data?.data?.records);
      const found2 = ClientResponse?.data?.data?.records.find((obj) => {
        return obj._id === businessId;
      });
      setSyncPercent(found2?.syncPercent ? found2?.syncPercent : 0);
      setOwnerAssigned(found2?.ownerAssigned);
      setOwnerName(found2?.name);
      if (found2?.syncPercent == 100) {
        setUploadPermison(true);
      } else {
        if(clientSelect == "All"){
          setUploadPermison(true);
        }else{
          setUploadPermison(false);
        }
      }
      const array = ClientResponse.data.data.records.map((obj) => Object.values(obj));
      setShowrowsData(array);
      if (array.length > 1) {
        setShowSelectBusiness(true);
      }
      {
        if (businessId == ":id" && array) {
          if (array.length === 0) {
            setOpenNoInvoice(true);
          } else if (array.length === 1) {
            if (ClientResponse?.data?.data?.records[0]?.syncPercent == 100) {
              setUploadPermison(true);
            } else {
              if(clientSelect == "All"){
                setUploadPermison(true);
              }else{
                setUploadPermison(false);
              }
            }
            setBusinessId(array[0][0]);
            setOnchageBusinessId(array[0][0]);
            setShowSelectedrowsData(array[0][1]);
            navigate(`/accountant/document/${array[0][0]}`);
          }
        }
        // prop[1] == eventValue ? 
        if(businessId != ":id"){
          array.map((prop, key) => (localStorage.getItem("clientSelect") == "All" ? setShowSelectedrowsData("All") : prop[0] == businessId ? setShowSelectedrowsData(prop[1]) : ""));
        }
        if(businessId != ":id"){
        array.map((prop, key) => ( localStorage.getItem("clientSelect") == "All" ?  localStorage.setItem("clientSelect","All") : prop[0] == businessId ?  localStorage.setItem("clientSelect",prop[0]) : ''));
        }
        setLoading(false);
        setLoadingCircul(false);
      }
    }
  }, [ClientResponse]);

  useEffect(() => {
    if (isCountLoading) {
      return () => null;
    }
    if (isCountError) {
      return () => null;
    }
    if (CountResponse && CountResponse?.data?.isSuccess === true) {
      setCountAll(CountResponse?.data?.data?.countAll);
      setCountApproved(CountResponse?.data?.data?.countApproved);
      setCountAskClient(CountResponse?.data?.data?.countAskClient);
      setCountClassify(CountResponse?.data?.data?.countClassify);
      setCountExcluded(CountResponse?.data?.data?.countExcluded);
      setCountPendingApproval(CountResponse?.data?.data?.countPendingApproval);
      setCountToReview(CountResponse?.data?.data?.countToReview);
      setCounReconciled(CountResponse?.data?.data?.countReconciled);
      setCountUnreconciled(CountResponse?.data?.data?.countUnreconciled)
    }
  }, [CountResponse]);

  useEffect(() => {
    if (invoiceStatusId === "ERROR") {
      setTitleShow("Error");
      setIconShow("warning");
      setCompletemassage("Unable to read invoice");
    } else {
      setTitleShow("Success");
      setIconShow("done");
      setCompletemassage(`Documents uploaded Successfully`);
    }
  }, [invoiceStatusId]);

  let idsArray = [];

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setHideNodoc(true);
      setLoading(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setAvailableStatuses(response?.data?.data?.availableStatuses);
      const ThisOffsetuse = localStorage.getItem("offset");
      if (ThisOffsetuse >= 10) {
        if (response?.data?.data?.records.length === 0 && ThisOffsetuse !== 0) {
          setLoading(true);
          localStorage.setItem("offset", ThisOffsetuse - 10);
          setAPICall({
            payload: [
              {
                clientId: `${businessId}`,
                keyword: `${search}`,
                invoiceStatus: selectTab,
                limit: limit,
                offset: ThisOffsetuse - 10,
                vendorName: headerFilter?.vendor,
                invoiceNo: headerFilter?.invoice,
                createdAt: headerFilter?.uplaoddate,
                ocrStatus: headerFilter?.SELECT,
                clientName:headerFilter?.clientName,
              },
            ],
          });
          eventBus.$emit("MoveMenuResponseComplete", ThisOffsetuse);
        }
      }
      setHideNodoc(true);
      eventBus.$emit("showInvoiceStatus", response?.data?.data?.records);
      setStoreResponce(response);
      setTableEmpty(response?.data?.data?.totalRecords);
      setInvoiceStatusId(
        response?.data?.data?.totalRecords === 0 ? 0 : response?.data?.data?.records[0]?.status
      );
      const allNotCompleted = response?.data?.data?.records.every(item => item.ocrStatus !== "COMPLETED");
        if(allNotCompleted){
          // eventBus.$emit("DisableAllCheckBox", true);
        }else{
          eventBus.$emit("DisableAllCheckBox", false);
        }
        const dataForMatch = response?.data?.data?.records.map((prop, key) => {
          return {
            clientName:prop?.client?.name ? prop?.client?.name : '---',
            clientId:prop?.client?.clientId ? prop?.client?.clientId : '---',
            id: prop._id,
            checkBoxActive: prop.checkBoxActive,
          };
        });
        setInvoiceListForMatch(dataForMatch);
      const data = response?.data?.data?.records.map((prop, key) => {
        const VnadorName = prop.vendor;
        var FiVendorName = "";
        if (VnadorName == undefined) {
          var FiVendorName = "-";
        } else {
          var FiVendorName = VnadorName.name ? VnadorName.name : "-";
        }
        if (prop.ocrStatus !== 'COMPLETED') {
          if (prop?._id) { // Check if _id exists before pushing
            idsArray.push(prop._id);
            localStorage.setItem("idsArray", JSON.stringify(idsArray));
          }
        }
        return {
          clientName:prop?.client?.name ? prop?.client?.name : '---',
          clientId:prop?.client?.clientId ? prop?.client?.clientId : '---',
          documentType:prop?.documentType,
          vendor: (
            <MDBox>
              {prop?.isSynced && 
               <Tooltip cursor='pointer' title='Synced with quickbook' placement="top">
                  <VerifiedIcon  style={{ color: prop?.ocrStatusDisplayTextColor }}/>
              </Tooltip>
              }
              {prop?.documentType == 'STATEMENT' ? <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="info"
                  sx={{
                    cursor:'pointer',
                    "&:hover": {
                    color: "#007bff", // Change to blue on hover
                  }}}
                  onClick={async (value) => {
                    setBusinessId(prop?.client?.clientId)
                    setInvoiceId(prop._id);
                    setOpenEditStatement(true);
                  }}
                >
                  {FiVendorName}
                </MDTypography> : 
              <MDTypography variant="caption" fontWeight="bold" fontSize="0.875rem" ml={0.5} >
                {FiVendorName}
              </MDTypography>}
            </MDBox>
          ),
          invoice: prop?.documentType == 'STATEMENT' ||prop.ocrStatus == "INPROGRESS" ? (
              prop.docNumber ?  <MDTypography
              variant="caption"
              fontWeight="regular"
              fontSize="0.875rem"
              color="info"
              sx={{
                cursor:'pointer',
                "&:hover": {
                color: "#007bff", // Change to blue on hover
              }}}
              onClick={async (value) => {
                setBusinessId(prop?.client?.clientId)
                setInvoiceId(prop._id);
                setOpenEditStatement(true);
              }}
            >
              {prop.docNumber ? prop.docNumber : "---"}
            </MDTypography> :  "---" 
            ) : (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="info"
                  sx={{
                    cursor:'pointer',
                    "&:hover": {
                    color: "#007bff", // Change to blue on hover
                  }}}
                  onClick={async (value) => {
                    setBusinessId(prop?.client?.clientId)
                    setInvoiceId(prop._id);
                    setOpenEditInvoice(true);
                  }}
                >
                  {prop.docNumber ? prop.docNumber : "---"}
                </MDTypography>
              </MDBox>
            ),
          phone: prop.phone,
          uplaoddate: prop.createdAt,
          status: prop?.duplicateOf && prop?.duplicateOf !== null ? (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="info"
                  style={{ cursor: "pointer" }}
                  onClick={async (value) => {
                    setInvoiceId(prop?.duplicateOf);
                    setOpenEditInvoice(true);
                  }}
                >
                  {prop?.ocrStatusDisplayText}
                </MDTypography>
              </MDBox>
            ) : (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  style={{ color: prop?.ocrStatusDisplayTextColor }}
                >
                  {prop?.ocrStatusDisplayText}
                </MDTypography>
              </MDBox>
            ),
          issue:
            prop.totalIssues >= 1 ? (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="error"
                  sx={{ cursor: "pointer" ,"&:hover": {
                    color: "#d3e786", // Change to blue on hover
                  } }}
                  onClick={async (value) => {
                    setLoading(true);
                    IssueListAPICall({
                      payload: [{ businessId: `${prop?.client?.clientId}`, InvoiceId: `${prop._id}` }],
                    });
                  }}
                >
                  {prop.totalIssues}
                </MDTypography>
              </MDBox>
            ) : (
              prop.totalIssues
            ),
          action:
            prop.status === "COMPLETED" ? (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="info"
                  onClick={async (value) => {
                    setInvoiceId(prop._id);
                    setOpenEditInvoice(true);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <Tooltip title={action.showtooltip} placement="top">
                    <Icon fontSize="small">visibility</Icon>
                  </Tooltip>
                </MDTypography>
              </MDBox>
            ) : (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="info"
                  onClick={async (value) => {
                    setInvoiceId(prop._id);
                    setErrorOpenEditInvoice(true);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <Tooltip title={action.showtooltip} placement="top">
                    <Icon fontSize="small">visibility</Icon>
                  </Tooltip>
                </MDTypography>
              </MDBox>
            ),
          id: prop._id,
          checkBoxActive: prop.checkBoxActive,
        };
      });
      setInvoiceList(data);
      if (data) {
        setLoading(false);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setStartSearchLoader(false);
      setLoadingCircul(false);
    }
  }, [response]);

  useEffect(() => {
    const fetchData = async () => {
        const clientSelect = localStorage.getItem('clientSelect');
        const CheckIdsArray =JSON.parse(localStorage.getItem("idsArray"));
        if (CheckIdsArray) {
          await CheckStausUserAPICall({
            payload: [{"documentIds":CheckIdsArray}],
          })
        }
        if(clientSelect !== null){
        await CountUserAPICall({
          payload: [{ businessId: clientSelect }],
        });      
      }
    };

    if (selectUseEffect == 0) {
        fetchData(); 

        if (checkIsProdServer === "production") {
            const interval = setInterval(fetchData, 40000); 
            return () => clearInterval(interval); 
        }
    }
  }, [selectedInvoice ,selectUseEffect]);

  const dataTableDataAll = {
    columns: [
      {
        Header: "Client Name",
        accessor: "clientName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Vendor",
        accessor: "vendor",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Invoice",
        accessor: "invoice",
        Cell: ({ value }) => <a style={{cursor:'pointer', color: "blue" }}>{value} </a>,
      },
      {
        Header: "Upload Date",
        accessor: "uplaoddate",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Issue",
        accessor: "issue",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: InvoiceList,
  };

  const dataTableData = {
      columns: [
        {
          Header: "Vendor",
          accessor: "vendor",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "Invoice",
          accessor: "invoice",
          Cell: ({ value }) => <a style={{cursor:'pointer', color: "blue" }}>{value} </a>,
        },
        {
          Header: "Upload Date",
          accessor: "uplaoddate",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "Issue",
          accessor: "issue",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
      ],
      rows: InvoiceList,
  };

  const dataTableDataStatmentAll = {
    columns: [
      {
        Header: "Client Name",
        accessor: "clientName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Vendor",
        accessor: "vendor",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Upload Date",
        accessor: "uplaoddate",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Issue",
        accessor: "issue",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: InvoiceList,
  };


  const dataTableDataStatment = {
    columns: [
      {
        Header: "Vendor",
        accessor: "vendor",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Upload Date",
        accessor: "uplaoddate",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Issue",
        accessor: "issue",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: InvoiceList,
};

  const handleSearch = async (event) => {
    localStorage.setItem("offset", 0);
    setSearch(event.target.value);
    window.history.pushState("data","title",`${pathname}?k=${encodeURIComponent(event.target.value)}&t=${encodeURIComponent(value)}`);
    setLoadingCircul(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const deleteInvoiceConfirmMessage = () => {
    setAlertDeleteInvoice(true);
  };
  const hideInvoiceAlert = () => {
    setAlertDeleteInvoice(false);
  };
  const successInoiceDelete = () => {
    setLoading(true);
    setDltButton(true);
    DeleteInvoiceListAPICall({
      payload: [
        {
          documentIds: {
            documentIds: SelectedRows,
          },
          businessId: `${businessId}`,
        },
      ],
    });
    setAlertDeleteInvoice(false);
  };

  const handleDeleteInvoice = () => {
    deleteInvoiceConfirmMessage();
  };

  const hideMoveAlert = () => {
    setalertMoveInvoice(false);
  };
  const successInoiceMove = async () => {
    setDisableMoveButton(true);
    await MoveMenuListAPICall({
      payload: [
        {
          documentIds: {
            documentIds: SelectedRows,
            documentStatus: tabsMove,
          },
          businessId: `${businessId}`,
        },
      ],
    });
    setLoading(true);
  };

  useEffect(() => {
    setSelectedInvoice(SelectedRows.length);
    if (Object.keys(storeResponce).length > 0) {
      storeResponce.data.data.records.map((prop, key) => {
        if (prop._id === SelectedRows[0]) {
          setStoreInvoiceNumber(prop?.docNumber !== "---" ? prop?.docNumber : "");
        }
      });
    }
  }, [SelectedRows, storeResponce]);

  const hideIssueAlert = () => {
    const ThisOffsethide = localStorage.getItem("offset");
    setAPICall({
      payload: [
        {
          clientId: `${businessId}`,
          invoiceStatus: selectTab,
          limit: limit,
          offset: ThisOffsethide,
          keyword: `${search}`,
          vendorName: headerFilter?.vendor,
          invoiceNo: headerFilter?.invoice,
          createdAt: headerFilter?.uplaoddate,
          ocrStatus: headerFilter?.SELECT,
          clientName:headerFilter?.clientName,
        },
      ],
    });
    setLoading(true);
    setSelectedIndex(0);
    setalertIssueInvoice(false);
  };

  eventBus.$on("closeandremovealldata", (data) => Closeandremovealldata(data));
  function Closeandremovealldata(data) {
    setOnIssueClick(false);
    setalertIssueInvoice(false);
  }

  const reloadApiAfterClose = () => {
    setOnIssueClick(false);
    setalertIssueInvoice(false);
    setOpenEditInvoice(false);
    setOpenEditStatement(false);
    // removeQueryParams();
  };

  const closeIssuePopup = () => {
    setOnIssueClick(false);
    setalertIssueInvoice(false);
    setOpenEditInvoice(false);
  };
  function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
      <>
        {tableEmpty !== 0 && ShowMoveIcon && selectUseEffect > 0 ? (
          <Toolbar style={{ marginLeft: "-15px" }}>
            {canAccess((actions = "delete"), (resource = "documents")) && selectUseEffect > 0 ? (
              <Tooltip title="Delete">
                <IconButton onClick={handleDeleteInvoice}>
                  <DeleteIcon sx={darkMode && { color: "white !important" }} />
                </IconButton>
              </Tooltip>
            ) : null}
          </Toolbar>
        ) : null}
      </>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0",
    boxShadow: 24,
    outline: "none",
    p: 4,
    "& .MuiInputLabel-root": {
      color: "#2d456c",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#2d456c",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2d456c",
    },
  };

  const assignstyle = {
      borderRadius: 2, 
      boxShadow: 24,
      maxWidth: 500,
      mx: 'auto',
      p: 4,
      textAlign: 'center',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: darkMode ?  colors.background.card  : "background.paper",
    border: "0",
    boxShadow: 24,
    outline: "none",
    p: 4,
    "& .MuiInputLabel-root": {
      color: "#2d456c",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#2d456c",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2d456c",
    },
  };

  const assignliststyle = {
    borderRadius: 2, 
    boxShadow: 24,
    minWidth: 500,
    mx: 'auto',
    p: 4,
    textAlign: 'center',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: darkMode ?  colors.background.card  : "background.paper",
    border: "0",
    outline: "none",
    "& .MuiInputLabel-root": {
      color: "#2d456c",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#2d456c",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2d456c",
    },
  };

  const darkModestyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    color: "#FFFFFF",
    bgcolor: colors.background.card,
    border: "0",
    boxShadow: 24,
    outline: "none",
    p: 4,
    "& .MuiInputLabel-root": {
      color: "#fff",
    },
    "& .MuiInput-underline:before": {
      // Custom styles for underline when not focused
      borderBottomColor: "#fff", // Underline color when not focused
    },
    "& .MuiInput-underline:after": {
      // Custom styles for underline when focused
      borderBottomColor: "#fff", // Underline color when focused
    },
    "& .MuiAutocomplete-popupIndicator": {
      // Custom styles for Autocomplete popup indicator (arrow)
      color: "#fff", // Color of the arrow icon
    },
  };

  function splitCamelCaseToString(s) {
    return s.split(/(?=[A-Z])/).join(" ");
  }

  const openMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  eventBus.$on("ShowAsignClientPopup", (data) => ShowAsignClientPopup(data));
  function ShowAsignClientPopup(data) {
    setLoading(true);
    setOpenEditInvoice(false);
    eventBus.$emit("ShowAsignClientPopup1", true);
  }

  const oncloseDialog = () => {
    setLoading(true);
    setOpenEditInvoice(false);
    const ThisOffsetclose = localStorage.getItem("offset");
    setAPICall({
      payload: [
        {
          clientId:  clientSelect == 'All' ? 'All' : businessId,
          invoiceStatus: selectTab,
          limit: limit,
          offset: ThisOffsetclose,
          keyword: `${search}`,
          vendorName: headerFilter?.vendor,
          invoiceNo: headerFilter?.invoice,
          createdAt: headerFilter?.uplaoddate,
          ocrStatus: headerFilter?.SELECT,
          clientName:headerFilter?.clientName,
        },
      ],
    });
    CountUserAPICall({
      payload: [{ businessId: clientSelect }],
    });
  };

  const oncloseDialog1 = () => {
    setLoading(true);
    const ThisOffsetclose1 = localStorage.getItem("offset");
    setAPICall({
      payload: [
        {
          clientId:  clientSelect == 'All' ? 'All' :businessId,
          invoiceStatus: selectTab,
          limit: limit,
          offset: ThisOffsetclose1,
          keyword: `${search}`,
          vendorName: headerFilter?.vendor,
          invoiceNo: headerFilter?.invoice,
          createdAt: headerFilter?.uplaoddate,
          ocrStatus: headerFilter?.SELECT,
          clientName:headerFilter?.clientName,
        },
      ],
    });
    CountUserAPICall({
      payload: [{ businessId: clientSelect }],
    });
  };

  const onMenuItemClick = async (event, key) => {
    setAnchor(null);
    if (selectTab == "Approved") {
      setShowApprovedModel(true);
    } else {
      const selectMoveTab = event.target.textContent.replace(/ /g, "");
      const IssueTotal = SelectedRows.map((prop, key) =>
        response?.data?.data?.records.find((obj) => {
          return obj._id === prop;
        })
      );
      const finalIssue = IssueTotal.find((obj) => {
        return obj?.totalIssues > 0;
      });
      const filteredInvoiceList = InvoiceListForMatch.filter(invoice => 
        unassignedOwners.some(owner => owner._id === invoice.clientId)
      );
      const selectedFilteredInvoiceList = filteredInvoiceList.filter(invoice =>
        SelectedRows.includes(invoice.id)
      );
      const resultMap = new Map();
      
      selectedFilteredInvoiceList.forEach(invoice => {
        const matchingOwner = unassignedOwners.find(owner => owner._id === invoice.clientId);
        const entry = {
          _id: invoice.clientId,
          name: invoice.clientName,
          ownerAssigned: matchingOwner ? matchingOwner.ownerAssigned : false
        };
      
        // Use the _id as the key in the map to ensure uniqueness
        if (!resultMap.has(entry._id)) {
          resultMap.set(entry._id, entry);
        }
      });
      
      // Convert the Map values back into an array
      const result = Array.from(resultMap.values());
      
      setSelectedUnAssignedOwner(result);

      // Step 2: Filter out matching items from SelectedRows
      const filteredSelectedRows = SelectedRows.filter(rowId => 
        selectedFilteredInvoiceList.some(invoice => invoice.id === rowId)
      );
      if(clientSelect == "All"){
        if (filteredSelectedRows.length != 0 && selectMoveTab === "AskClient") {
          setOnwerNotAssigned(true);
          // setAssignNewClient(true);
        } else if (selectMoveTab === "Approved" && finalIssue) {
          setIssueExist(true);
        } else {
          setTabsMove(selectMoveTab);
          setalertMoveInvoice(true);
        }
      }else{
        if (ownerAssigned == false && selectMoveTab === "AskClient") {
          setAssignNewClient(true);
        } else if (selectMoveTab === "Approved" && finalIssue) {
          setIssueExist(true);
        } else {
          setTabsMove(selectMoveTab);
          setalertMoveInvoice(true);
        }
      }
    }
  };

  const AsignNewClientMsg = () => {
    return (
      <MDBox sx={{ ml: 4, mb: -1 }}>
        <MDTypography>No email has been associated with this client account.</MDTypography>
        <MDTypography display="flex" justifyContent="center">
          Click here to add and email for this client
        </MDTypography>
      </MDBox>
    );
  };

  const successAssignNewClient = (move) => {
    setAssignNewClient1(true);
    setAssignNewClient(false);
    // eventBus.$emit("refreshApiInvoiceList", true);
    // eventBus.$emit("ShowAsignClientPopup", true);
  };
  useEffect(() => {
    const arr = [];
    SelectedRows.forEach(function (i) {
      something(i);
    });
    function something(i) {
      const Issue = response?.data?.data?.records.find((obj) => {
        return obj._id === i;
      });
      arr.push(Issue);
    }
    const FinalIssues = arr.find((obj) => {
      return obj?.totalIssues > 0;
    });
    setIssuesExist(FinalIssues === undefined);
  }, [SelectedRows]);

  const tabRowsDataToReview = [
    {
      key: {
        AskClient: "Ask Client",
        ToReview: "To Review",
        Excluded: "Excluded",
      },
      Tab: "Classify",
      Permission: ["Owner", "Admin", "Manager", "User"],
    },
    {
      key: {
        Classify: "Classify",
      },
      Tab: "AskClient",
      Permission: ["Owner", "Admin", "Manager"],
    },
    {
      key: {
        Classify: "Classify",
        PendingApproval: "Pending Approval",
      },
      Tab: "ToReview",
      Permission: ["Owner", "Admin", "Manager"],
    },
    {
      key: {
        Classify: "Classify",
        Approved: "Approved",
      },
      Tab: "PendingApproval",
      Permission: ["Owner", "Admin", "Manager"],
    },
    {
      key: {
        Classify: "Classify",
      },
      Tab: "Approved",
      Permission: ["Owner", "Admin", "Manager"],
    },
    {
      key: {
        AskClient: "Ask Client",
      },
      Tab: "Unreconciled",
      Permission: ["Owner", "Admin", "Manager", "User"],
    },
    {
      key: {
        AskClient: "Ask Client",
      },
      Tab: "Reconciled",
      Permission: ["Owner", "Admin", "Manager", "User"],
    },
    {
      key: {
        Classify: "Classify",
      },
      Tab: "Excluded",
      Permission: ["Owner", "Admin", "Manager"],
    },
    {
      key: {},
      Tab: "AllDoc",
      Permission: [""],
    },
  ];

  const tabRowsData = [
    {
      key: {
        AskClient: "Ask Client",
        Excluded: "Excluded",
      },
      Tab: "Classify",
      Permission: ["Owner", "Admin", "Manager", "User"],
    },
    {
      key: {
        Classify: "Classify",
      },
      Tab: "AskClient",
      Permission: ["Owner", "Admin", "Manager"],
    },
    {
      key: {
        Classify: "Classify",
        PendingApproval: "Pending Approval",
      },
      Tab: "ToReview",
      Permission: ["Owner", "Admin", "Manager"],
    },
    {
      key: {
        Classify: "Classify",
        Approved: "Approved",
      },
      Tab: "PendingApproval",
      Permission: ["Owner", "Admin", "Manager"],
    },
    {
      key: {
        AskClient: "Ask Client",
        Excluded: "Excluded",
      },
      Tab: "Unreconciled",
      Permission: ["Owner", "Admin", "Manager", "User"],
    },
    {
      key: {
        AskClient: "Ask Client",
        Excluded: "Excluded",
      },
      Tab: "Reconciled",
      Permission: ["Owner", "Admin", "Manager", "User"],
    },
    {
      key: {
        Classify: "Classify",
      },
      Tab: "Approved",
      Permission: ["Owner", "Admin", "Manager"],
    },
    {
      key: {
        Classify: "Classify",
      },
      Tab: "Excluded",
      Permission: ["Owner", "Admin", "Manager"],
    },
    {
      key: {},
      Tab: "AllDoc",
      Permission: [""],
    },
  ];

  const FinalTabData = IssuesExist ? tabRowsDataToReview : tabRowsData;
  const moveSelect = FinalTabData.find((obj) => {
    return obj.Tab === selectTab ? obj.Tab === selectTab : obj.Tab === "AllDoc";
  });
  const ShowMoveIcon = moveSelect.Permission.includes(movePermmison);

  useEffect(() => {
    if (openNoInvoice === true) {
      const profileInfo11 = cookies.get("remind_add_client");
      const profileInfo12 = cookies.get("backup_remind_add_client");
      setOpenNoClient(!profileInfo11);
      if (profileInfo12 === undefined) {
        cookies.set("remind_add_client", false, { path: "/", maxAge: 3600 });
      }
      if (profileInfo12 === undefined) {
        setOpenNoClient(true);
      } else if (profileInfo11 === false) {
        setOpenNoClient(true);
      }
    }
  }, [openNoInvoice]);

  eventBus.$on("GetDocumentFilterListData", (data) => GetDocumentFilterListData(data));
  function GetDocumentFilterListData(data) {
    console.log("Document filter data", data);
  }

  eventBus.$on("startSearchLoader", (data) => {
    setStartSearchLoader(data);
  });

  eventBus.$on("GetDocumentinputValues", (data) => GetDocumentinputValues(data));
  function GetDocumentinputValues(data) {
    setLoadingCircul(true);
    setHeaderFilter(data);
    localStorage.setItem('CoulmnFilterObj',JSON.stringify(data));
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("k");
    const Tabs = queryParameters.get("t");
    if (businessId !== ":id") {
    setAPICall({
      payload: [
        {
          clientId:  clientSelect == 'All' ? 'All' : businessId,
          invoiceStatus: Tabs ? Tabs : selectTab,
          vendorName: data?.vendor,
          invoiceNo: data?.invoice,
          createdAt: data?.uplaoddate,
          ocrStatus: data?.SELECT,
          offset: 0,
          limit: 10,
          keyword: `${keyword ? keyword : search}`,
          clientName:data?.clientName,
        },
      ],
    });
    }
  }
  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3} 
        sx={{ "@media (max-width: 1440px)": { my:1 }}} >
        <Card
          sx={{
            opacity: loading ? "0.4" : "",
            borderRadius: loading ? "13px" : "",
            backgroundColor: darkMode ? "#202940" : "#ffffff",
          }}
        >
          <MDBox display="flex" justifyContent="center">
            {loading && <Loader />}
          </MDBox>
          <MDBox sx={{ mt: 2 }} component="form" display="flex" justifyContent="space-between">
            <MDBox mb={0} display="flex">
              {showSelectBusiness ? (
                <FormControl variant="standard" sx={{ pl: 3, minWidth: 150 }} fullWidth>
                  <InputLabel
                    sx={darkMode ? { pl: 4, color: "white !important" } : { pl: 4 }}
                    htmlFor="demo-simple-select-standard"
                    id="demo-simple-select-standard-label"
                  >
                    Client
                  </InputLabel>
                  <Select
                    aria-required="true"
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={showselectedrowsData}
                    onChange={handleChangeBusiness}
                    label="Select Client"
                    sx={
                      darkMode && {
                        color: "#ffffff !important",
                        "& .MuiSelect-icon": {
                          color: "#ffffff !important",
                        },
                        "& .MuiPopover-paper.MuiMenu-paper": {
                          backgroundColor: "#202940 !important" ,
                        },
                      }
                    }
                  >
                    {showrowsDataobj.map((prop, key) => (
                      <MenuItem value={prop[1]}>{prop[1]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <MDTypography pt={3} px={2} sx={{ pr: 3, mb: 2 }} variant="h5" fontWeight="medium">
                  Documents
                </MDTypography>
              )}
              {canAccess((actions = "update"), (resource = "documents")) &&
              tableEmpty !== 0 &&
              ShowMoveIcon &&
              selectUseEffect > 0 ? (
                <MDBox>
                  <Tooltip title={action.Movetooltip}>
                    <IconButton
                      onClick={openMenu}
                      sx={{ ml: 2, mt: 1.5 }}
                      aria-controls={openMenu ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? "true" : undefined}
                    >
                      <DriveFileMoveIcon sx={darkMode && { color: "white !important" }} />
                    </IconButton>
                  </Tooltip>
                  <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted>
                    {Object.keys(moveSelect.key).map((key, index) => (
                      <MenuItem
                        key={index}
                        onClick={(event) => onMenuItemClick(event, key)}
                        // selected={index === selected}
                      >
                        {moveSelect.key[key]}
                      </MenuItem>
                    ))}
                  </Menu>
                </MDBox>
              ) : null}
              <EnhancedTableToolbar numSelected={SelectedRows.length} />
              <div style={{fontSize:'smaller', marginLeft:'25px' , marginTop:'18px'}} >
                  {startSearchLoader ?
                    <MDBox sx={{display:'flex'}} >
                    <img
                      width={23}
                      height={23}
                      style={{ marginBottom: "-2px", marginRight: "8px" }}
                      src={image}
                    />
                    <div style={{color:darkMode ? '#ffffff' : '#7b809a', marginTop:'-2px'}}>
                      Fetching...
                    </div>
                    </MDBox>
                    :
                    <MDBox ml={4} >
                    </MDBox>
                  }
                </div>
            </MDBox>
            <MDBox px={2} display="flex"></MDBox>
            <MDBox px={2} display="flex">
              <MDBox sx={{ pr: 3, mb: 2 }}>
                <Tooltip placement="top" title="You can search documents using the vendor name and invoice number only">
                <MDInput
                  type="search"
                  label="Search Document"
                  value={search !== 'null'? search : ''}
                  onKeyDown={handleKeyDown}
                  variant="standard"
                  onChange={handleSearch}
                  InputLabelProps={{ style: darkMode ? { color: "#ffffff" } : {} }}
                  sx={{ "& input": { color: darkMode ? "#ffffff" : "#000000" }}}
                />
                </Tooltip>
              </MDBox>
              <MDBox>
                {canAccess((actions = "create"), (resource = "documents")) && (
                  <MDButton
                    variant="gradient"
                    color="info"
                    disabled={!uploadPermison}
                    onClick={() => clientSelect == "All" ?  setOpenSelectForInvoiceUpload(true) : setOpenInvoice(true)}
                  >
                    {" "}
                    Upload Documents
                  </MDButton>
                )}
              </MDBox>
            </MDBox>
          </MDBox>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                sx={darkMode && { backgroundColor: "#1a2035 !important" }}
                className={darkMode ? classes.tabs : ""}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab className={darkMode ? classes.tabs : ""} label={`Classify (${countClassify})`} value="Classify"/>
                <Tab label={`Ask Client (${countAskClient})`} value="AskClient" />
                <Tab label={`To Review (${countToReview})`} value="ToReview" />
                <Tab label={`Pending Approval (${countPendingApproval})`} value="PendingApproval" />
                <Tab label={`Approved (${countApproved})`} value="Approved" />
                <Tab label={`Unreconciled (${countUnreconciled})`} value="Unreconciled" />
                <Tab label={`Reconciled (${counReconciled})`} value="Reconciled" />
                <Tab label={`Excluded (${countExcluded})`} value="Excluded" />
                <Tab label={`All Documents (${countAll})`} value="" />
              </Tabs>
            </Box>
            <TabPanel>
              <MDBox>
                {response?.data?.isSuccess === true && tableEmpty == 0 ? (
                  <MDBox my={3} sx={{ mt: -2 }}>
                    <DataTable
                      showCheck={false}
                      table={clientSelect == "All" 
                        ? (currentTabs !== 'Unreconciled' && currentTabs !== 'Reconciled' ? dataTableDataAll : dataTableDataStatmentAll) 
                        : (currentTabs !== 'Unreconciled' && currentTabs !== 'Reconciled' ? dataTableData : dataTableDataStatment)}                      entriesPerPage={false}
                      pagination={true}
                      showTotalEntries={true}
                      selectTab={selectTab}
                      response={tableEmpty}
                      availableStatuses={availableStatuses}
                      uploadPermison={uploadPermison}
                    />
                    {uploadPermison ? (
                      <MDBox sx={{top:'55%' , position:'absolute' , left:'45%' ,zIndex:'999' }}>
                      <MDTypography
                        variant="body2"
                        color="secondary"
                        sx={{ fontSize: "1.20rem !important", mt: 2 }}
                      >
                        {hideNodoc ? "No Document in this tab" : ""}
                      </MDTypography>
                      </MDBox>
                    ) : (
                      <MDBox sx={{top:'50%' , position:'absolute' , left:'30%' ,zIndex:'999' , "@media (max-width: 1440px)": { left:'15%' }}}>
                        <MDTypography
                          variant="body2"
                          color="secondary"
                          sx={darkMode
                              ? {
                                  width: "100%",
                                  fontSize: "1.20rem !important",
                                  display: "flex",
                                  justifyContent: "center",
                                  mt: 2,
                                  color: "#ffffff !important",
                                } : { width: "100%", fontSize: "1.20rem !important", display: "flex", justifyContent: "center", mt: 2 }}
                        > {`Please bear with us while we retrieve information from QBO. This process may take a few moments...`}
                        </MDTypography>
                        <MDBox sx={{ width: "100%", display: "flex", justifyContent: "center", fontSize: "1.20rem !important" }} >
                          <div><img width={23} height={23} style={{ marginBottom: "-6px", marginRight: "8px" }} src={image} /></div>
                          <MDTypography sx={{ mr: 2 }} fontSize="1.20rem" color={"warning"}> ({syncPercent}% completed) </MDTypography>
                        </MDBox>
                      </MDBox>
                    )}
                  </MDBox>
                ) : (
                  <MDBox my={3} sx={{ mt: -2, mb: -2 }}>
                    <DataTable
                      table={clientSelect == "All" 
                        ? (currentTabs !== 'Unreconciled' && currentTabs !== 'Reconciled' ? dataTableDataAll : dataTableDataStatmentAll) 
                        : (currentTabs !== 'Unreconciled' && currentTabs !== 'Reconciled' ? dataTableData : dataTableDataStatment)}
                      entriesPerPage={true}
                      pagination={true}
                      showTotalEntries={true}
                      selectTab={selectTab}
                      response={tableEmpty}
                      loading={loading}
                      availableStatuses={availableStatuses}
                      uploadPermison={uploadPermison}
                    />
                  </MDBox>
                )}
              </MDBox>
            </TabPanel>
          </Box>
        </Card>
      </MDBox>
      <MDBox>
      <Modal
          open={openSelectForInvoiceUpload}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={darkMode ? darkModestyle : style}>
            <MDBox sx={{ pl: 0, pb: 5 }} display="flex" justifyContent="center">
              <FormControl variant="standard" sx={{ minWidth: 150, mt: 1.5 }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  ListboxProps={{
                    style: { maxHeight: "15rem" },
                    position: "bottom-start",
                    background: "#2d456c",
                  }}
                  options={autoCompleteObjForSelect}
                  sx={{width: 300 }}
                  onChange={(event, newValue) => {
                    setClientNewValueSelect(newValue);
                    setClientEventSelect(event);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Client"
                      variant="standard"
                      sx={
                        darkMode
                          ? {
                              "& .MuiFormLabel-root": {
                                marginTop: "2px",
                              },
                              "& .MuiInput-root .MuiInput-input": {
                                color: "#FFFFFF",
                                marginBottom: "-3px",
                              },
                            }
                          : {
                              "& .MuiFormLabel-root": {
                                marginTop: "2px",
                              },
                            }
                      }
                    />
                  )}
                />
              </FormControl>
            </MDBox>
            <MDBox
              sx={{
                justifyContent: "end",
                display: "flex",
              }}
            >
              <MDButton
                sx={darkMode && { backgroundColor: "transparent !important", color: "#ffffff" }}
                onClick={()=>setOpenSelectForInvoiceUpload(false)}
              >
                Close
              </MDButton>
              <MDButton
                variant="gradient"
                sx={{ ml: 0.5 }}
                color="info"
                onClick={() => handleSelectBusiness(clientEventSelect, clientNewValueSelect)}
              >
                submit
              </MDButton>
            </MDBox>
          </Box>
        </Modal>
        <Modal
          open={openSelectInvoice}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={darkMode ? darkModestyle : style}>
            <MDBox sx={{ pl: 0, pb: 5 }} display="flex" justifyContent="center">
              <FormControl variant="standard" sx={{ minWidth: 150, mt: 1.5 }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  ListboxProps={{
                    style: { maxHeight: "15rem" },
                    position: "bottom-start",
                    background: "#2d456c",
                  }}
                  options={autoCompleteObj}
                  sx={
                    darkMode
                      ? {
                          width: 300,
                          "& .MuiAutocomplete-clearIndicator": {
                            color: "#FFFFFF", // Change color of clear (X) icon in dark mode
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#FFFFFF", // Change color of dropdown arrow in dark mode
                          },
                        }
                      : {
                        "& .MuiAutocomplete-clearIndicator": {
                            color: "#000000", // Change color of clear (X) icon in dark mode
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#000000", // Change color of dropdown arrow in dark mode
                          },
                          width: 300,
                        }
                  }
                  onChange={(event, newValue) => {
                    setClientNewValue(newValue);
                    setClientEvent(event);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Client"
                      variant="standard"
                      sx={
                        darkMode
                          ? {
                              "& .MuiFormLabel-root": {
                                marginTop: "2px",
                              },
                              "& .MuiInput-root .MuiInput-input": {
                                color: "#FFFFFF",
                                marginBottom: "-3px",
                              },
                            }
                          : {
                              "& .MuiFormLabel-root": {
                                marginTop: "2px",
                              },
                            }
                      }
                    />
                  )}
                />
              </FormControl>
            </MDBox>
            <MDBox
              sx={{
                justifyContent: "end",
                display: "flex",
              }}
            >
              <MDButton
                sx={darkMode && { backgroundColor: "transparent !important", color: "#ffffff" }}
                onClick={handleCloseSelect}
              >
                Close
              </MDButton>
              <MDButton
                variant="gradient"
                sx={{ ml: 0.5 }}
                color="info"
                onClick={() => handleChangeBusiness(clientEvent, clientNewValue)}
              >
                submit
              </MDButton>
            </MDBox>
          </Box>
        </Modal>
        <Modal
          open={showApprovedModel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <MDBox sx={{ pl: 0, pb: 5, mb: -1 }} display="flex" justifyContent="center">
              <MDTypography component="span" variant="body2" color="dark">
                To move this document you must delete it from your QBO account.
              </MDTypography>
            </MDBox>
            <MDBox
              sx={{
                justifyContent: "end",
                display: "flex",
                mb: -2.5,
              }}
            >
              <MDButton onClick={() => setShowApprovedModel(false)}>Close</MDButton>
            </MDBox>
          </Box>
        </Modal>
        <Modal
            open={onwerNotAssigned}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={assignstyle}  >
              <MDBox 
                sx={{ 
                  padding:"32px 32px 10px 32px",
                  backgroundColor: 'white', 
                  borderRadius: 2, 
                  maxWidth: 600,
                  mx: 'auto'
                }}
              >
                <MDTypography 
                  id="modal-title" 
                  variant="h6" 
                  color="info" 
                  align="center"
                  gutterBottom
                  mb={3}
                >
                  Attention Required
                </MDTypography>
                <MDTypography 
                  id="modal-description" 
                  variant="body2" 
                  align="center"
                  color="dark"
                  mb={4}
                >
                  No email has been associated with 
                  <span
                    onClick={handleClickAssignClient}
                    style={{ color: '#1976d2', cursor: 'pointer', fontWeight: 'bold' }}
                  >
                    {' '} {selectedUnAssignedOwner.length} {' '}
                  </span> 
                  clients.
                </MDTypography>
                <MDBox 
                  sx={{
                    display: 'flex', 
                    justifyContent: 'space-between',
                  }}
                >
                  <MDButton 
                    variant="contained" 
                    color="info" 
                    onClick={handleClickAssignClient}
                  >
                    View Clients
                  </MDButton>
                  <MDButton 
                    variant="outlined" 
                    color="secondary" 
                    onClick={() => setOnwerNotAssigned(false)}
                  >
                    Close
                  </MDButton>
                </MDBox>
              </MDBox>
            </Box>
          </Modal>
        <Modal
          open={showNotAssignList}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
         <Box sx={assignliststyle}  >
         <IconButton
                  aria-label="close"
                  onClick={() => setShowNotAssignList(false)}
                  sx={{
                    position: 'absolute',
                    right: 16,
                    top: 16,
                    color: 'grey.500',
                  }}
                >
                  <CloseIcon />
                </IconButton>
            <MDTypography 
              id="modal-title" 
              variant="h6" 
              color="info" 
              gutterBottom
            >
              Unassigned Clients
            </MDTypography>
            <MDTypography 
              id="modal-description" 
              variant="body2" 
              color="info" 
              mb={3}
            >
              Please assign an email to the following clients:
            </MDTypography>
            
            <MDBox 
              sx={{
                maxHeight: 200,
                overflowY: 'auto',
                mb: 3,
              }}
            >
              {selectedUnAssignedOwner.map((owner, index) => (
                <MDTypography key={index} variant="body2" sx={{ mt: 1 }}>
                  {owner.name}
                </MDTypography>
              ))}
            </MDBox>

            <MDBox 
              sx={{
                display: 'flex', 
                justifyContent: 'space-between',
              }}
            >
              <Tooltip placement="top" title="Start assigning emails one by one for each client.">
                <MDButton 
                  variant="contained" 
                  color="info" 
                  onClick={handleAssignClient}
                >
                  Assign Email
                </MDButton>
              </Tooltip>
              <Tooltip placement="top" title="Skip unassigned client documents and proceed with assigned ones.">
                <MDButton 
                  variant="outlined" 
                  color="info"
                  onClick={handleSkipAssign}
                >
                  Skip & continue
                </MDButton>
              </Tooltip>
            </MDBox>
          </Box>
        </Modal>
        <MDBox>
          <Modal
            open={openNoInvoice}
            // onClose={() => setOpenNoInvoice(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Modalstyle}>
              <MDBox display="flex" justifyContent="end" style={{ cursor: "pointer" }}>
                <MDTypography
                  component="span"
                  variant="body2"
                  color="dark"
                  opacity={1}
                  sx={{ lineHeight: 0, outline: "none" }}
                  onClick={() => navigate("/accountant/client/list")}
                >
                  <Icon>close</Icon>
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="center" mb={3}>
                <img className="small" src={logo} alt="no image" height="80px" width="80px" />
              </MDBox>
              <Typography
                sx={{ mt: 2 }}
                display={"flex"}
                justifyContent={"center"}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                Let’s add your first client to get the most use out of Paynthr
              </Typography>
              <Typography variant="body2" id="modal-modal-description" sx={{ mt: 4, ml: 1 }}>
                To initiate the synchronization with QuickBooks, we'll need to obtain a set of
                default accounts, vendors, and items for your QuickBooks account. Our system will
                seamlessly map your QuickBooks data to the corresponding Paynthr account. The
                mapping process can start after establishing the
              </Typography>
              <Typography
                display={"flex"}
                justifyContent={"center"}
                variant="body2"
                id="modal-modal-description"
              >
                connection.
              </Typography>
              <MDBox display="flex" justifyContent="center" sx={{ mt: 4 }}>
                <ConnectToQuickBooksOnlineButton
                  buttonType={"Icon"}
                  accountantId={encodeURI(profileInfo?.user?._id)}
                  accountantDomain={encodeURI(profileInfo?.user?.domainKey)}
                  accountantReturnUrl={ReturnUrl}
                />
              </MDBox>
            </Box>
          </Modal>
        </MDBox>
        <MDBox>
          <Modal
            open={uploadInvoice}
            // onClose={() => setOpenNoInvoice(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={darkMode ? DarkModalstyle : Modalstyle}>
              <MDBox display="flex" justifyContent="end" style={{ cursor: "pointer" }}>
                <MDTypography
                  component="span"
                  variant="body2"
                  color="dark"
                  opacity={1}
                  sx={{ lineHeight: 0, outline: "none" }}
                  onClick={() => setUploadInvoice(false)}
                >
                  <Icon>close</Icon>
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="center" mb={3}>
                <img className="small" src={logo} alt="no image" height="50px" width="50px" />
              </MDBox>
              <Typography
                sx={darkMode ? { mt: 2, color: "#ffffff" } : { mt: 2 }}
                display={"flex"}
                justifyContent={"center"}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                Document upload is successful
              </Typography>
              <Typography variant="body2" id="modal-modal-description" sx={{ mt: 4, ml: 1, mb: 4 }}>
                We are now processing your documents. Once all your documents are processed we will
                send you an email at your registered email address. You can also check the Document
                list page as it auto refreshes every 3 mins to check the processing status of your
                uploaded document.
              </Typography>
            </Box>
          </Modal>
        </MDBox>
        <MDBox>
              <DraggableNewInvoiceDialog
                open={openInvoice}
                invoiceId={invoiceId}
                businessId={businessId}
                disButton={disButton}
                onClose={handleClose}
              />
        </MDBox>
        <MDBox>
          <Dialog
            fullScreen
            open={openEditStatement}
            onClose={() => setOpenEditStatement(false)}
            TransitionComponent={Transition}
          >
            <DialogContent
              sx={
                darkMode
                  ? { padding: "10px 0px 0px 0px", backgroundColor: "#202940" }
                  : { padding: "10px 0px 0px 0px" }
              }
            >
              <EditStatementDialog
                selectedIndex={selectedId}
                issueType={issueType}
                clientAssigned={clientAssigned}
                invoiceIds={invoiceId}
                businessId={businessId}
                onIssueClick={onIssueClick}
                onIssueClickData={onIssueClickData}
                documentsId={documentsId}
                onClickClose={() => closeIssuePopup()}
                moduleTypeEdit={moduleTypeEdit}
                selectTab={selectTab}
                onClose={oncloseDialog}
                onClose1={oncloseDialog1}
                storeResponce={storeResponce}
                reloadApiAfterClose={reloadApiAfterClose}
              />
            </DialogContent>
          </Dialog>
        </MDBox>
        <MDBox>
          <Dialog
            fullScreen
            open={openEditInvoice}
            onClose={() => setOpenEditInvoice(false)}
            TransitionComponent={Transition}
          >
            <DialogContent
              sx={
                darkMode
                  ? { padding: "10px 0px 0px 0px", backgroundColor: "#202940" }
                  : { padding: "10px 0px 0px 0px" }
              }
            >
              <EditInvoiceDialog
                selectedIndex={selectedId}
                issueType={issueType}
                clientAssigned={clientAssigned}
                invoiceIds={invoiceId}
                businessId={businessId}
                onIssueClick={onIssueClick}
                onIssueClickData={onIssueClickData}
                documentsId={documentsId}
                onClickClose={() => closeIssuePopup()}
                moduleTypeEdit={moduleTypeEdit}
                selectTab={selectTab}
                onClose={oncloseDialog}
                onClose1={oncloseDialog1}
                storeResponce={storeResponce}
                reloadApiAfterClose={reloadApiAfterClose}
              />
            </DialogContent>
          </Dialog>
        </MDBox>
        <MDBox>
          <Dialog
            fullScreen
            open={openErrorEditInvoice}
            onClose={() => setErrorOpenEditInvoice(false)}
            TransitionComponent={Transition}
          >
            <Toolbar style={{ minHeight: "45px", backgroundColor: "white" }}>
              <Typography variant="h6" className={classes.title}></Typography>
              <Icon sx={{ cursor: "pointer" }} onClick={() => setErrorOpenEditInvoice(false)}>
                close
              </Icon>
            </Toolbar>
            <DialogContent>
              <ErrorEditInvoiceDialog invoiceId={invoiceId} businessId={businessId} />
            </DialogContent>
          </Dialog>
        </MDBox>
        <MDBox>
          <MDSnackbar
            color="error"
            icon="warning"
            title="Failed"
            content="Please Select Valid Document."
            open={showSnackbar}
            close={() => setShowSnackbar(false)}
          />
        </MDBox>
        <MDBox>
          <Dialog
            open={alertDeleteInvoice}
            onClose={hideInvoiceAlert}
            aria-labelledby="alert-dialog-title"
            sx={
              darkMode
                ? {
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",
                        backgroundColor: "#424242 !important",
                        color: "white !important",
                      },
                    },
                  }
                : {
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",
                        // maxHeight: "600px",
                      },
                    },
                  }
            }
          >
            <DialogContent>
              <DialogContentText sx={{ mt: -2, display: "flex", justifyContent: "center" }}>
                <MDTypography variant="body2" color="secondary" sx={{ fontSize: 70 }}>
                  <WarningAmberIcon sx={{ color: yellow[500], fontSize: 50 }} />
                </MDTypography>
              </DialogContentText>
              <DialogContentText sx={darkMode ? {color:'#ffffff', display: "flex", justifyContent: "center" }:{ display: "flex", justifyContent: "center" }}>
                {selectedInvoice > 1 ? (
                  <h3>Are you sure you want to delete {selectedInvoice} invoices?</h3>
                ) : StoreInvoiceNumber ? (
                  <h3> Are you sure you want to delete invoice {StoreInvoiceNumber}? </h3>
                ) : (
                  <h3> Are you sure you want to delete this invoice? </h3>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
              <MDButton
                variant="gradient"
                color="success"
                disabled={dltButton}
                onClick={successInoiceDelete}
              >
                Yes, delete it!
              </MDButton>
              <MDButton
                variant="gradient"
                color="error"
                disabled={dltButton}
                onClick={hideInvoiceAlert}
              >
                Cancel
              </MDButton>
            </DialogActions>
          </Dialog>
        </MDBox>
        <MDBox>
          <Dialog
            open={alertMoveInvoice}
            onClose={hideMoveAlert}
            aria-labelledby="alert-dialog-title"
            sx={
              darkMode
                  ? {
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        // height: "40%",
                        maxWidth: "500px",
                        backgroundColor: "#424242 !important",
                        color: "white !important",
                      },
                    }
                  }
                  : {
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        // height: "40%",
                        maxWidth: "500px",
                      },
                    }
                  }
              }
          >
            <DialogContent>
              <DialogContentText sx={darkMode
                  ? {
                      color: "#ffffff",
                      mt: 3, display: "flex", justifyContent: "center" 
                    }
                  : {
                    mt: 3, display: "flex", justifyContent: "center" 
                    }}>
                {selectedInvoice > 1 ? (
                  <h3>Are you sure you want to move {selectedInvoice} invoices?</h3>
                ) : StoreInvoiceNumber ? (
                  <h3> Are you sure you want to move invoice {StoreInvoiceNumber}? </h3>
                ) : (
                  <h3> Are you sure you want to move this invoice? </h3>
                )}
              </DialogContentText>
              <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                <MDTypography
                  variant="body1"
                  color="info"
                  sx={{ p: 2, justifyContent: "center", display: "flex", flexDirection: "column" }}
                >
                  {splitCamelCaseToString(selectTab)}
                </MDTypography>
                <MDTypography
                  variant="body2"
                  color="#037ffd"
                  sx={{
                    fontSize: 40,
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {" "}
                  <DriveFileMoveIcon sx={{ color: "#037ffd", fontSize: 40 }} />
                </MDTypography>
                <MDTypography
                  variant="body1"
                  color="info"
                  sx={{ p: 2, justifyContent: "center", display: "flex", flexDirection: "column" }}
                >
                  {splitCamelCaseToString(tabsMove)}
                </MDTypography>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
              <MDButton
                variant="gradient"
                color="success"
                disabled={disableMoveButton}
                onClick={successInoiceMove}
              >
                Yes, Move it!
              </MDButton>
              <MDButton
                variant="gradient"
                color="error"
                disabled={disableMoveButton}
                onClick={hideMoveAlert}
              >
                Cancel
              </MDButton>
            </DialogActions>
          </Dialog>
        </MDBox>
        <MDBox>
          <Dialog
            open={alertIssueInvoice}
            onClose={hideIssueAlert}
            aria-labelledby="alert-dialog-title"
            sx={
              darkMode
                ? {
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "575px",
                        backgroundColor: "#424242 !important",
                        color: "white !important",
                      },
                    },
                  }
                : {
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxHeight: "50%",
                        maxWidth: "500px",
                      },
                    },
                  }
            }
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={darkMode ? {justifyContent: "center",display: "flex",color: "#ffffff"} : {justifyContent: "center",display: "flex"}}
            >
              Issues
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Demo style={{ backgroundColor: darkMode ? "#424242" : "" }}>
                    <List sx={{color: darkMode ? "#ffffff" : "",mt: 4,mb: 2,flexBasis: "95%!important"}} >
                      {generate()}
                    </List>
                  </Demo>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDButton
                style={{color: darkMode ? "#ffffff" : "", backgroundColor: darkMode ? "transparent" : ""}}
                onClick={hideIssueAlert}
              >
                Close
              </MDButton>
            </DialogActions>
          </Dialog>
        </MDBox>
        <MDBox>
          <Dialog
            open={assignNewClient1}
            onClose={() => setAssignNewClient1(false)}
            aria-labelledby="alert-dialog-title"
            sx={
              darkMode
                ? {
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",
                        backgroundColor: "#424242 !important",
                        color: "white !important",
                      },
                    },
                  }
                : {
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",
                        // maxHeight: "600px",
                      },
                    },
                  }
            }
          >
            {" "}
            <DialogTitle
              sx={darkMode ? {color:'#ffffff',justifyContent: "center",display: "flex"} : {justifyContent: "center", display: "flex"}}
            >
              Add Owner For - {ownerName}
            </DialogTitle>
            <DialogContent>
              <AddOwnerDetailsDialog businessId={businessId} />
            </DialogContent>
          </Dialog>
        </MDBox>
        <MDBox>
          <MDSnackbar
            color="success"
            icon="done"
            title="Success"
            content="Documents moved successfully."
            open={showMoveSnackbar}
            close={() => setShowMoveSnackbar(false)}
          />
        </MDBox>
        <MDBox>
          <MDSnackbar
            color="success"
            icon="done"
            title="Success"
            content="Document Deleted Successfully."
            open={showDeleteSnackbar}
            close={() => setShowDeleteSnackbar(false)}
          />
        </MDBox>
        <MDBox>
          <MDSnackbar
            color="error"
            icon="warning"
            title="Failed"
            content={"Invalid Document"}
            open={invalidDocumnet}
            close={() => setInvalidDocumnet(false)}
          />
        </MDBox>
        <MDBox>
          <MDSnackbar
            color="error"
            icon="warning"
            title="Failed"
            content={moveErrorMessage}
            open={showErrorMoveSnackbar}
            close={() => setShowErrorMoveSnackbar(false)}
          />
        </MDBox>
        <MDBox>
          <MDSnackbar
            color="error"
            icon="warning"
            title="Failed"
            content={deleteErrorMessage}
            open={showErrorDeleteSnackbar}
            close={() => setShowErrorDeleteSnackbar(false)}
          />
        </MDBox>
        <MDBox>
          <MDSnackbar
            color={titleShow.toLowerCase()}
            icon={iconShow}
            title={titleShow}
            content={completemassage}
            open={showCompleteSnackbar}
            close={() => setShowCompleteSnackbar(false)}
          />
        </MDBox>
        {/* <NotificationBox notifications={notifications} /> */}
        <MDBox>
          <CustomDialog2
            DialogContentText={() => AsignNewClientMsg()}
            open={assignNewClient}
            onClose={() => setAssignNewClient(false)}
            onSuccess={successAssignNewClient}
            Title={""}
            center={true}
            height={"27%"}
            buttonOk={"Assign client"}
            button={"Cancel"}
          />
        </MDBox>
        <MDBox>
          <CustomDialog
            DialogContentText={() => IssueIsExisttext()}
            open={issueExist}
            onClose={() => setIssueExist(false)}
            onSuccess={null}
            Title={""}
            // height={'27%'}
            button={"Close"}
          />
        </MDBox>
        <MDBox>
          {loadingCircul && (
            <CircularProgress
              size={50}
              sx={{
                color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}
export default UserHoc(AccountantInvoice);
