import * as React from "react";
import { useEffect, useState } from "react";
//material ui core component
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import * as Yup from "yup";
//material ui core
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { eventBus } from "utils/eventbus";

// Material Dashboard 2 PRO React examples
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";

// NewUser layout schemas for form and form feilds
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";


//formik
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import {
  CreateComments,
  DeleteInvoice,
  DeleteItem,
  GetAuditLogList,
  GetCommentsList,
  GetInvoiceDetails,
  GetItemList,
  UpdateInvoiceDetails,
} from "../../../../services/userservices";
import { useAPICall } from "../../../../utils/useapicall";

//open dialog
import EditVendorDialog from "./editvendordialog";

import TimelineItem from "./components/Timeline/TimelineItem";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ErrorEditInvoiceDialog({ invoiceId, businessId }) {
  const [invoice_id, setInvoiceId] = useState(invoiceId);
  const [business_id, setBusinessId] = useState(businessId);
  const [createObjectURL, setCreateObjectURL] = useState(null);
  const [value, setValue] = useState(0);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [vendorname, setVendorname] = useState("");
  const [invoicenumber, setInvoiceNumber] = useState("");
  const [invoicedate, setInvoiceDate] = useState("");
  const [uploaddate, setUploadDate] = useState("");
  const [alert, setAlert] = useState(false);
  const [alertDeleteInvoice, setAlertDeleteInvoice] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [toggleShow, setToggleShow] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openEditVendor, setOpenEditVendor] = useState(false);
  const [total, setTotal] = useState("0");
  const [tax, setTax] = useState("0");
  const [poNumber, setPoNumber] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [subtotal, setSubTotal] = useState("0");
  const [itemList, setItemList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showDetails, setShowDetails] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const [btnDisable, setBtnDisable] = useState(false);
  const [btnDisable1, setBtnDisable1] = useState(false);
  const [auditLogList, setAuditLogList] = useState([]);
  const [commentsList, setCommentsList] = useState([]);
  const [itemId, setItemId] = useState([]);
  const [commentsState, setCommentstate] = useState("");
  const [editItemId, setEditItemId] = useState([]);
  const [italic, setItalic] = React.useState(false);
  const [fontWeight, setFontWeight] = React.useState("normal");
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(UpdateInvoiceDetails, {
    onLoad: false,
  });
  const [
    { isLoading: isDetailsLoading, isError: isDetailsError, response: DetailsResponse },
    DetailsUserAPICall,
  ] = useAPICall(GetInvoiceDetails, { onLoad: false });
  const [
    { isLoading: isAuditLogLoading, isError: isAuditLogError, response: AuditLogResponse },
    AuditLogListAPICall,
  ] = useAPICall(GetAuditLogList, { onLoad: false });
  const [
    { isLoading: isCommentsLoading, isError: isCommentsError, response: CommentsResponse },
    CommentsListAPICall,
  ] = useAPICall(GetCommentsList, { onLoad: false });
  const [
    { isLoading: isDeleteItemLoading, isError: isDeleteItemError, response: DeleteItemResponse },
    DeleteItemListAPICall,
  ] = useAPICall(DeleteItem, { onLoad: false });
  const [
    { isLoading: isGetItemListLoading, isError: isGetItemListError, response: GetItemListResponse },
    GetItemListListAPICall,
  ] = useAPICall(GetItemList, { onLoad: false });
  const [
    {
      isLoading: isDeleteInvoiceLoading,
      isError: isDeleteInvoiceError,
      response: DeleteInvoiceResponse,
    },
    DeleteInvoiceListAPICall,
  ] = useAPICall(DeleteInvoice, { onLoad: false });
  const [
    {
      isLoading: isCreateComentsLoading,
      isError: isCreateComentsError,
      response: CreateComentsResponse,
    },
    CreateComentsListAPICall,
  ] = useAPICall(CreateComments, { onLoad: false });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isCreateComentsLoading) {
      return () => null;
    }
    if (isCreateComentsError) {
      setLoading(false);
      setBtnDisable1(false);
      return () => null;
    }
    if (CreateComentsResponse && CreateComentsResponse.data.isSuccess === true) {
      // setLoading(false);
      setBtnDisable1(false);
      setCommentstate("");
      CommentsListAPICall({
        payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
      });
    }
  }, [CreateComentsResponse]);

  useEffect(() => {
    if (isDeleteInvoiceLoading) {
      return () => null;
    }
    if (isDeleteInvoiceError) {
      setLoading(false);
      return () => null;
    }
    if (DeleteInvoiceResponse && DeleteInvoiceResponse.data.isSuccess === true) {
      eventBus.$emit("closeDeleteItemPopup", DeleteInvoiceResponse);
      setLoading(true);
    }
  }, [DeleteInvoiceResponse]);

  useEffect(() => {
    if (isGetItemListLoading) {
      return () => null;
    }
    if (isGetItemListError) {
      return () => null;
    }
    if (GetItemListResponse && GetItemListResponse.data.isSuccess === true) {
      const data = GetItemListResponse.data.data.records.map((prop, key) => {
        return {
          item: prop.description,
          qty: prop.quantity,
          unit: prop.unit_of_measure,
          price: prop.unit_price,
          total: prop.total,
          id: prop._id,
          sku: prop.sku,
          action: (
            <MDBox>
              <MDTypography variant="body2" color="secondary">
                <Tooltip
                  title="edit"
                  placement="top"
                  sx={{ cursor: "pointer", ml: 2 }}
                  onClick={(value) => {
                    // setAccountantId(prop._id);
                    handleEditItem();
                    setEditItemId(prop._id);
                  }}
                >
                  <Icon>edit</Icon>
                </Tooltip>
              </MDTypography>
            </MDBox>
          ),
        };
      });
      setItemList(data);
      setLoading(false);
    }
  }, [GetItemListResponse]);

  useEffect(() => {
    if (isDeleteItemLoading) {
      return () => null;
    }
    if (isDeleteItemError) {
      setLoading(false);
      return () => null;
    }
    if (DeleteItemResponse && DeleteItemResponse.data.isSuccess === true) {
      GetItemListListAPICall({
        payload: [{ InvoiceId: `${invoice_id}` }],
      });
      setLoading(false);
    }
  }, [DeleteItemResponse]);

  useEffect(() => {
    if (isCommentsLoading) {
      return () => null;
    }
    if (isCommentsError) {
      return () => null;
    }
    if (CommentsResponse && CommentsResponse.data.isSuccess === true) {
      const LogCommentdata = CommentsResponse.data.data.records.map((prop, key) => {
        const commentBy =
          prop.commentBy.fname.charAt(0).toUpperCase() +
          prop.commentBy.fname.slice(1) +
          " " +
          prop.commentBy.lname;
        const comments = prop.comment;
        const Createdate = new Date(prop.createdAt);
        return (
          <Paper style={{ padding: "30px 20px" }}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Avatar alt="Remy Sharp" sx={{ width: 33, height: 33 }} />
              </Grid>
              <Grid justifyContent="left" item xs zeroMinWidth>
                <div style={{ display: "flex" }}>
                  <h4
                    style={{
                      margin: 0,
                      textAlign: "left",
                      fontFamily: "Arial",
                      fontSize: "0.875rem",
                      fontWeight: "bold",
                      lineHeight: 1.5,
                      textTransform: "uppercase",
                      letterSpacing: "0.02857em",
                      opacity: 1,
                      textTransform: "none",
                      verticalAlign: "unset",
                      textAlign: "left",
                      marginBottom: "5px",
                    }}
                  >
                    {commentBy}
                  </h4>
                  <p
                    style={{
                      fontFamily: "Arial",
                      fontSize: "0.75rem",
                      fontWeight: 300,
                      lineHeight: 1.25,
                      marginLeft: "12px",
                      marginTop: "4.5px",
                    }}
                  >
                    {prop.createdAt}
                    {/* {
                                            Createdate.toLocaleString()
                                        } */}
                    {/* {Createdate.toDateString() + " " + Createdate.toLocaleTimeString('en-US')} */}
                  </p>
                </div>
                <p
                  style={{
                    margin: 0,
                    fontFamily: "Arial",
                    fontSize: "0.875rem",
                    fontWeight: 300,
                    lineHeight: 1.5,
                    textTransform: "uppercase",
                    letterSpacing: "0.02857em",
                    opacity: 1,
                    textTransform: "none",
                    verticalAlign: "unset",
                    textAlign: "left",
                  }}
                >
                  {comments}
                </p>
                {/* <p style={{ textAlign: "left", color: "gray" }}>
                                    posted 1 minute ago
                                </p> */}
              </Grid>
            </Grid>
          </Paper>
        );
      });
      setCommentsList(LogCommentdata);
      setLoading(false);
    }
  }, [CommentsResponse]);

  useEffect(() => {
    if (isAuditLogLoading) {
      return () => null;
    }
    if (isAuditLogError) {
      return () => null;
    }
    if (AuditLogResponse && AuditLogResponse.data.isSuccess === true) {
      const Logdata = AuditLogResponse.data.data.records.map((prop, key) => {
        return (
          <TimelineItem dateTime={prop.createdAt} description={prop.message} badges={["design"]} />
        );
      });

      setAuditLogList(Logdata);
      setLoading(false);
    }
  }, [AuditLogResponse]);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setBtnDisable(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      DetailsUserAPICall({
        payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
      });
      setBtnDisable(false);
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);

      setLoading(true);
    }
  }, [response]);

  useEffect(async () => {
    await DetailsUserAPICall({
      payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
    });
  }, []);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openAddItem) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openAddItem]);

  useEffect(() => {
    if (isDetailsLoading) {
      return () => null;
    }

    if (isDetailsError) {
      return () => null;
    }

    if (DetailsResponse && DetailsResponse.data.isSuccess === true) {
      setVendorname(
        DetailsResponse.data.data.invoice.vendor
          ? DetailsResponse.data.data.invoice.vendor.name
          : ""
      );
      setInvoiceNumber(DetailsResponse.data.data.invoice.invoice_number);
      setInvoiceDate(DetailsResponse.data.data.invoice.invoice_date);
      setUploadDate(DetailsResponse.data.data.invoice.createdAt);
      setTotal(DetailsResponse.data.data.invoice.total);
      setTax(DetailsResponse.data.data.invoice.tax);
      setSubTotal(DetailsResponse.data.data.invoice.sub_total);
      setCurrencySymbol(DetailsResponse.data.data.invoice.currency_symbol);
      setDocumentType("");
      setCreateObjectURL(DetailsResponse.data.data.invoice.uploadedFileUrl);
      setShowDetails(true);
      setLoading(false);
    }
  }, [DetailsResponse]);

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      setLoading(true);
      await GetItemListListAPICall({
        payload: [{ InvoiceId: `${invoice_id}` }],
      });
    } else if (newValue === 2) {
      setLoading(true);
      await AuditLogListAPICall({
        payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
      });
    } else if (newValue === 3) {
      setLoading(true);
      await CommentsListAPICall({
        payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
      });
    }
  };
  const handleSubmit = async (event, values) => {
    setBtnDisable(true);

    await setAPICall({
      payload: [
        {
          invoice_number: `${event.invoicenumber}`,
          total: `${event.total}`,
          tax: `${event.tax}`,
          sub_total: `${event.subtotal}`,
          // "invoice_number": `${event.ponumber}`,
          invoice_date: `${event.invoicedate}`,
          InvoiceId: `${invoice_id}`,
          businessId: `${businessId}`,
        },
      ],
    });
  };
  const handleSubmit2 = async (event, values) => {
    // setBtnDisable1(true);
  };
  const handleToggle = () => {
    setToggleShow(!toggleShow);
  };
  const handleAddItem = () => {
    setOpenAddItem(true);
  };
  const handleEditItem = () => {
    setOpenEditItem(true);
  };

  const handleEditVendor = () => {
    setOpenMenu(false);
    setOpenEditVendor(true);
  };

  const warningWithConfirmMessage = () => {
    setAlert(true);
  };
  const hideAlert = () => {
    setAlert(false);
  };
  const successDelete = () => {
    DeleteItemListAPICall({
      payload: [
        {
          adjustInvoice: false,
          InvoiceId: `${invoice_id}`,
          ItemId: `${itemId}`,
        },
      ],
    });
    setAlert(false);
    setLoading(true);
  };

  const deleteInvoiceConfirmMessage = () => {
    setAlertDeleteInvoice(true);
  };
  const hideInvoiceAlert = () => {
    setAlertDeleteInvoice(false);
  };
  const successInoiceDelete = () => {
    DeleteInvoiceListAPICall({
      payload: [
        {
          documentIds: {
            documentIds: [invoiceId],
          },
          businessId: `${businessId}`,
        },
      ],
    });
    setAlert(false);
    setLoading(true);
  };

  const handleDeleteInvoice = () => {
    // setOpenMenu(false)

    setAlertDeleteInvoice(true);
  };
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  //render menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <MenuList>
        <MenuItem onClick={handleEditVendor}>Edit Vendor</MenuItem>
        <MenuItem style={{ color: "red" }} onClick={handleDeleteInvoice}>
          Delete Invoice
        </MenuItem>
      </MenuList>
    </Menu>
  );

  const dataTableData = {
    columns: [
      {
        Header: "Item Name",
        accessor: "item",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Category",
        accessor: "category",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Customer",
        accessor: "customer",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Qty",
        accessor: "qty",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Unit",
        accessor: "unit",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Price",
        accessor: "price",
        Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
      },
      {
        Header: "Total",
        accessor: "total",
        Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
      },
      {
        Header: "Actions",
        accessor: "action",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: itemList,
  };
  const dataTableData1 = {
    columns: [
      {
        Header: "Item Name",
        accessor: "item",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Category",
        accessor: "category",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Class",
        accessor: "class",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Customer",
        accessor: "customer",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Qty",
        accessor: "qty",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Unit",
        accessor: "unit",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Price",
        accessor: "price",
        Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
      },
      {
        Header: "Total",
        accessor: "total",
        Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
      },
      {
        Header: "Actions",
        accessor: "action",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: itemList,
  };
  const initialValues = {
    vendorname: vendorname,
    documenttype: documentType,
    invoicenumber: invoicenumber,
    ponumber: poNumber,
    invoicedate: invoicedate,
    uploaddate: uploaddate,
    total: `${total.replace(",", "")}`,
    tax: `${tax.replace(",", "")}`,
    subtotal: `${subtotal.replace(",", "")}`,
  };

  const initialValues2 = {
    Comment: "",
  };

  const InvoiceEditSchema2 = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("Comment is required.")
      .min(3, "Comment should be more than 3 characters."),
  });

  eventBus.$on("closeAddNewItemPopup", (data) => fetchNewInvoicesAdd(data));

  function fetchNewInvoicesAdd(data) {
    setOpenAddItem(false);
    setLoading(true);

    GetItemListListAPICall({
      payload: [{ InvoiceId: `${invoice_id}` }],
    });
    DetailsUserAPICall({
      payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
    });
  }

  eventBus.$on("closeEditItemPopup", (data) => fetchNewInvoices(data));

  function fetchNewInvoices(data) {
    setOpenEditItem(false);
    setLoading(true);
    GetItemListListAPICall({
      payload: [{ InvoiceId: `${invoice_id}` }],
    });
    DetailsUserAPICall({
      payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
    });
  }

  const CommentWithInput = (event, values) => {
    event.preventDefault();
  };

  return (
    <>
      {!showDetails ? (
        <>
          <center>
            <CircularProgress
              size={50}
              sx={{
                color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          </center>
        </>
      ) : (
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={5}>
              <Card sx={{ height: "100%" }}>
                {/* <div>
                                        {createObjectURL ? <Image src={createObjectURL} /> :
                                            <Image fontSize="large" />}
                                    </div> */}
                {createObjectURL ? (
                  <embed src={createObjectURL + "#toolbar=0"} height="600" />
                ) : (
                  <h4>You can only add image after you save changes</h4>
                )}
              </Card>
              {<center>1 of 1</center>}
            </Grid>
            <Grid item xs={12} sm={6} lg={7}>
              <Card sx={{ height: "100%" }}>
                <CardContent>
                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "30%",
                    }}
                  >
                    <MDTypography variant="h2" color="error">
                      Failed to fetch data please retry
                    </MDTypography>
                  </MDBox>
                </CardContent>
              </Card>

              <MDBox>
                {loading && (
                  <CircularProgress
                    size={50}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </MDBox>
            </Grid>
          </Grid>
          {/* Edit Vendor Dialog */}
          <MDBox>
            <MDBox>
              <Dialog
                open={openEditVendor}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "570px",
                    },
                  },
                }}
              >
                <DialogContent>
                  <EditVendorDialog
                    invoiceId={invoiceId}
                    businessId={businessId}
                    onClose={() => setOpenEditVendor(false)}
                  />
                </DialogContent>
              </Dialog>
            </MDBox>
          </MDBox>
          {/* Delete invoice Dialog */}
          <MDBox>
            <Dialog
              open={alertDeleteInvoice}
              onClose={hideInvoiceAlert}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "450px",
                  },
                },
              }}
            >
              <DialogContent sx={{ overflowY: "hidden" }}>
                <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                  <h3>Are you sure?</h3>
                </DialogContentText>
                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                  >
                    Once you delete this Invoice there is no way of getting any of the documents or
                    data back.
                  </MDTypography>
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
                <MDButton variant="gradient" color="success" onClick={successInoiceDelete}>
                  Yes, delete it!
                </MDButton>
                <MDButton variant="gradient" color="error" onClick={hideInvoiceAlert}>
                  Cancel
                </MDButton>
              </DialogActions>
            </Dialog>
          </MDBox>
        </MDBox>
      )}
    </>
  );
}

export default ErrorEditInvoiceDialog;
