import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Button, Modal, ThemeProvider, createTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from '@mui/material/Paper';
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { green } from "@mui/material/colors";
import { DataGrid, GridCellModes } from "@mui/x-data-grid";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import CustomDialog from "components/CustomDialog";
import CustomDialog2 from "components/CustomDialog/customDialog2";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { textEllipsis } from "components/textEllipsis";
import { useMaterialUIController } from "context";
import { Form, Formik, isString } from "formik";
import FormFieldDate from "layouts/accountants/user/components/FormFieldDate";
import FormField from "layouts/admin/accountants/components/FormField";
import AddOwnerDetailsDialog from "layouts/clients/add-owner";
import Moment from "moment";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/cjs/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { TransformComponent, TransformWrapper, useControls } from "react-zoom-pan-pinch";
import { eventBus } from "utils/eventbus";
import { inLocalTime } from "utils/timeHelper";
import { canAccess } from "helpers";
import { AddNewInvoices, AddVendors, CreateComments, DeleteInvoice, DeleteItem, EditItem, GetAuditLogList, GetCommentsList, GetInvoiceDetails, GetInvoiceList, GetIssueList, GetItemList, GetVendorList, GetclientUserAllList, GetuserClientDetails, GetuserClientList, MoveMenuInvoice, StatementDocumentList, UpdateInvoiceDetails } from "../../../../services/clientservices";
import { useAPICall } from "utils/useapicall";
import LoaderDailog from "layouts/accountants/user/components/imgLoader/LoaderDailog.js";
import { InvoiceEditSchema } from "layouts/accountants/user/components/schemas/editinvoicevalidation.js";
import TimelineItem from "./components/Timeline/TimelineItem";
// import mentionsInputStyle from "./mentionsInputStyle";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import EditStatementInvoiceDialog from "./editStatmentInvoice";
import "layouts/accountants/user/invoice/styles.css";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const myTheme = createTheme({
    palette: {
      type: "dark",
    },
    typography: {
      fontSize: 13,
      fontWeight: 600,
      backgroundColor: "rgb(123, 128, 154)",
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          row: {
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-actionsCell": {
              gridGap: "0px",
            },
            "& .MuiDataGrid-cell": {
              justifyContent: "center",
              outline: " none",
              fontWeight: "bold !important",
              color: "rgb(123 128 154)",
              // border: 0,
            },
            "& .MuiDataGrid-cell:focus": {
              outline: " none",
            },
            "&.Mui-selected": {
              backgroundColor: "white",
              color: "black",
              "&:hover": {
                backgroundColor: "white",
              },
            },
          },
        },
      },
    },
  });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} >
        {value === index && ( <Box> <Typography>{children}</Typography> </Box> )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return { id: `simple-tab-${index}`, "aria-controls": `simple-tabpanel-${index}` };
  }

  function EditStatementDialog({invoiceIds, businessId, onIssueClick,onIssueClickData,documentsId, onClickClose, moduleTypeEdit,selectTab,onClose, storeResponce, onClose1, reloadApiAfterClose,issueType,selectedIndex,}) {
    let newObj = {};
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;
    const [textAreaVal, setTextAreaVal] = useState("");
    const [rotationAngle, setRotationAngle] = useState(0);
    const [novendorIssue, setNoVendorIssue] = useState(false);
    const [duplicateIssue, setDuplicateIssue] = useState(false);
    const [totalIssue, setTotalIssues] = useState(0);
    const [novendorIssueMsg, setNoVendorIssueMsg] = useState("");
    const [searchInvoice , setSearchInvoice] = useState('');
    const [documentsList , setDocumentsList] = useState([]);
    const [isDisable ,setIsDisable] = useState(false);
    const [duplicateMsg, setDuplicateMsg] = useState("");
    const [priceMismatch, setPriceMismatch] = useState(false);
    const [priceMismatchMsg, setPriceMismatchMsg] = useState("");
    const [totalMismatch, setTotalMismatch] = useState(false);
    const [totalMismatchMsg, setTotalMismatchMsg] = useState(false);
    const [onlytotalMismatch, setOnlyTotalMismatch] = useState(false);
    const [invoiceDateMismatch, setInvoiceDateMismatch] = useState(false);
    const [noProduct, setNoProduct] = useState(false);
    const [openList, setOpenList] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentPageRowSize, setCurrentPageRowSize] = useState(false);
    const [noUnitProduct, setNoUnitProduct] = useState(false);
    const [noQuantity, setNoQuantity] = useState(false);
    const [noItem, setNoItem] = useState(false);
    const [allRows , setAllRows] = useState([]);
    const [assignedClients , setAssignedClients] = useState([]);
    const [showButton, setShowButton] = useState(false);
    const [showPassDuplicate ,setShowPassDuplicate] = useState(false);
    const [noPrice, setNoPrice] = useState(false);
    const [noCategory, setNoCategory] = useState(false);
    const [anchor, setAnchor] = useState(null);
    const [documentTypeObj ,setDocumentTypeObj] = useState("");
    const [invoiceNumberObj ,setInvoiceNumberObj] = useState("");
    const [ponumberObj ,setPonumberObj] = useState("");
    const [showApprovedModel, setShowApprovedModel] = useState(false);
    const [invoice_id, setInvoice_Id] = useState(invoiceIds);
    const [invoiceId, setInvoiceId] = useState(invoiceIds);
    const [openQboSyncAskClient, setOpenQboSyncAskClient] = useState(false);
    const [createObjectURL, setCreateObjectURL] = useState(null);
    const [imageUrl, setImageUrl] = useState("");
    const [counts, setCounts] = useState(0);
    const [imageUrl1, setImageUrl1] = useState("");
    const [value, setValue] = useState(1);
    const [ErrorMessages, setErrorMessage] = useState("");
    const [passDuplicateMsg, setpassDuplicateMsg] = useState("");
    const [ownerName, setOwnerName] = useState("");
    const [openAddOwner, setOpenAddOwner] = useState(false);
    const [onIssueClick1, setOnIssueClick1] = useState(onIssueClick);
    const [styles, setStyles] = useState({});
    const [editbleData, setEditbleData] = useState(false);
    const [checkFound, setCheckFound] = useState(false);
    const [showErrorMoveSnackbar, setShowErrorMoveSnackbar] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [vendorSelect, setVendorSelect] = useState("");
    const [vendorSelect1, setVendorSelect1] = useState("");
    const [vendorname, setVendorname] = useState("");
    const [vendorname1, setVendorname1] = useState("");
    const [vendorname2, setVendorname2] = useState("");
    const [addVendoreerror, setAddVendoreerror] = useState(false);
    const [vendorList, setVendorList] = useState([]);
    const [showTrueSnackbar, setShowTrueSnackbar] = useState(false);
    const [showFalseSnackbar, setShowFalseSnackbar] = useState(false);
    const [showreportSnackbar, setShowReportSnackbar] = useState(false);
    const [invoicenumber, setInvoiceNumber] = useState("");
    const [totalItemLength, setTotalItemLength] = useState(0);
    const [transactionDate, setTransactionDate] = useState("");
    const [vendorEditable, setVendorEditable] = useState(true);
    const [uploaddate, setUploadDate] = useState("");
    const [alert, setAlert] = useState(false);
    const [passDuplicate , setPassDuplicate] = useState(false);
    const [ocrStatus, setOcrstatus] = useState(false);
    const [ocrStatus1, setOcrstatus1] = useState("");
    const [alertDeleteInvoice, setAlertDeleteInvoice] = useState(false);
    const [toggleShow, setToggleShow] = useState(false);
    const [openEditItem, setOpenEditItem] = useState(false);
    const [openAddItem, setOpenAddItem] = useState(false);
    const [openCategoryAddItem, setOpenCategoryAddItem] = useState(false);
    const [openEditVendor, setOpenEditVendor] = useState(false);
    const [total, setTotal] = useState(0);
    const [dottedStyle, setDottedStyle] = useState({});
    const [orderTotal, setOrderTotal] = useState(0);
    const [orderTotalStore, setOrderTotalStore] = useState(0);
    const [tax, setTax] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [shippingprice, setShippingprice] = useState(0);
    const [onChanges, SetonChanges] = useState(false);
    const [discountStore, setDiscountStore] = useState(0);
    const [discountStatic, setDiscountStatic] = useState(0);
    const [shippingpriceStore, setShippingpriceStore] = useState(0);
    const [shippingpriceStatic, setShippingpriceStatic] = useState(0);
    const [firstTime, setFirstTime] = useState(true);
    const [ponumber, setPoNumber] = useState("");
    const [documentType, setDocumentType] = useState("");
    const [subtotal, setSubTotal] = useState(0);
    const [subTotalForMatch, setSubTotalForMatch] = useState(0);
    const [totalForMatch, setTotalForMatch] = useState(0);
    const [transactionDateForMatch, setTransactionDateForMatch] = useState("");
    const [itemList, setItemList] = useState([]);
    const [summeryList, setSummeryList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showLoaderFirInvoice, setShowLoaderFirInvoice] = useState(true);
    const [disableAskButton, setDisableAskButton] = useState(false);
    const [disableMoveButton, setDisableMoveButton] = useState(false);
    const [loadingCircul, setLoadingCircul] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [currencySymbol, setCurrencySymbol] = useState("$");
    const [btnDisable, setBtnDisable] = useState(false);
    const [btnDisable1, setBtnDisable1] = useState(false);
    const [auditLogList, setAuditLogList] = useState([]);
    const [commentsList, setCommentsList] = useState([]);
    const [submitcomment, setsubmitcomment] = useState(false);
    const [itemId, setItemId] = useState([]);
    const [oneItemPopup, setOneItemPopup] = useState(false);
    const [unitPrice, setUnitPrice] = useState("");
    const [showselectedVendorId, setShowselectedVendorId] = useState("");
    const [editItemId, setEditItemId] = useState([]);
    const [showDetailsForm, setShowDetailsForm] = useState(false);
    const [editable, SetEditable] = useState(false);
    const [taxStore, setTaxStore] = useState(0);
    const [actualSubtotal, setActualSubtotal] = useState(0);
    const [actualTotal, setActualTotal] = useState(0);
    const [matchId, setMatchId] = useState("");
    const [subTotStore, setSubTotStore] = useState(0);
    const [issues, setIssues] = useState([]);
    const [TotalStore, setTotalStore] = useState(total);
    const [resetData, setResetData] = useState(false);
    const [documentSource, setDocumnetSource] = useState("");
    const [isSynced, setIsSynced] = useState(false);
    const [documentStatus, setDocumentStatus] = useState("");
    const [openQboSync, setOpenQboSync] = useState(false);
    const [openAddNewInvoice, setOpenAddNewInvoice] = useState(false);
    const [extention, setExtention] = useState("");
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [itemListResponse, SetItemListResponse] = useState([]);
    const [vendorerrormsg, setVendorerrormsg] = useState("");
    const [vendorerror, setVendorerror] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [checked, setChecked] = useState(newObj);
    const [pushPopFun, setPushPopFun] = useState(false);
    const [ownerAssigned, setOwnerAssigned] = useState(false);
    const [assignNewClient, setAssignNewClient] = useState(false);
    const [tabsMove, setTabsMove] = useState("");
    const [checkIssue, setCheckIssue] = useState(false);
    const [paginationModel, setPaginationModel] = React.useState({ pageSize: 5, page: 0 });
    const [alertMoveInvoice, setalertMoveInvoice] = useState(false);
    const [finalSelect, setFinalSelect] = useState("");
    const [transactionDateObj , setTransactionDateObj] = useState("");
    const [addNewVendorId, setAddNewVendorId] = useState("");
    const [moveTowhere, setmoveTowhere] = useState("");
    const [users, setUsers] = useState([]);
    const [chenging, setChenging] = useState(true);
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [selectedId, setSelectedId] = useState(null); 
    const [inputValue, setInputValue] = useState('');
    const [statmentDocList ,setStatmentDocList] = useState([]);
    const [ErrorMessagesVendorError, setErrorMessagesVendorError] = useState("");
    const queryParameters = new URLSearchParams(window.location.search);
    const currentTabs = queryParameters.get("t");
    const limit = localStorage.getItem("limit");
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    var ref = useRef(null);

    const mentionsInputStyle = {
      control: {
        backgroundColor: 'transparent',
        fontSize: 16,
        border: "2px solid #ccc", // Example: changing border color
        "&::placeholder": {
          color: darkMode ? "#a9a9a9" : "#999999", // Custom placeholder color
          opacity: 1, // Ensure placeholder is fully opaque
        },
      },
  
      "&multiLine": {
        control: {
          fontFamily: "monospace",
          minHeight: 70,
          backgroundColor: "transparent", // Example: changing background color
        },
        highlighter: {
          padding: 9,
          border: "1px solid transparent",
        },
        input: {
          padding: 9,
          border: "1px solid silver",
          color: darkMode ? "#ffffff"  :"#333", // Example: changing text color
          // Add the placeholder color here
          "&::placeholder": {
            color: darkMode ? "#ffffff !important" : "#999999", // Custom placeholder color
            opacity: 1, // Ensure placeholder is fully opaque
          },
        },
      },
    
      "&singleLine": {
        display: "inline-block",
        width: 180,
    
        highlighter: {
          padding: 1,
          border: "2px inset transparent",
        },
        input: {
          padding: 1,
          border: "2px inset",
          color: darkMode ? "#ffffff"  :"#333", // Example: changing text color
          // Add the placeholder color here
          "&::placeholder": {
            color: darkMode ? "#ffffff !important" : "#999999", // Custom placeholder color
            opacity: 1, // Ensure placeholder is fully opaque
          },
        },
      },
    
      suggestions: {
        list: {
          backgroundColor: "white",
          border: "1px solid rgba(0,0,0,0.15)",
          fontSize: 16,
        },
        item: {
          padding: "5px 15px",
          borderBottom: "1px solid rgba(0,0,0,0.15)",
          "&focused": {
            backgroundColor: "white",
          },
        },
      },
    };

    const [{ isError: isGetUserError, isLoading: isGetUserLoading, response: GetUserResponse },GetUsersetAPICall,] = useAPICall(GetclientUserAllList, { onLoad: false });
    const [{isLoading: isInvoiceDetailsLoading,isError: isInvoiceDetailsError,response: InvoiceDetailsResponse,}, InvoiceDetailsUserAPICall,] = useAPICall(GetuserClientList, { onLoad: false });
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(UpdateInvoiceDetails, { onLoad: false,});
    const [ { isError: isEditeItemError, isLoading: isEditeItemLoading, response: EditeItemResponse }, setEditItemAPICall,] = useAPICall(EditItem, { onLoad: false });
    const [{ isLoading: isDetailsLoading, isError: isDetailsError, response: DetailsResponse },DetailsUserAPICall,] = useAPICall(GetInvoiceDetails, { onLoad: false });
    const [{ isLoading: isAuditLogLoading, isError: isAuditLogError, response: AuditLogResponse }, AuditLogListAPICall,] = useAPICall(GetAuditLogList, { onLoad: false });
    const [{ isLoading: isCommentsLoading, isError: isCommentsError, response: CommentsResponse },CommentsListAPICall,] = useAPICall(GetCommentsList, { onLoad: false });
    const [{ isLoading: isDeleteItemLoading, isError: isDeleteItemError, response: DeleteItemResponse },DeleteItemListAPICall,] = useAPICall(DeleteItem, { onLoad: false });
    const [{ isLoading: isGetItemListLoading, isError: isGetItemListError, response: GetItemListResponse },GetItemListListAPICall,] = useAPICall(GetItemList, { onLoad: false });
    const [{isLoading: isDeleteInvoiceLoading,isError: isDeleteInvoiceError,response: DeleteInvoiceResponse,},DeleteInvoiceListAPICall,] = useAPICall(DeleteInvoice, { onLoad: false });
    const [{isLoading: isCreateComentsLoading,isError: isCreateComentsError,response: CreateComentsResponse,},CreateComentsListAPICall,] = useAPICall(CreateComments, { onLoad: false });
    const [{ isLoading: isMoveMenuLoading, isError: isMoveMenuError, response: MoveMenuResponse },MoveMenuListAPICall,] = useAPICall(MoveMenuInvoice, { onLoad: false });
    const [{ isLoading: isVendorLoading, isError: isVendorError, response: VendorResponse }, VendorListAPICall,] = useAPICall(GetVendorList, { onLoad: false });
    const [{ isLoading: isIssueLoading, isError: isIssueError, response: IssueResponse },IssueListAPICall,] = useAPICall(GetIssueList, { onLoad: false });
    const [{ response: clientDetailsDataResponse }, getClientDetailsAPICall] = useAPICall(GetuserClientDetails, { onLoad: false });
    const action = {COMPLETED: "Completed", Qbotooltip: "QBO",INPROGRESS: "In Progress", PENDING: "Pendding", ERROR: "Error",Movetooltip: "Move",showtooltip: "View",edittooltip: "Edit", deletetooltip: "Delete",};
    const [{ isError: isOnlyUpdateError, isLoading: isOnlyUpdateLoading, response: OnlyUpdateresponse },setOnlyUpdateAPICall,] = useAPICall(UpdateInvoiceDetails, { onLoad: false });
    const [{ isLoading: isGetInoviceListLoading, isError: isGetInvoiceListError, response: GetInvoiceListResponse },GetInvoiceListAPICall ,] = useAPICall(GetInvoiceList, { onLoad: false });
    const [{isLoading: isStatmentDocListLoading,isError: isStatmentDocListError,response: StatmentDocListResponse,},StatmentDocListListAPICall,] = useAPICall(StatementDocumentList, { onLoad: false });

    useEffect(() => {
      if (isStatmentDocListLoading) {
        return () => null;
      }
      if (isStatmentDocListError) {
        setLoadingCircul(false);
        setBtnDisable1(false);
        return () => null;
      }
      if (StatmentDocListResponse && StatmentDocListResponse.data.isSuccess === true) {
        setLoadingCircul(false);
        const data = StatmentDocListResponse.data.data.invoices.map((prop, key) => {
          return {
            docNumber: prop?.docNumber,
            ItemId: prop?._id,
            txnDate: prop?.txnDate,
            total: prop?.total
          };
        });
        setStatmentDocList(data);
        setLoadingCircul(false);
        setTimeout(() => {
          setLoadingCircul(false);
        }, 2000);
      }
    }, [StatmentDocListResponse]);

    useEffect(() => {
      if (isGetInoviceListLoading) {
        return () => null;
      }
      if (isGetInvoiceListError) {
        setLoadingCircul(false);
        return () => null;
      }
      if (GetInvoiceListResponse && GetInvoiceListResponse.data.isSuccess === true) {
        // setClientRecord(GetInvoiceListResponse.data.data.records);
        // setTableEmpty(GetInvoiceListResponse.data.data.totalRecords);
        const Allrowdata = GetInvoiceListResponse.data.data.records.map((prop, key) => {
          return {
            id:prop?._id,
            name: prop.name,
          };
        });
        setAllRows(Allrowdata);
        const data = GetInvoiceListResponse?.data?.data?.records.map((prop, key) => {
          return {
            id:prop?._id,
            snum: key,
            docNumber: prop?.docNumber,
          };
        });
        setDocumentsList(data);
        setLoadingCircul(false);
      }
    }, [GetInvoiceListResponse]);

    useEffect(() => {
      if (isOnlyUpdateLoading) {
        return () => null;
      }
      if (isOnlyUpdateError) {
        setErrorMessage(OnlyUpdateresponse?.data?.message);
        setShowErrorSnackbar(true);
        setTimeout(() => {
          setShowErrorSnackbar(false);
        }, 4000);
        setBtnDisable(false);
        setLoadingCircul(false);
        return () => null;
      }
      
      if (OnlyUpdateresponse && OnlyUpdateresponse.data.isSuccess === true) {
        // setShowSnackbar(true);
        eventBus.$emit("refreshApiInvoiceList", true);
        setBtnDisable(false);
        IssueListAPICall({
          payload: [{ businessId: `${businessId}`, documentId: `${invoice_id}` }],
        });
        DetailsUserAPICall({
          payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
        });
        setTimeout(() => {
          setShowSnackbar(false);
        }, 10000);
        SetEditable(false);
        setLoading(false);
        setLoadingCircul(false);
      }
    }, [OnlyUpdateresponse]);

    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      border: "0",
      boxShadow: 24,
      outline: "none",
      p: 4,
    };

    const useStyles = makeStyles((theme) => ({
      dialogPaper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '80vw', // Adjust the max width as per your needs
        overflow: 'hidden',
        transition: 'all 0.3s',
        '&:focus': {
            outline: 'none',
        },
    },
    dialogTitle: {
        cursor: 'move',
    },
      tabs: {
        "& .MuiTab-root": {
          backgroundColor: "#202940 !important",
          color: "#ffffff !important",
          marginLeft: "3px !important",
          marginRight: "2px !important",
        },
        "& .MuiTab-root.Mui-selected": {
          color: "#202940 !important",
          backgroundColor: "#ffffff !important",
        },
      },
      root: {
        "& .css-15e8ec1-MuiInputBase-input-MuiInput-input.Mui-disabled": {
          WebkitTextFillColor: darkMode ? "#ffffff" : "#000000",
        },
        "& .MuiInput-underline:before": {
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)", // Adjust opacity here
        },
        "& .MuiInput-underline:hover:before": {
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)", // Adjust opacity here
        },
        "& .MuiInput-underline:after": {
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)", // Adjust opacity here
        },
      },
      appBar: {
        position: "relative",
      },
      title: {
        marginLeft: theme.spacing(2),
        flex: 1,
      },
      option: {
        "&:first-child": {
          color: "#0000ff",
        },
        "&:hover:first-child": {
          color: "#0000ff",
          backgroundColor: "none !important",
        },
      },
      InputRoot: {
        "&::-webkit-calendar-picker-indicator": {
          display: "none !important",
        },
      },
    }));

    const classes = useStyles();

    useEffect(async () => {
      setShowLoaderFirInvoice(true);
      GetItemListListAPICall({
        payload: [{ documentId: `${invoiceIds}` }],
      });
      await GetUsersetAPICall({
        payload: [{ keyword: `` }],
      });
    }, []);

    useEffect(() => {
      if (isGetUserLoading) {
        return () => null;
      }
      if (isGetUserError) {
        setLoadingCircul(false);
        setLoading(false);
        return () => null;
      }
      if (GetUserResponse && GetUserResponse.data.isSuccess === true) {
        const data = GetUserResponse?.data?.data?.records.map((prop, key) => {
          return {
            id: prop?._id,
            display: prop?.fname,
          };
        });
        setUsers(data);
        setLoadingCircul(false);
        setLoading(false);
      }
    }, [GetUserResponse]);

    const [{ isLoading: isAddVendorLoading, isError: isAddVendorError, response: AddVendorResponse },AddVendorListAPICall,] = useAPICall(AddVendors, { onLoad: false });
    const [{ isLoading: isAddInvoiceLoading, isError: isAddInvoiceError, response: AddInvoiceResponse },AddInvoiceListAPICall,] = useAPICall(AddNewInvoices, { onLoad: false });

    useEffect(() => {
      if (isAddInvoiceLoading) {
        return () => null;
      }
      if (isAddInvoiceError) {
        setErrorMessage(AddInvoiceResponse?.data?.message);
        setLoading(false);
        setIsDisable(false);
        setShowErrorSnackbar(true);
        setTimeout(() => {
          setShowErrorSnackbar(false);
        }, 3000);
        return () => null;
      }

      if (AddInvoiceResponse && AddInvoiceResponse.data.isSuccess === true) {
        GetItemListListAPICall({
          payload: [{ documentId: `${invoiceId}` }],
        });
        setIsDisable(false);
        setLoading(false);
        setLoadingCircul(false);
        setOpenAddNewInvoice(false);
        setSearchInvoice('');
      }
    }, [AddInvoiceResponse]);


    useEffect(() => {
      if (isAddVendorLoading) {
        return () => null;
      }
      if (isAddVendorError) {
        setErrorMessage(AddVendorResponse?.data?.message);
        setLoading(false);
        setBtnDisable(false);
        setShowErrorSnackbar(true);
        setTimeout(() => {
          setShowErrorSnackbar(false);
        }, 3000);
        return () => null;
      }

      if (AddVendorResponse && AddVendorResponse.data.isSuccess === true) {
        setLoading(false);
        setOpenList(false);
        setAddNewVendorId(AddVendorResponse?.data?.data?.vendor?._id);
        // setVendorname2()
        setOpenEditVendor(false);
        if (AddVendorResponse?.data?.data?.vendor?._id) {
          setLoading(true);
          VendorListAPICall({
            payload: [{ businessId: `${businessId}`, IsSynced: true }],
          });
          setOnlyUpdateAPICall({
            payload: [
              {
                vendorId: `${
                  AddVendorResponse?.data?.data?.vendor?._id
                    ? AddVendorResponse?.data?.data?.vendor?._id
                    : addNewVendorId
                }`,
                businessId: `${businessId}`,
                documnetId: `${invoice_id}`,
              },
            ],
          });
        }
      }
    }, [AddVendorResponse]);

    const [cellModesModel, setCellModesModel] = React.useState({});

    const handleCellClick = React.useCallback((params, event) => {
      if (!params.isEditable) {
        return;
      }

      if (!event.currentTarget.contains(event.target)) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    }, []);

    const handleCellModesModelChange = React.useCallback((newModel) => {
      setCellModesModel(newModel);
    }, []);

    var resource = "";
    var actions = "";
    const [anchorEl, setAnchorEl] = React.useState(null);

    function splitCamelCaseToString(s) {
      return s.split(/(?=[A-Z])/).join(" ");
    }
    useEffect(() => {
      IssueListAPICall({
        payload: [{ businessId: `${businessId}`, documentId: `${invoice_id}` }],
      });
    }, [response]);

    useEffect(() => {
      if (isIssueLoading) {
        return () => null;
      }
      if (isIssueError) {
        setLoading(false);
        return () => null;
      }
      if (IssueResponse && IssueResponse.data.isSuccess === true) {
        GetItemListListAPICall({
          payload: [{ documentId: `${invoiceId}` }],
        });
        setTotalIssues(IssueResponse?.data?.data?.totalRecords);
        if (IssueResponse?.data?.data?.totalRecords == 0) {
          setNoVendorIssue(false);
          setPriceMismatch(false);
          setDuplicateIssue(false);
          setTotalMismatch(false);
          setOnlyTotalMismatch(false);
          setInvoiceDateMismatch(false);
          setNoProduct(false);
          setNoCategory(false);
        } else if (editable == false) {
          const found1 = IssueResponse?.data?.data?.records?.find((obj) => {
            return obj.issueType === "DUPLICATE";
          });

          const found2 = IssueResponse?.data?.data?.records?.find((obj) => {
            return obj.issueType === "BADDOCNO";
          });
          if (found1) {
            setDuplicateIssue(true);
            setNoVendorIssueMsg(`${found1?.message}`);
            setDuplicateMsg(`${found1?.message}`);
          } else if (found2) {
            setDuplicateIssue(true);
            setNoVendorIssueMsg(`${found2?.message}`);
            setDuplicateMsg(`${found2?.message}`);
          } else {
            setDuplicateIssue(false);
          }
          IssueResponse?.data?.data?.records?.map((prop, key) => { prop?.issueType === "NOVENDOR" ? setNoVendorIssue(true) || setNoVendorIssueMsg(`${prop?.message}`): setNoVendorIssue(false);
            prop?.issueType === "NOSUBTOTAL" ? setPriceMismatch(true) || setPriceMismatchMsg(`${prop?.message}`): setPriceMismatch(false);
            prop?.issueType === "TOTALMISMATCH" ? setTotalMismatch(true) || setTotalMismatchMsg(`${prop?.message}`) : setTotalMismatch(false);
            prop?.issueType === "PRICEMISMATCH"? setPriceMismatch(true) || setPriceMismatchMsg(`${prop?.message}`) : setPriceMismatch(false);
            prop?.issueType === "NOTOTAL" ? setOnlyTotalMismatch(true) : setOnlyTotalMismatch(false);
            prop?.issueType === "NOINVOICE_DATE" ? setInvoiceDateMismatch(true) : setInvoiceDateMismatch(false);
            prop?.issueType == "NOPRODUCT" ? setNoProduct(true) : setNoProduct(false);
            prop?.issueType === "NOCATEGORY" ? setNoCategory(true) : setNoCategory(false);
          });
        }
      }
    }, [IssueResponse]);

    useEffect(() => {
      IssueResponse?.data?.data?.records.map((prop, key) => {
        prop?.issueType === "NOPRODUCT" ? setNoProduct(true) : "";
        prop?.issueType === "NOPRICE" ? setNoPrice(true) : "";
        prop?.issueType === "ITEMPRICEMISMATCH" ? setNoPrice(true) : "";
        prop?.issueType === "NOUNITPRICE" ? setNoUnitProduct(true) : "";
        prop?.issueType === "NOQUANTITY" ? setNoQuantity(true) : "";
        prop?.issueType === "NOITEMS" ? setNoItem(true) : "";
        prop?.issueType === "NOCATEGORY" ? setNoCategory(true) : "";
      });
      const found1 = IssueResponse?.data?.data?.records.find((obj) => {
        return obj._id === selectedIndex;
      });
      if (onIssueClick) {
        if (found1) {
          found1?.elements.forEach((element) => {
            switch (element) {
              case "vendorId":
                setNoVendorIssue(true);
                break;
              case "transactionDate":
                setInvoiceDateMismatch(true);
                break;
              case "subTotal":
                setPriceMismatch(true);
                break;
              case "accountId":
                setNoCategory(true);
                break;
              case "total":
                setTotalMismatch(true);
                break;
              case "productId":
                setNoProduct(true);
                break;
              default:
                break;
            }
          });
        } else {
          setNoVendorIssue(false);
          setPriceMismatch(false);
          setTotalMismatch(false);
          setInvoiceDateMismatch(false);
          setOnlyTotalMismatch(false);
        }
      } else {
        let results = IssueResponse?.data?.data?.records.filter((e) => e.moduleType === "DOCUMENT");
        results?.map((prop, key) => {
          prop?.issueType === "NOVENDOR" ? setNoVendorIssue(true) : "";
          prop?.issueType === "NOSUBTOTAL" ? setPriceMismatch(true) : "";
          prop?.issueType === "TOTALMISMATCH" ? setTotalMismatch(true) : "";
          prop?.issueType === "PRICEMISMATCH" ? setPriceMismatch(true) : "";
          prop?.issueType === "NOTOTAL" ? setOnlyTotalMismatch(true) : "";
          prop?.issueType === "NOINVOICE_DATE" ? setInvoiceDateMismatch(true) : "";
          prop?.issueType == "NOPRODUCT" ? setNoProduct(true) : "";
          prop?.issueType === "NOCATEGORY" ? setNoCategory(true) : "";
        });
        // }
      }
    }, [IssueResponse, editable, itemList]);

    function checkNextOCRCompleted(index, list) {
      // Check if index is within the range of the list
      if (index < 0 || index >= list.length - 1) {
        onClose1();
        return false;
      }
    
      // Loop through the subsequent items starting from the given index
      for (let i = index + 1; i < list.length; i++) {
        // Check if the OCR status of the current item is "COMPLETED"
        if (list[i].ocrStatus === "COMPLETED") {
          return {
            id: list[i]._id,         // Return the _id
            clientId: list[i].client.clientId // Return the clientId
          };
        }
      }
    
      return false; // None of the subsequent items have OCR status "COMPLETED"
    }

    useEffect(async () => {
      await IssueListAPICall({
        payload: [{ businessId: `${businessId}`, documentId: `${invoice_id}` }],
      });
      await getClientDetailsAPICall({
        payload: [{ bussinessId: `${businessId}` }],
      });
    }, []);
  
    useEffect(() => {
      if (DetailsResponse && GetItemListResponse) {
        const data = GetItemListResponse?.data?.data?.records.map((prop, key) => {
          return {
            item: prop?.description ? prop?.description : "---",
            qty: prop?.quantity ? prop?.quantity : "---",
            unit: prop?.unitOfMeasure ? prop?.unitOfMeasure : "---",
            product: prop?.product?.name ? prop?.product?.name : "---",
            productCheck: prop?.product?.name ? prop?.product?.name : "---",
            price: prop?.unitPrice ? prop?.unitPrice : "0",
            total: prop?.amount ? prop?.amount : "---",
            customer: prop?.customer?.name ? prop?.customer?.name : "---",
            category: prop?.category?.name ? prop?.category?.name : "---",
            class: prop?.class?.name ? prop?.class?.name : "---",
            id: prop._id,
            sku: prop.sku,
            itemType: prop.itemType,
            CheckAskClient: prop?.askClient,
          };
        });
        setLoadingCircul(false);
        setLoading(false);
        if (DetailsResponse?.data?.data?.document?.itemType === "ItemBasedExpenseLineDetail") {
          const foundProducts = data?.find((obj) => {
            return obj.product == "---";
          });
        } else if (
          DetailsResponse?.data?.data?.document?.itemType === "AccountBasedExpenseLineDetail"
        ) {
          const foundCategory = data?.find((obj) => {
            return obj.category == "---";
          });

        }
      }
    }, [GetItemListResponse, DetailsResponse]);

    useEffect(() => {
      if (isInvoiceDetailsLoading) {
        return () => null;
      }
      if (isInvoiceDetailsError) {
        setLoading(false);
        setLoadingCircul(false);
        return () => null;
      }
      if (InvoiceDetailsResponse && InvoiceDetailsResponse.data.isSuccess === true) {
        const found2 = InvoiceDetailsResponse?.data?.data?.records.find((obj) => {
          return obj._id === businessId;
        });
        setOwnerAssigned(found2?.ownerAssigned);
        setOpenAddOwner(false);
        setLoading(false);
        setLoadingCircul(false);
      }
    }, [InvoiceDetailsResponse]);

    useEffect(() => {
      if (isMoveMenuLoading) {
        return () => null;
      }
      if (isMoveMenuError) {
        setDisableMoveButton(false);
        setLoading(false);
        setalertMoveInvoice(false);
        return () => null;
      }
      if (MoveMenuResponse && MoveMenuResponse.data.isSuccess === true) {
        setLoading(true);
        setSelectedRows([]);
        setalertMoveInvoice(false);
        setDisableMoveButton(false);
        const index = storeResponce?.data?.data?.records.findIndex((x) => x._id === invoiceId);
        const CheckNextComplete = checkNextOCRCompleted(index, storeResponce?.data?.data?.records);
        if (CheckNextComplete) {
          storeResponce?.data?.data?.totalRecords - 1 === 0 ? onClose() : onClose1();
          setInvoice_Id(CheckNextComplete.id);
          setInvoiceId(CheckNextComplete.id);
          openQboSyncAskClient ? onClose() : onClose1();
          setLoading(true);
          GetItemListListAPICall({
            payload: [{ documentId: `${CheckNextComplete.id}` }],
          });
          DetailsUserAPICall({
            payload: [{ documentId: `${CheckNextComplete.id}`, businessId: `${CheckNextComplete.clientId}` }],
          });
          VendorListAPICall({
            payload: [{ businessId: `${CheckNextComplete.clientId}`, IsSynced: true }],
          });
          InvoiceDetailsUserAPICall({
            payload: [{ businessId: `${CheckNextComplete.clientId}` }],
          });
        } else {
          onClose();
          onClose1();
          storeResponce?.data?.data?.totalRecords - 1 === 0 ? onClose() : onClose1();
          openQboSyncAskClient ? onClose() : onClose1();
        }
      }
    }, [MoveMenuResponse]);

    useEffect(() => {
      if (selectedRows.length !== 0) {
        setCheckFound(true);
      } else {
        setCheckFound(false);
      }
    }, [selectedRows]);

    const successInoiceMove = async () => {
      setOpenQboSyncAskClient(false);
      setLoading(true);
      setDisableMoveButton(true);
      setAnchorEl(null);
      EditItemAPICall({
        payload: [
          {
            documentId: invoiceId,
            itemIds: selectedRows,
            updateObj: {
              askClient: true,
            },
          },
        ],
      });
      MoveMenuListAPICall({
        payload: [
          {
            documentIds: {
              documentIds: [invoiceId],
              documentStatus: openQboSyncAskClient ? moveTowhere : tabsMove,
            },
            businessId: `${businessId}`,
          },
        ],
      });
    };

    const successAssignNewClient = (move) => {
      setOpenAddOwner(true);
      setAssignNewClient(false);
    };


    useEffect(async () => {
      if (isEditeItemLoading) {
        return () => null;
      }
      if (isEditeItemError) {
        setLoading(false);
        setChenging(true);
        GetItemListListAPICall({
          payload: [{ documentId: `${invoiceId}` }],
        });
        setSelectedRows((prevSelected) => prevSelected.filter((rowId) => rowId !== matchId));
        if (EditeItemResponse?.data?.message) {
          setErrorMessage(EditeItemResponse.data.message);
        }
        setShowErrorMoveSnackbar(true);
        setTimeout(() => {
          setShowErrorMoveSnackbar(false);
        }, 4000);
        return () => null;
      }
      if (EditeItemResponse && EditeItemResponse.data.isSuccess === true) {
        eventBus.$emit("refreshListAfterUpdate", response);
        IssueListAPICall({
          payload: [{ businessId: `${businessId}`, documentId: `${invoice_id}` }],
        });
        setTimeout(() => {
          GetItemListListAPICall({
            payload: [{ documentId: `${invoice_id}` }],
          });
          DetailsUserAPICall({
            payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
          });
        }, 700);
      }
    }, [EditeItemResponse]);

    useEffect(() => {
      setLoading(true);
      GetItemListListAPICall({
        payload: [{ documentId: `${invoice_id}` }],
      });
      DetailsUserAPICall({
        payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
      });
      VendorListAPICall({
        payload: [{ businessId: `${businessId}`, IsSynced: true }],
      });
      InvoiceDetailsUserAPICall({
        payload: [{ businessId: `${businessId}` }],
      });
      // GetsummoryListListAPICall({
      //   payload: [{ InvoiceId: `${invoice_id}` , businessId: `${businessId}`}],
      // });
    }, []);

    useEffect(() => {
      if (isCreateComentsLoading) {
        return () => null;
      }
      if (isCreateComentsError) {
        setLoading(false);
        setBtnDisable1(false);
        return () => null;
      }
      if (CreateComentsResponse && CreateComentsResponse.data.isSuccess === true) {
        // setLoading(false);
        setsubmitcomment(false);
        setBtnDisable1(false);
        setTextAreaVal("");
        CommentsListAPICall({
          payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
        });
      }
    }, [CreateComentsResponse]);

    useEffect(() => {
      if (isDeleteInvoiceLoading) {
        return () => null;
      }
      if (isDeleteInvoiceError) {
        setLoading(false);
        return () => null;
      }
      if (DeleteInvoiceResponse && DeleteInvoiceResponse.data.isSuccess === true) {
        eventBus.$emit("closeDeleteItemPopup", DeleteInvoiceResponse);
        setLoading(true);
      }
    }, [DeleteInvoiceResponse]);

    useEffect(() => {
      if (isGetItemListLoading) {
        return () => null;
      }
      if (isGetItemListError) {
        setLoading(false);
        return () => null;
      }
      if (GetItemListResponse && GetItemListResponse.data.isSuccess === true) {
        // setChenging(true);
        setLoading(false);
        SetItemListResponse(GetItemListResponse);
        setTotalItemLength(GetItemListResponse?.data?.data?.totalRecords);
        if (pushPopFun) {
          const foundId = GetItemListResponse?.data?.data?.records.find((obj) => {
            return obj._id === matchId;
          });
          if (foundId?.askClient === true) {
            setShowTrueSnackbar(true);
            setTimeout(() => {
              setShowTrueSnackbar(false);
            }, 5000);
          } else if (foundId?.askClient === false) {
            setShowFalseSnackbar(true);
            setTimeout(() => {
              setShowFalseSnackbar(false);
            }, 5000);
          }
        }
        setTimeout(() => {
          setPushPopFun(false);
        }, 1000);

        if (onIssueClick1 === true && moduleTypeEdit === "ITEM") {
          setValue(1);
          setOpenEditItem(true);
          setEditItemId(onIssueClickData?.moduleId);
        } else if (onIssueClick1 === true && moduleTypeEdit === "DOCUMENT") {
          setValue(0);
        }
        const data = GetItemListResponse?.data?.data?.records.map((prop, key) => {
          const Error = IssueResponse?.data?.data?.records.find((obj) => {
            return obj.moduleId === prop._id;
          });
          return {
            invoice: prop?.docNumber ? prop?.docNumber : "---",
            balance: prop?.amount ? prop?.amount : "---",
            invoiceDate: prop?.invoiceDate ? prop?.invoiceDate : "---",
            noOfIssues: prop?.noOfIssues ? prop?.noOfIssues : "---",
            item: prop?.description ? prop?.description : "---",
            qty: prop?.quantity ? isString(prop?.quantity) ? Number(prop?.quantity) : prop?.quantity : "---",
            unit: prop?.unitOfMeasure ? prop?.unitOfMeasure : "---",
            product: prop?.product?.name ? prop?.product?.name : "---",
            price: prop?.unitPrice ? prop?.unitPrice : "0",
            customer: prop?.customer?.name ? prop?.customer?.name : "---",
            category: prop?.category?.name ? prop?.category?.name : "---",
            class: prop?.class?.name ? prop?.class?.name : "---",
            proderror: Error ? true : false,
            proderrorMsg: Error ? Error?.message : "---",
            id: prop._id,
            sku: prop.sku,
            itemType: prop.itemType,            
            CheckAskClient: prop?.askClient,
          };
        });
        const Categoryresult = data.filter(
          (data) => data.itemType === "AccountBasedExpenseLineDetail"
        );
        if (firstTime === true) {
          if (Categoryresult.length === 0) {
            // setToggleShow(true),
            setItemList(data), setLoading(false), setFirstTime(false);
          } else {
            // setToggleShow(false),
            setItemList(data); setLoading(false), setFirstTime(false);
          }
        } else {
          setLoading(false), toggleShow === true ? setItemList(data) : setItemList(data);
        }
        setLoading(false);
        setTimeout(() => {
          setChenging(true);
          setLoading(false);
        }, 2000);
        
        setLoadingCircul(false);
      }
    }, [GetItemListResponse]);

    useEffect(() => {
      if (DetailsResponse && GetItemListResponse) {
        const data = GetItemListResponse?.data?.data?.records.map((prop, key) => {
          return {
            item: prop?.description ? prop?.description : "---",
            qty: prop?.quantity ? prop?.quantity : "---",
            unit: prop?.unitOfMeasure ? prop?.unitOfMeasure : "---",
            product: prop?.product?.name ? prop?.product?.name : "---",
            productCheck: prop?.product?.name ? prop?.product?.name : "---",
            price: prop?.unitPrice ? prop?.unitPrice : "0",
            total: prop?.amount ? prop?.amount : "---",
            customer: prop?.customer?.name ? prop?.customer?.name : "---",
            category: prop?.category?.name ? prop?.category?.name : "---",
            class: prop?.class?.name ? prop?.class?.name : "---",
            id: prop._id,
            sku: prop.sku,
            itemType: prop.itemType,
            CheckAskClient: prop?.askClient,
          };
        });
        setLoadingCircul(false);
        setLoading(false);
        if (DetailsResponse?.data?.data?.document?.itemType === "ItemBasedExpenseLineDetail") {
          const foundProducts = data?.find((obj) => {
            return obj.product == "---";
          });
          if (foundProducts === undefined) {
            setShowButton(true);
          } else {
            setShowButton(false);
          }
        } else if (
          DetailsResponse?.data?.data?.document?.itemType === "AccountBasedExpenseLineDetail"
        ) {
          const foundCategory = data?.find((obj) => {
            return obj.category == "---";
          });
          if (foundCategory === undefined) {
            setShowButton(true);
          } else {
            setShowButton(false);
          }
        }
      }
    }, [GetItemListResponse, DetailsResponse]);

    useEffect(() => {
      if (isDeleteItemLoading) {
        return () => null;
      }
      if (isDeleteItemError) {
        setErrorMessage(DeleteItemResponse?.data?.message);
        setLoading(false);
        setIsDisable(false);
        setShowErrorSnackbar(true);
        setTimeout(() => {
          setShowErrorSnackbar(false);
        }, 3000);
        setLoading(false);
        return () => null;
      }
      if (DeleteItemResponse && DeleteItemResponse.data.isSuccess === true) {
        GetItemListListAPICall({
          payload: [{ documentId: `${invoice_id}` }],
        });
        DetailsUserAPICall({
          payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
        });
      }
    }, [DeleteItemResponse]);

    useEffect(() => {
      if (isCommentsLoading) {
        return () => null;
      }
      if (isCommentsError) {
        setLoading(false);
        setLoadingCircul(false);
        return () => null;
      }
      if (CommentsResponse && CommentsResponse.data.isSuccess === true) {
        const LogCommentdata = CommentsResponse?.data?.data?.records.map((prop, key) => {
          const commentBy =
            prop.commentBy.fname.charAt(0).toUpperCase() + prop?.commentBy?.fname
              ? prop?.commentBy?.fname.slice(1)
              : "" + " " + prop.commentBy.lname;
          const comments = prop.comment;
          var found = [],
            rxp = /{{([^}]+)}}/g,
            str = comments,
            curMatch;

          while ((curMatch = rxp.exec(str))) {
            found.push(curMatch[1]);
          }
          function findReplaceString(string, found) {
            let results = string.match(/{{([^}]+)}}/g);
            if (results) {
              let results = string.match(/{{([^}]+)}}/g);
              const final = results?.map((prop, key) => {
                const Name = users.find((obj) => {
                  return obj.id == found[key];
                });
                string = string.replace(
                  new RegExp("{{(?:\\s+)?(" + found[key] + ")(?:\\s+)?}}"),
                  `@${Name?.display}`
                );
                return string;
              });
              return final.slice(-1);
            }
          }
          const datacom = findReplaceString(comments, found);
          return (
            <Paper style={{background: darkMode ? '#394366' : '#ffffff', padding: "30px 20px" ,borderBottom : darkMode ? '2px solid #ffffff' : '' }}>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <Avatar alt="Remy Sharp" sx={{ width: 33, height: 33 }} />
                </Grid>
                <Grid justifyContent="left" item xs zeroMinWidth>
                  <div style={{ display: "flex" }}>
                    <h4
                      style={{
                        margin: 0,
                        textAlign: "left",
                        fontFamily: "Arial",
                        fontSize: "0.775rem",
                        fontWeight: "bold",
                        lineHeight: 1.5,
                        textTransform: "uppercase",
                        letterSpacing: "0.02857em",
                        opacity: 1,
                        textTransform: "none",
                        verticalAlign: "unset",
                        textAlign: "left",
                        marginBottom: "5px",
                        color:darkMode ? '#ffffff' : '',
                      }}
                    >
                      {commentBy}
                    </h4>
                    <MDTypography sx={{color:darkMode ? '#ffffff' : '', mt: 0.5, ml: 1 }} variant="caption" color="text">
                      {inLocalTime(prop.createdAt)}
                    </MDTypography>
                  </div>
                  <p
                    style={{
                      color:darkMode ? '#ffffff' : '',
                      margin: 0,
                      fontFamily: "Arial",
                      fontSize: "0.775rem",
                      fontWeight: 300,
                      lineHeight: 1.5,
                      textTransform: "uppercase",
                      letterSpacing: "0.02857em",
                      opacity: 1,
                      textTransform: "none",
                      verticalAlign: "unset",
                      textAlign: "left",
                    }}
                  >
                    {datacom ? datacom[0] : comments}
                  </p>
                </Grid>
              </Grid>
            </Paper>
          );
        });
        setCommentsList(LogCommentdata);
        setLoading(false);
        setLoadingCircul(false);
      }
    }, [CommentsResponse]);

    useEffect(() => {
      if (isAuditLogLoading) {
        return () => null;
      }
      if (isAuditLogError) {
        setLoadingCircul(false);
        setLoading(false);
        return () => null;
      }
      if (AuditLogResponse && AuditLogResponse.data.isSuccess === true) {
        const Logdata = AuditLogResponse.data.data.records.map((prop, key) => {
          return (
            <TimelineItem
              dateTime={prop.createdAt}
              description={prop.message}
              badges={["design"]}
              type={prop.eventType}
              button={prop.moduleId}
            />
          );
        });
        setLoadingCircul(false);
        setAuditLogList(Logdata);
        setLoading(false);
      }
    }, [AuditLogResponse]);

    useEffect(() => {
      if (isLoading) {
        return () => null;
      }
      if (isError) {
          if (response?.code == "ERR100") {
            setpassDuplicateMsg(response?.data?.message);
            setShowPassDuplicate(true);
        }else{
          setShowPassDuplicate(false);
        }
        setErrorMessage(response?.data?.message);
        setBtnDisable(false);
        setLoadingCircul(false);
        return () => null;
      }
      if (response && response.data.isSuccess === true) {
        eventBus.$emit("refreshListAfterUpdate", response);
        DetailsUserAPICall({
          payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
        });
        setBtnDisable(false);
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 10000);

        SetEditable(false);
        setLoading(true);
        setLoadingCircul(false);
        setShowPassDuplicate(false);
      }
    }, [response]);

    useEffect(async () => {
      setFirstTime(onIssueClick1);
      await DetailsUserAPICall({
        payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
      });
      await VendorListAPICall({
        payload: [{ businessId: `${businessId}`, IsSynced: true }],
      });
    }, []);

    useEffect(() => {
      if (loading == true) {
        setTimeout(() => {
          setLoading(false);
        }, 10000);
      }
    }, [loading]);

    useEffect(() => {
      if (isVendorLoading) {
        return () => null;
      }
      if (isVendorError) {
        setLoading(false);
        return () => null;
      }
      if (VendorResponse && VendorResponse.data.isSuccess === true) {
        setLoading(false);
        setVendorList(VendorResponse?.data?.data?.records);
        const Name = VendorResponse?.data?.data?.records.find((obj) => {
          return obj._id == addNewVendorId;
        });
        Name?.name ? setVendorname2(Name?.name) : "";
      }
    }, [VendorResponse]);

    eventBus.$on("AddnewVendorError", (data) => AddnewVendorError(data));
    function AddnewVendorError(data) {
      setAddVendoreerror(true);
      setErrorMessagesVendorError("Error");
    }

    eventBus.$on("AddnewVendorComplete", (data) => AddnewVendorComplete(data));
    function AddnewVendorComplete(data) {
      setAddNewVendorId(data?.vendor?._id);
      setOpenEditVendor(false);
      setLoading(true);
      VendorListAPICall({
        payload: [{ businessId: `${businessId}`, IsSynced: true }],
      });
    }

    eventBus.$on("errorInAddOwner", (data) => errorInAddOwner(data));
    function errorInAddOwner(data) {
      setErrorMessage(data);
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 4000);
    }

    eventBus.$on("successInAddOwner", (data) => successInAddOwner(data));
    function successInAddOwner(data) {
      InvoiceDetailsUserAPICall({
        payload: [{ documentId: `${businessId}` }],
      });
      setOpenAddOwner(false);
      setTimeout(() => {
        setTabsMove("Ask Client");
        setLoading(false);
        setLoadingCircul(false);
        setalertMoveInvoice(true);
      }, 500);
    }

    eventBus.$on("closeCategoryEdit", (data) => closeCategoryEdit(data));
    function closeCategoryEdit(data) {
      setOpenAddItem(false);
    }

    eventBus.$on("handleChangeRedio", (data) => handleChangeRedioEvent(data));
    function handleChangeRedioEvent(data) {
      data === "ItemBasedExpenseLineDetail" ? handleAddItem() : handleCategort();
    }

    eventBus.$on("handleChangeEditRedio", (data) => handleChangeEditRedioEvent(data));
    function handleChangeEditRedioEvent(data) {
      setToggleShow(!toggleShow);
      data === "ItemBasedExpenseLineDetail" ? setOpenEditItem(true) : setOpenEditItem(true);
    }

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
      if (openAddItem) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
    }, [openAddItem]);

    useEffect(() => {
      if (isDetailsLoading) {
        return () => null;
      }

      if (isDetailsError) {
        setLoadingCircul(false);
        return () => null;
      }

      if (DetailsResponse && DetailsResponse.data.isSuccess === true) {
        eventBus.$emit("refreshListAfterUpdate", response);
        setCreateObjectURL(DetailsResponse?.data?.data?.document?.uploadedFileUrl ? DetailsResponse?.data?.data?.document?.uploadedFileUrl: "");
        if(DetailsResponse?.data?.data?.document?.documentStatus == "ToReview" && showDetails == false){
          setValue(0);
          setShowDetails(true);
        }else if(showDetails == false) {
          setValue(1);
          setShowDetails(true);
        }
        DetailsResponse?.data?.data?.document?.documentSource === "QBO" ? setEditbleData(false) : setEditbleData(true);
        setOcrstatus( DetailsResponse?.data?.data?.document?.ocrStatus === "INPROGRESS" ? true : false);
        setOcrstatus1(DetailsResponse?.data?.data?.document?.ocrStatus);
        setActualSubtotal(DetailsResponse?.data?.data?.document?.actualSubtotal);
        setActualTotal(DetailsResponse?.data?.data?.document?.total);
        setVendorSelect(DetailsResponse?.data?.data?.document?.vendor ? DetailsResponse?.data?.data?.document?.vendor.name : "");
        setVendorname( DetailsResponse?.data?.data?.document?.vendor ? DetailsResponse?.data?.data?.document?.vendor.name : "");
        setVendorname1(DetailsResponse?.data?.data?.document?.vendor ? DetailsResponse?.data?.data?.document?.vendor.name : "");
        setInvoiceNumber(DetailsResponse?.data?.data?.document?.invoice_number ? DetailsResponse?.data?.data?.document?.invoice_number : DetailsResponse?.data?.data?.document?.docNumber);
        setPoNumber(DetailsResponse?.data?.data?.document?.order_no ? DetailsResponse?.data?.data?.document?.order_no : "");
        setTransactionDate(DetailsResponse?.data?.data?.document?.transactionDate ? Moment(DetailsResponse?.data?.data?.document?.transactionDate).format("YYYY-MM-DD"): null);
        setTransactionDateObj(DetailsResponse?.data?.data?.document?.transactionDate ? Moment(DetailsResponse?.data?.data?.document?.transactionDate).format("YYYY-MM-DD"): null);
        setUploadDate(DetailsResponse?.data?.data?.document?.createdAt ? Moment(DetailsResponse?.data?.data?.document?.createdAt).format("YYYY-MM-DD"): null);
        setTotal(DetailsResponse?.data?.data?.document?.total);
        setTotalStore(DetailsResponse?.data?.data?.document?.total);
        setTotalForMatch(DetailsResponse?.data?.data?.document?.total);
        setTransactionDateForMatch(DetailsResponse?.data?.data?.document?.transactionDate ? Moment(DetailsResponse?.data?.data?.document?.transactionDate).format("YYYY-MM-DD") : null);
        setOrderTotalStore(DetailsResponse?.data?.data?.document?.orderTotal);
        setTax(DetailsResponse?.data?.data?.document?.tax);
        setDiscount(DetailsResponse?.data?.data?.document?.discount);
        setDiscountStore(DetailsResponse?.data?.data?.document?.discount);
        setDiscountStatic(DetailsResponse?.data?.data?.document?.discount);
        setShippingpriceStore(DetailsResponse?.data?.data?.document?.shippingPrice);
        setShippingprice(DetailsResponse?.data?.data?.document?.shippingPrice);
        setShippingpriceStatic(DetailsResponse?.data?.data?.document?.shippingPrice);
        setTaxStore(DetailsResponse?.data?.data?.document?.tax);
        setPoNumber(DetailsResponse?.data?.data?.document?.purchaseOrderNo);
        setIssues(DetailsResponse?.data?.data?.document?.issues);
        setSubTotalForMatch(DetailsResponse?.data?.data?.document?.sub_total ? DetailsResponse?.data?.data?.document?.sub_total : DetailsResponse?.data?.data?.document?.subTotal);
        setSubTotal( DetailsResponse?.data?.data?.document?.sub_total ? DetailsResponse?.data?.data?.document?.sub_total : DetailsResponse?.data?.data?.document?.subTotal);
        setSubTotStore(DetailsResponse?.data?.data?.document?.sub_total ? DetailsResponse?.data?.data?.document?.sub_total : DetailsResponse?.data?.data?.document?.subTotal);
        setCurrencySymbol(DetailsResponse?.data?.data?.document?.currencySymbol);
        setDocumnetSource(DetailsResponse?.data?.data?.document?.documentSource ? DetailsResponse?.data?.data?.document?.documentSource : "");
        setIsSynced(DetailsResponse?.data?.data?.document?.isSynced);
        setDocumentStatus(DetailsResponse?.data?.data?.document?.documentStatus ? DetailsResponse?.data?.data?.document?.documentStatus : "");
        setDocumentType(DetailsResponse?.data?.data?.document?.documentType ? DetailsResponse?.data?.data?.document?.documentType : "" );
        setImageUrl(DetailsResponse?.data?.data?.document?.uploadedFileUrl ? DetailsResponse?.data?.data?.document?.uploadedFileUrl : "");
        
        setLoading(false);
        DetailsResponse?.data?.data?.document?.itemType === "ItemBasedExpenseLineDetail"
          ? setToggleShow(true)
          : setToggleShow(false);
        setLoadingCircul(false);
        setShowDetailsForm(true);
        setResetData(false);
        setShowLoaderFirInvoice(false);
        setInitialValues({
          vendorname: DetailsResponse?.data?.data?.document?.vendor
            ? DetailsResponse?.data?.data?.document?.vendor.name
            : "",
          documenttype: DetailsResponse?.data?.data?.document?.documentType
            ? DetailsResponse?.data?.data?.document?.documentType
            : "",
          invoicenumber: DetailsResponse?.data?.data?.document?.docNumber,
          ponumber: DetailsResponse?.data?.data?.document?.purchaseOrderNo,
          invoicedate: DetailsResponse?.data?.data?.document?.transactionDate
            ? Moment(DetailsResponse?.data?.data?.document?.transactionDate).format("YYYY-MM-DD")
            : null,
          uploaddate: DetailsResponse?.data?.data?.document?.createdAt
            ? Moment(DetailsResponse?.data?.data?.document?.createdAt).format("YYYY-MM-DD")
            : null,
          total: `${total}`,
          tax: `${tax}`,
          transactionDate: `${transactionDate}`,
          subtotal: `${subtotal}`,
        });
        IssueListAPICall({
          payload: [{ businessId: `${businessId}`, documentId: `${invoice_id}` }],
        });
      }
    }, [DetailsResponse]);

    useEffect(() => {
      setInitialValues({
        vendorname: "test",
        documenttype: documentType,
        invoicenumber: invoicenumber,
        ponumber: ponumber,
        invoicedate: transactionDate,
        uploaddate: uploaddate,
        total: `${total}`,
        tax: `${tax}`,
        transactionDate: `${transactionDate}`,
        subtotal: `${subtotal}`,
      });
    }, [resetData]);

    const handleClosebtn = async () => {
      setVendorname(vendorname1);
      setResetData(true);
      SetEditable(false);
      setOpenEditVendor(false);
      await DetailsUserAPICall({
        payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
      });
    };

    const handleCloseVendorbtn = async () => {
      setOpenEditVendor(false);
      await DetailsUserAPICall({
        payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
      });
    };

    const finalStoreObj = typeof subTotStore == "string" ? subTotStore.replaceAll(",", "") : subTotStore;
    const finalTaxObj = typeof taxStore == "string" ? taxStore.replaceAll(",", "") : taxStore;
    const finalShippingPriceObj = typeof shippingpriceStore == "string" ? shippingpriceStore.replaceAll(",", "") : shippingpriceStore;
    const finaldiscountObj = typeof discountStore == "string" ? discountStore.replaceAll(",", "") : discountStore;
    const finalTotalObj = typeof TotalStore == "string" ? TotalStore.replaceAll(",", "") : TotalStore;

    const handleChange = async (event, newValue) => {
      setFirstTime(true);
      setOnIssueClick1(false);
      setValue(newValue);
      if (newValue === 1) {
        setLoading(true);
        // setLoadingCircul(true);
        await GetItemListListAPICall({
          payload: [{ documentId: `${invoice_id}` }],
        });
      } else if (newValue === 3){
        setLoading(true);
        await AuditLogListAPICall({
          payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
        });
      }else if (newValue === 2) {
        setLoading(true);
        await CommentsListAPICall({
          payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
        });
      }
      if(isFormDirty){
        setAPICall({
        payload: [
          {
            transactionDate: `${transactionDateObj}`,
            createdAt: `${uploaddate}`,
            documentType: `${documentTypeObj}`,
            docNumber: `${invoiceNumberObj}`,
            vendorId: `${addNewVendorId ? addNewVendorId : showselectedVendorId}`,
            subTotal: Number(finalStoreObj),
            tax: Number(finalTaxObj),
            shippingPrice: Number(finalShippingPriceObj),
            discount: Number(finaldiscountObj),
            total: Number(finalTotalObj),
            purchaseOrderNo: `${ponumberObj}`,
            businessId: `${businessId}`,
            documentId: `${invoice_id}`,
            passDuplicate:passDuplicate,
          },
        ],
      });
    }
    };
    const handleAddNewVendor = async (values) => {
      setLoading(true);
      DetailsResponse?.data?.data?.document?.vendor?.billingAddress?.line1
        ? await AddVendorListAPICall({
            payload: [
              {
                name: vendorname,
                billingAddress: {
                  line1: DetailsResponse?.data?.data?.document?.vendor?.billingAddress?.line1,
                  city: DetailsResponse?.data?.data?.document?.vendor?.billingAddress?.city,
                  state: DetailsResponse?.data?.data?.document?.vendor?.billingAddress?.state,
                  zip: DetailsResponse?.data?.data?.document?.vendor?.billingAddress?.zip,
                },
                clientId: `${businessId}`,
              },
            ],
          })
        : await AddVendorListAPICall({
            payload: [
              {
                name: vendorname,
                clientId: `${businessId}`,
              },
            ],
          });
    };
    const handleSubmit = async (event, values) => {
      setBtnDisable(true);
      setLoadingCircul(true);
      const finalStore = typeof subTotStore == "string" ? subTotStore.replaceAll(",", "") : subTotStore;
      const finalTax = typeof taxStore == "string" ? taxStore.replaceAll(",", "") : taxStore;
      const finalShippingPrice = typeof shippingpriceStore == "string" ? shippingpriceStore.replaceAll(",", "") : shippingpriceStore;
      const finaldiscount = typeof discountStore == "string" ? discountStore.replaceAll(",", "") : discountStore;
      const finalTotal = typeof TotalStore == "string" ? TotalStore.replaceAll(",", "") : TotalStore;
      await setAPICall({
        payload: [
          {
            transactionDate: `${transactionDateObj}`,
            createdAt: `${event.uploaddate}`,
            documentType: `${event.documenttype}`,
            docNumber: `${event.invoicenumber}`,
            vendorId: `${addNewVendorId ? addNewVendorId : showselectedVendorId}`,
            subTotal: Number(finalStore),
            tax: Number(finalTax),
            shippingPrice: Number(finalShippingPrice),
            discount: Number(finaldiscount),
            total: Number(finalTotal),
            purchaseOrderNo: `${event.ponumber}`,
            businessId: `${businessId}`,
            documentId: `${invoice_id}`,
            passDuplicate:passDuplicate,
          },
        ],
      });
      eventBus.$emit("refreshApiInvoiceList", event);
    };
    const handleAddItem = () => {
      setOpenAddItem(true);
      setOpenCategoryAddItem(false);
    };
    const handleCategort = () => {
      setOpenCategoryAddItem(true);
      setOpenAddItem(false);
    };
    const handleEditItem = () => {
      setOpenEditItem(true);
    };
    const warningWithConfirmMessage = () => {
      setAlert(true);
    };
    const hideAlert = () => {
      setAlert(false);
    };
    const successDelete = () => {
      DeleteItemListAPICall({
        payload: [
          {
            adjustInvoice: false,
            documentId: `${invoice_id}`,
            ItemId: `${itemId}`,
          },
        ],
      });
      setAlert(false);
      setLoading(true);
    };
    const hideInvoiceAlert = () => {
      setAlertDeleteInvoice(false);
    };
    const successInoiceDelete = () => {
      DeleteInvoiceListAPICall({
        payload: [
          {
            documentIds: {
              documentIds: [invoiceId],
            },
            businessId: `${businessId}`,
          },
        ],
      });
      setAlert(false);
      setLoading(true);
    };

    const currentPage = paginationModel.page;
    const rowsPerPage = paginationModel.pageSize;
    useEffect(() => {
      if (GetItemListResponse?.data?.isSuccess === true && itemList?.length !== 0) {
        const currentPageRows = itemList.slice(
          currentPage * rowsPerPage,
          (currentPage + 1) * rowsPerPage
        );
        if (currentPageRows.length > 0) {
          const currentPageRowIds = currentPageRows.map((item) => item.id);
          const allCurrentPageRowsSelected = currentPageRows.every((item) =>
            selectedRows.includes(item.id)
          );
          setCurrentPageRowSize(allCurrentPageRowsSelected);
        }
      }
    }, [selectedRows, itemList]);

    useEffect(() => {
      if (itemList.length !== 0) {
        const currentPageRows = itemList.slice(
          currentPage * rowsPerPage,
          (currentPage + 1) * rowsPerPage
        );
        const currentPageRowIds = currentPageRows.map((item) => item.id);
        const allCurrentPageRowsSelected = currentPageRows.every((item) =>
          selectedRows.includes(item.id)
        );
        setCurrentPageRowSize(allCurrentPageRowsSelected);
      }
    }, [paginationModel]);

    const handleRowCheckboxChange = (event, id) => {
      const isChecked = event.target.checked;

      // Calculate the index of the row in the itemList
      const rowIndex = currentPage * rowsPerPage + itemList.findIndex((item) => item.id === id);

      setSelectedRows((prevSelected) => {
        if (isChecked) {
          // Add the row ID if it's checked and belongs to the current page
          return [...prevSelected, id];
        } else {
          // Remove the row ID if it's unchecked
          return prevSelected.filter((rowId) => rowId !== id);
        }
      });
    };

    const callApiAfterPassDuplicate = () => {
      setPassDuplicate(true);
      setAPICall({
        payload: [
          {
            transactionDate: `${transactionDateObj}`,
            createdAt: `${uploaddate}`,
            documentType: `${documentTypeObj}`,
            docNumber: `${invoiceNumberObj}`,
            vendorId: `${addNewVendorId ? addNewVendorId : showselectedVendorId}`,
            subTotal: Number(finalStoreObj),
            tax: Number(finalTaxObj),
            shippingPrice: Number(finalShippingPriceObj),
            discount: Number(finaldiscountObj),
            total: Number(finalTotalObj),
            purchaseOrderNo: `${ponumberObj}`,
            passDuplicate:true,
            businessId: `${businessId}`,
            documentId: `${invoice_id}`,
          },
        ],
      });
    };

    const closeMoveDialog = async () => {
      setOpenQboSyncAskClient(false);
    };

    const rows = [
      { id: 1, invoice: 'Advertising', invoiceDate: '21-09-2023', status: 'Complete', balance: '$292.62' },
      { id: 2, invoice: 'Advertising', invoiceDate: '17-03-2024', status: 'Balance Differs from Invoice Total', balance: '$309.71' },
      { id: 3, invoice: 'Advertising', invoiceDate: '08-04-2024', status: 'Not In Paynthr', balance: '$138.20' },
      { id: 4, invoice: 'Advertising', invoiceDate: '04-06-2024', status: 'Complete', balance: '$361.34' },
    ];

    const reconciledColumns = [
      {
        field: "invoice",
        headerName: "INVOICE",
        minWidth: 120,
        headerAlign: "center",
        disableColumnMenu: true,
        sortable: false,
        flex: 0.5,
        renderCell: (params) => (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                },
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 15)}
            </MDTypography>
          </Tooltip>
        ),
      },
      {
        field: "invoiceDate",
        headerName: "INVOICE DATE",
        minWidth: 120,
        headerAlign: "center",
        disableColumnMenu: true,
        sortable: false,
        flex: 0.5,
        renderCell: (params) => (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                },
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 15)}
            </MDTypography>
          </Tooltip>
        ),
      },
      {
        headerName: "BALANCE",
        field: "balance",
        headerAlign: "center",
        minWidth: 100,
        sortable: false,
        disableColumnMenu: true,
        flex: 0.3,
        renderCell: (params) => (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  cursor: "pointer",
                  color: "#7b809a",
                },
                color: "#7b809a",
              }}
            >
              {" "}
              {params?.formattedValue}
            </MDTypography>
          </Tooltip>
        ),
      }
    ];

    const cetagorycolumnswithoutaskclient = [
      {
        field: "invoice",
        headerName: "INVOICE",
        minWidth: 120,
        headerAlign: "center",
        disableColumnMenu: true,
        sortable: false,
        flex: 0.5,
        renderCell: (params) => (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                },
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 15)}
            </MDTypography>
          </Tooltip>
        ),
      },
      {
        field: "invoiceDate",
        headerName: "INVOICE DATE",
        minWidth: 120,
        headerAlign: "center",
        disableColumnMenu: true,
        sortable: false,
        flex: 0.5,
        renderCell: (params) => (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                },
                color: "#7b809a",
              }}
            >
              {textEllipsis(params?.formattedValue, 25)}
            </MDTypography>
          </Tooltip>
        ),
      },
      {
        headerName: "BALANCE",
        field: "balance",
        headerAlign: "center",
        minWidth: 100,
        sortable: false,
        disableColumnMenu: true,
        flex: 0.3,
        renderCell: (params) => (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  cursor: "pointer",
                  color: "#7b809a",
                },
                color: "#7b809a",
              }}
            >
              {`$${textEllipsis(params?.formattedValue, 25)}`}
            </MDTypography>
          </Tooltip>
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "ACTIONS",
        maxWidth: 100,
        flex: 0.5,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            documentStatus === "Synced" || isSynced === true ? (
              <>
                <GridActionsCellItem
                  icon={<EditIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}/>}
                  label="Edit"
                  className="textPrimary"
                  onClick={(value) => {
                    setOpenQboSync(true);
                  }}
                  sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
                />
                <GridActionsCellItem
                  icon={<DeleteIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }} />}
                  label="Delete"
                  onClick={(value) => {
                    setOpenQboSync(true);
                  }}
                  sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
                />
              </>
            ) : (
              <>
                <GridActionsCellItem
                  icon={<EditIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}/>}
                  label="Edit"
                  className="textPrimary"
                  onClick={() => {
                    editbleData === true
                      ? setEditItemId(id) || handleEditItem()
                      : setOpenQboSync(true);
                  }}
                  sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
                />
                <GridActionsCellItem
                  icon={<DeleteIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }} />}
                  label="Delete"
                  onClick={(value) => {
                    totalItemLength === 1
                      ? setOneItemPopup(true)
                      : editbleData === true
                      ? setItemId(id) || warningWithConfirmMessage() 
                      : setOpenQboSync(true);
                  }}
                  sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
                />
              </>
            ),
          ];
        },
      },
    ];

    const cetagorycolumns = [
      {
        field: "invoice",
        headerName: "INVOICE",
        minWidth: 120,
        headerAlign: "center",
        disableColumnMenu: true,
        sortable: false,
        flex: 0.5,
        renderCell: (params) => (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                },
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 15)}
            </MDTypography>
          </Tooltip>
        ),
      },
      {
        field: "invoiceDate",
        headerName: "INVOICE DATE",
        minWidth: 120,
        headerAlign: "center",
        disableColumnMenu: true,
        sortable: false,
        flex: 0.5,
        renderCell: (params) => (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                },
                color: "#7b809a",
              }}
            >
              {textEllipsis(params?.formattedValue, 25)}
            </MDTypography>
          </Tooltip>
        ),
      },
      {
        headerName: "BALANCE",
        field: "balance",
        headerAlign: "center",
        minWidth: 100,
        sortable: false,
        disableColumnMenu: true,
        flex: 0.3,
        renderCell: (params) => (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  cursor: "pointer",
                  color: "#7b809a",
                },
                color: "#7b809a",
              }}
            >
              {`$${textEllipsis(params?.formattedValue, 25)}`}
            </MDTypography>
          </Tooltip>
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "ACTIONS",
        maxWidth: 100,
        flex: 0.5,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            documentStatus === "Synced" || isSynced === true ? (
              <>
                <GridActionsCellItem
                  icon={<EditIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}/>}
                  label="Edit"
                  className="textPrimary"
                  onClick={(value) => {
                    setOpenQboSync(true);
                  }}
                  sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
                />
                <GridActionsCellItem
                  icon={<DeleteIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }} />}
                  label="Delete"
                  onClick={(value) => {
                    setOpenQboSync(true);
                  }}
                  sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
                />
              </>
            ) : (
              <>
                <GridActionsCellItem
                  icon={<EditIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}/>}
                  label="Edit"
                  className="textPrimary"
                  onClick={() => {
                    editbleData === true
                      ? setEditItemId(id) || handleEditItem()
                      : setOpenQboSync(true);
                  }}
                  sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
                />
                <GridActionsCellItem
                  icon={<DeleteIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }} />}
                  label="Delete"
                  onClick={(value) => {
                    totalItemLength === 1
                      ? setOneItemPopup(true)
                      : editbleData === true
                      ? setItemId(id) || warningWithConfirmMessage() 
                      : setOpenQboSync(true);
                  }}
                  sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
                />
              </>
            ),
          ];
        },
      },
      {
        field: "askClients",
        type: "actions",
        headerName: "ASK CLIENT",
        maxWidth: 100,
        flex: 0.4,
        cellClassName: "actions",
        // renderHeader: () => (
        //   <input type="checkbox" onChange={handleHeaderCheckboxChange} checked={currentPageRowSize} />
        // ),
        renderCell: (params) => (
        params.row.noOfIssues !== 0 ? 
          <input
            type="checkbox"
            onChange={(event) => handleRowCheckboxChange(event, params.row.id)}
            checked={selectedRows.includes(params.row.id)}
          />:""
        ),
      },
    ];

    eventBus.$on("reloadDetailsTab", (data) => reloadDetailsTab(data));
    function reloadDetailsTab(data) {
      setShowDetailsForm(false);
      setOpenAddItem(false);
      setLoading(true);
      DetailsUserAPICall({
        payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
      });
    }

    eventBus.$on("closeAddNewItemPopup", (data) => fetchNewInvoicesAdd(data));
    function fetchNewInvoicesAdd(data) {
      setOpenAddItem(false);
      setLoading(true);
      setOpenCategoryAddItem(false);
      GetItemListListAPICall({
        payload: [{ documentId: `${invoice_id}` }],
      });
      DetailsUserAPICall({
        payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
      });
    }

    eventBus.$on("closeEditItemPopup", (data) => fetchNewInvoices(data));
    function fetchNewInvoices(data) {
      setOpenEditItem(false);
      setOnIssueClick1(false);
      setLoading(true);
      GetItemListListAPICall({
        payload: [{ documentId: `${invoice_id}` }],
      });
      DetailsUserAPICall({
        payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
      });
    }

    eventBus.$on("closeBulkEditItemPopup", (data) => closeBulkEditItemPopup(data));
    function closeBulkEditItemPopup(data) {
      setLoading(true);
      setSelectedRows([]);
      DetailsUserAPICall({
        payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
      });
      GetItemListListAPICall({
        payload: [{ documentId: `${invoice_id}` }],
      });
      IssueListAPICall({
        payload: [{ businessId: `${businessId}`, documentId: `${invoice_id}` }],
      });
    }

    useEffect(() => {
      let taxNow = typeof taxStore == "string" ? taxStore.replaceAll(",", "") : taxStore;
      let subTotalNow = typeof subTotStore == "string" ? subTotStore.replaceAll(",", "") : subTotStore;
      let discountNow = typeof discountStore == "string" ? discountStore.replaceAll(",", "") : discountStore;
      let shippingPriceNow = typeof shippingpriceStore == "string" ? shippingpriceStore.replaceAll(",", "") : shippingpriceStore;
      let orderTotalNow = typeof orderTotalStore == "string" ? orderTotalStore.replaceAll(",", "") : orderTotalStore;
      const finaldiscountNow = discountNow ? discountNow : 0;
      const MyTotal = Number.parseFloat(subTotalNow, 10) - Number.parseFloat(finaldiscountNow, 10);
      const finalshippingPriceNow = shippingPriceNow ? shippingPriceNow : 0;
      const finaltaxNow = taxNow ? taxNow : 0;

      if (onChanges === true) {
        const timeOutId = setTimeout(
          async () =>
          setTotalStore(MyTotal + Number.parseFloat(finaltaxNow, 10) +  Number.parseFloat(finalshippingPriceNow, 10) ),
          setTotal(MyTotal + Number.parseFloat(finaltaxNow, 10) + Number.parseFloat(finalshippingPriceNow, 10)),
          setOrderTotal(Number.parseFloat(orderTotalNow, 10) + Number.parseFloat(finaltaxNow, 10)),
          500
        );
        return () => clearTimeout(timeOutId);
      }
    }, [taxStore, subTotStore, discountStore, orderTotalStore, orderTotal, shippingpriceStore]);

    const isVenorButtonDisabled = () => {
      const trueCount = [vendorerror, novendorIssue].filter(Boolean).length;
      return trueCount !== 0;
    };

    useEffect(() => {
      return () => {
        if (typingTimeout) {
          clearTimeout(typingTimeout);
        }
      };
    }, [typingTimeout]);

    const handleBlurDate = (event) => {
      setTransactionDateObj(event?.target?.value);
      if (!editable && invoiceDateMismatch && event?.target?.value != transactionDateForMatch)
        setAPICall({
          payload: [
            {
              transactionDate: `${event?.target?.value}`,
              businessId: `${businessId}`,
              documentId: `${invoice_id}`,
            },
          ],
        });
    };

    const handleChangeBusiness = (event, newValue) => {
      if (newValue === "+ Add Vendor") {
        setVendorSelect(vendorSelect);
      } else {
        setVendorSelect1(newValue);
      }
      setVendorname2("");
      if (newValue == "+ Add Vendor") {
        setOpenEditVendor(true);
      } else {
        if (newValue) {
          setVendorerrormsg("");
          setVendorerror(false);
        } else {
          setVendorerrormsg("Vendor name required");
          setVendorerror(true);
        }
        const found = vendorList.find((obj) => {
          return obj.name === newValue;
        });
        const Checkselected = event?.target?.outerText ? event?.target?.outerText.length : 0;
        if (found && found._id) {
          setShowselectedVendorId(found._id);
          if (!editable && isVenorButtonDisabled() && Checkselected != 0) {
            setAPICall({
              payload: [
                {
                  vendorId: `${addNewVendorId ? addNewVendorId : found._id}`,
                  businessId: `${businessId}`,
                  documentId: `${invoice_id}`,
                },
              ],
            });
            eventBus.$emit("refreshApiInvoiceList", event);
          }
        } else {
          setShowselectedVendorId("");
        }
      }
    };

    useEffect(() => {
      setStyles( value === 2 || value === 3 ? { display: "grid", overflowY: "scroll", overflowX: "scroll", height: "80%" } : { height: "80%", overflow: "auto" } ); }, [value]);

    const AsignNewClientMsg = () => {
      return (
        <MDBox sx={{ ml: 4, mb: -1 }}>
          <MDTypography>No email has been associated with this client account.</MDTypography>
          <MDTypography display="flex" justifyContent="center">
            Click here to add and email for this client
          </MDTypography>
        </MDBox>
      );
    };

    useEffect(() => {
      if (createObjectURL) {
        setExtention(createObjectURL.split(".").pop());
      }
    }, [createObjectURL]);

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { CurrentPageLabel } = pageNavigationPluginInstance;

    const QboSyncMsg = () => {
      return (
        <MDBox sx={{ ml: 1, mb: -1 }}>
          <MDTypography>We are not allowed to update once they have been synced</MDTypography>
          <MDTypography sx={{ display: "flex", justifyContent: "center" }}>
            to quickbooks.
          </MDTypography>
        </MDBox>
      );
    };
    useEffect(() => {
      !editable
        ? setDottedStyle({ border: "none", borderRadius: 0, background: "none",  color: "inherit",  borderTop: "0px", fontSize: "1rem", width: "100%", backgroundColor: darkMode ? "#202940" : "#ffffff", boxShadow: "0px" })
        : setDottedStyle({  border: "none", borderRadius: 0,  background: "none",  color: "inherit",  width: "100%", borderBottom: darkMode ? "2px solid #ffffff" : "1px solid #949494", marginTop: "16px", borderTop: "0px", fontSize: "1rem", backgroundColor: darkMode ? "#202940" : "#ffffff",  boxShadow: "none", transition: "border-color 0.3s ease", 
            "&:hover": {
              borderBottomColor: darkMode ? "#ffffff" : "#000000", 
            },
          });
    }, [editable]);

    const hideMoveAlert = () => {
      setAnchorEl(null);
      setalertMoveInvoice(false);
    };

    useEffect(() => {
      if ( issues?.documents?.count !== 0 || issues?.items?.count !== 0 || issues?.vendor?.count !== 0 ) {
        setCheckIssue(true);
      } else {
        setCheckIssue(false);
      }
    }, [issues]);

    const tabRowsDataToReview = [
      {
        key: {
          AskClient: "Ask Client",
          ToReview: "To Review",
          Excluded: "Excluded",
        },
        Tab: "Classify",
        Permission: ["Admin", "Manager", "User"],
      },
      {
        key: {
          Classify: "Classify",
        },
        Tab: "AskClient",
        Permission: ["Admin", "Manager"],
      },
      {
        key: {
          Classify: "Classify",
          PendingApproval: "Pending Approval",
        },
        Tab: "ToReview",
        Permission: ["Admin", "Manager"],
      },
      {
        key: {
          Classify: "Classify",
          Approved: "Approved",
        },
        Tab: "PendingApproval",
        Permission: ["Admin", "Manager"],
      },
      {
        key: {
          Classify: "Classify",
        },
        Tab: "Approved",
        Permission: ["Admin", "Manager"],
      },
      {
        key: {
          Classify: "Classify",
        },
        Tab: "Excluded",
        Permission: ["Admin", "Manager"],
      },
      {
        key: {},
        Tab: "AllDoc",
        Permission: [""],
      },
    ];

    const tabRowsData = [
      {
        key: {
          AskClient: "Ask Client",
          Excluded: "Excluded",
        },
        Tab: "Classify",
        Permission: ["Admin", "Manager", "User"],
      },
      {
        key: {
          Classify: "Classify",
        },
        Tab: "AskClient",
        Permission: ["Admin", "Manager"],
      },
      {
        key: {
          Classify: "Classify",
          PendingApproval: "Pending Approval",
        },
        Tab: "ToReview",
        Permission: ["Admin", "Manager"],
      },
      {
        key: {
          Classify: "Classify",
          Approved: "Approved",
        },
        Tab: "PendingApproval",
        Permission: ["Admin", "Manager"],
      },
      {
        key: {
          Classify: "Classify",
        },
        Tab: "Approved",
        Permission: ["Admin", "Manager"],
      },
      {
        key: {
          Classify: "Classify",
        },
        Tab: "Excluded",
        Permission: ["Admin", "Manager"],
      },
      {
        key: {},
        Tab: "AllDoc",
        Permission: [""],
      },
    ];

    const finaltabRowsData = checkIssue ? tabRowsData : tabRowsDataToReview;
    const moveSelect = finaltabRowsData.find((obj) => {
      return obj.Tab === documentStatus ? obj.Tab === documentStatus : obj.Tab === "AllDoc";
    });

    const openMenu = (event) => {
      setAnchor(event.currentTarget);
    };
    const closeMenu = () => {
      setAnchor(null);
    };

    const onButtonItemClick = async (move) => {
      const selectMoveTab = move.replace(/ /g, "");
      if (ownerAssigned === false && selectMoveTab === "AskClient") {
        setAssignNewClient(true);
      } else {
        setTabsMove(selectMoveTab);
        setalertMoveInvoice(true);
      }
    };

    const onMenuItemClick = async (event, key) => {
      if (selectTab === "Approved") {
        setShowApprovedModel(true);
      } else {
        setAnchor(null);
        const selectMoveTab = event.target.textContent.replace(/ /g, "");
        if (ownerAssigned === false && selectMoveTab === "AskClient") {
          setAssignNewClient(true);
        } else {
          setTabsMove(selectMoveTab);
          setalertMoveInvoice(true);
        }
      }
    };

    const moveOptionName = {  Classify: "Classify", AskClient: "Ask Client", ToReview: "To Review", PendingApproval: "Pending Approval", Approved: "Approved", Excluded: "Excluded",  Synced: "Quickbook" };

    useEffect(() => {
      Object.keys(moveOptionName).map(function (k) {
        k === documentStatus ? setFinalSelect(moveOptionName[k]) : "";
      });
    }, [documentStatus]);

    const handleClickImgDownload = () => {
      window.open(`${createObjectURL}`, "_blank");
    };

    async function createFile() {
      if (imageUrl && counts === 0) {
        setCounts(counts + 1);
        let response = await fetch(imageUrl);
        let data = await response.blob();
        let metadata = {
          type: "image/jpeg",
        };
        let file = new File([data], "test.jpg", metadata);
        const imageDataUrl = URL.createObjectURL(file);
        setImageUrl1(imageDataUrl);
      }
    }
    useEffect(() => {
      createFile();
    }, [imageUrl]);

    const rotateImage = () => {
      // Update the rotation angle by 90 degrees
      setRotationAngle((prevAngle) => prevAngle + 90);
    };

    const Controls = () => {
      const { zoomIn, zoomOut } = useControls();
      return (
        <div style={{ display: "flex", justifyContent: "center", marginBottom: "23px", marginTop: "-23px", marginRight: "125px" }} >
          <a style={{ display: "flex", justifyContent: "center", marginLeft: "100px" }}>
            <img onClick={() => zoomOut()} style={{ marginRight: "20px", cursor: "pointer", marginBottom: "-6px", filter: darkMode ? "brightness(0) invert(1)" : "" }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+0lEQVR4nLXTTSvFQRTH8c9FKAsLS9lYWSgpG5dXwC1ZKTuLKwsbpWxEnp+SDStLKRsskYW8IW+Bpob+6c7/zs31q9Oc5kzfc+bMGf5RQzjFK57ieomRViCLeMEUKoX9MTxgLQdSww26EvEKzlAvg/TgHb1NknXEigdSB2axnlM2FrCSCm6hGv0+DDew/sJjXKdAhxiP/iiOG9h0jIdr3aZAy7HkHFWxlwoO4j4TdIWJsgMXmG8CmcRds0zdeMTSr2H81hw+cC5DYRg38IYjrGIXz9jECT6xL1OdsQ+12NzioB60CivTToSFz/1nhREIsO12wAJkph2gH30BrGgnHq5WuyEAAAAASUVORK5CYII=" title="ZoomIn" />
          </a>
          <a style={{ display: "flex", justifyContent: "center" }}>
            <img onClick={() => zoomIn()} style={{  marginRight: "20px", cursor: "pointer",  marginBottom: "-6px", filter: darkMode ? "brightness(0) invert(1)" : ""  }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAABCElEQVR4nLXTTyuFQRTH8c9FKMrCUjZWFkrKxuUVcEtWyu4uyMJGKQsi//8lG1aWUjZYIgvxQrwEb4Gmhp6e7lzPzfWr05zzm/o2Z+YM/6h+HOMZD3E9x2AjkDk8YRyljD+MOywXgVRwhbbEfgknmK8H6cArOnP+Wq5uiSfuTYGmsFLDf6zhzWIxBdpAOeZdGIjxlsl7Mo9xmQLtYyTmQziM8Z7JJ+J+aOs6BVqIRy7SWhk7KVAfbguCLjCqjs4wk/O6c/UYbvyidtyjmhvGb03jA6cKKAzjKl5wgCVsxxbXcYRP7Cqo1ngPlXi52UHdaxRWT1sRFj73nxVGIMA2mwELkMlmgH70Bf8ZKsMW9hShAAAAAElFTkSuQmCC"  title="ZoomIn"   />
          </a>
        </div>
      );
    };
    const workerUrl = "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js";
    if (darkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }

    const ApiCallBeforeClose = () => {
      // Update the rotation angle by 90 degrees
      reloadApiAfterClose()
      if(isFormDirty){
        setAPICall({
          payload: [
            {
              transactionDate: `${transactionDateObj}`,
              createdAt: `${uploaddate}`,
              documentType: `${documentTypeObj}`,
              docNumber: `${invoiceNumberObj}`,
              vendorId: `${addNewVendorId ? addNewVendorId : showselectedVendorId}`,
              subTotal: Number(finalStoreObj),
              tax: Number(finalTaxObj),
              shippingPrice: Number(finalShippingPriceObj),
              discount: Number(finaldiscountObj),
              total: Number(finalTotalObj),
              purchaseOrderNo: `${ponumberObj}`,
              passDuplicate:passDuplicate,
              businessId: `${businessId}`,
              documentId: `${invoice_id}`,
            },
          ],
        });
      }
    };

    useEffect(() => {
      if(searchInvoice){
        const ThisOffsetuse = localStorage.getItem("offset");
        const timeOutId = setTimeout(
          async () =>
            await GetInvoiceListAPICall({
              payload: [
                {
                  businessId: businessId,
                  isSynced:true,
                  invoiceStatus: "",
                  offset: ThisOffsetuse,
                  limit: limit,
                  keyword: `${searchInvoice}`,
                },
              ],
            }),
          500
        );
        return () => clearTimeout(timeOutId);
      }else{
        setLoadingCircul(false);
      }
    }, [searchInvoice]);

    const handleSearch = async (event ,newInputValue) => {
      localStorage.setItem("offset", 0);
      setSearchInvoice(newInputValue);
      // setLoading(true);
      setLoadingCircul(true);
    };
  
    const handleKeyDown = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    };

    const handleClickOpenInvoice = async () => {
      localStorage.setItem('selectedIdsForAllPage',[]);
      localStorage.setItem("offset", 0);
      setPushPopFun(true);
      setLoadingCircul(true);
      await StatmentDocListListAPICall({
        payload: [
          {
            clientId: businessId,
            offset: 0,
            limit: 10,
            keyword: `${searchInvoice}`,
          },
        ],
      });
      setOpenAddNewInvoice(true);
    };

    const HandleCloseInvoice = () => {
      setSearchInvoice('');
      setIsDisable(false);
      setSearchInvoice('');
      setDocumentsList([]);
      setOpenAddNewInvoice(false)
    };

    const handleAddNewInvoice = async() => {
      // Update the rotation angle by 90 degrees
      await AddInvoiceListAPICall({
        payload: [{ InvoiceId: `${invoiceId}`, docNumber : searchInvoice }],
      });
      setIsDisable(true);
      // setSearchInvoice(null);
      // setDocumentsList([]);
      // setOpenAddNewInvoice(false)
    };


    return (
      <> {!showDetails ? ( <MDBox mt={"-10%"} display="flex" justifyContent="center"> {<LoaderDailog />} </MDBox>  ) : (
          <>
            <MDBox mb={3} display={"flex"}>
              <Typography variant="h6" className={classes.title}>
                <MDBox display={"flex"}>
                  <MDTypography fontSize={"1rem"}>Document in</MDTypography>
                  <MDTypography sx={{ ml: 0.5 }} fontSize={"1rem"} fontWeight={"bold"}>
                    {finalSelect ? finalSelect : documentStatus}
                  </MDTypography>
                  <MDBox>
                    {ocrStatus1 !== "COMPLETED" || documentSource === "QBO" ? ( "" ) : (
                      <Tooltip title={action.Movetooltip}>
                        <IconButton  onClick={openMenu} sx={{ mt: -0.3 }} aria-controls={openMenu ? "account-menu" : undefined} aria-haspopup="true" aria-expanded={openMenu ? "true" : undefined} >
                          <DriveFileMoveOutlinedIcon  sx={{ color: darkMode ? "#ffffff" : "#000000",  height: `0.7em`, width: `1em`, mb: 1 }} />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted>
                      {Object.keys(moveSelect.key).map((key, index) => ( <MenuItem key={index} onClick={(event) => onMenuItemClick(event, key)} > {moveSelect.key[key]} </MenuItem> ))}
                    </Menu>
                  </MDBox>
                </MDBox>
              </Typography>
              <MDBox> <Icon sx={ darkMode ? { cursor: "pointer", mr: 2, color: "#ffffff" } : { cursor: "pointer", mr: 2 } } fontSize="medium" onClick={ApiCallBeforeClose} > close </Icon>
              </MDBox>
            </MDBox>
            <MDBox mb={3} style={{ height: "95%", overflow: "auto", display: "block", marginBottom: 0 }} >
              <Grid container style={{ height: "100%", overflow: "auto" }}>
                {showLoaderFirInvoice ? ( <Grid item xs={12} sm={4} lg={4.5} ml={-2} style={{ height: "98%", overflow: "auto", border: "2px solid #000000" }} >
                    <CircularProgress size={50} sx={{color: green[500], marginTop: "50%", marginLeft: "45%", zIndex: 999 }} />
                  </Grid>
                ) : createObjectURL ? (
                  <Grid item xs={12} sm={4} lg={4.5} ml={-2} style={{ height: "98%",  overflow: "auto", border: "2px solid #000000", backgroundColor: darkMode ? "#202940" : "#ffffff" }} >
                    {extention == "pdf" ? (
                      <div className="rpv-core__viewer" style={{ display: "flex",flexDirection: "column", height: "97%" }} >
                        <div style={{  alignItems: "center", borderBottom: "1px solid rgba(0, 0, 0, 0.1)", display: "flex", padding: "0.25rem", backgroundColor: darkMode ? "#1a2035" : "#eeeeee" }} >
                          <Toolbar>
                            {(props) => {
                              const { CurrentPageInput, NumberOfPages, ZoomIn, ZoomOut, Download } = props;
                              return (
                                <>
                                  <div style={{ padding: "0px 2px",  width: "4rem", marginLeft: "10px", color: darkMode ? "#ffffff" : "" }} > <CurrentPageInput /> </div>
                                  <div style={{ padding: "0px 2px", color: darkMode ? "#ffffff" : "" }} > of <NumberOfPages /> </div>
                                  <div style={{ padding: "0px 2px", marginLeft: "35%" }}> <ZoomOut /> </div>
                                  <div style={{ padding: "0px 2px", marginLeft: "10px" }}> <ZoomIn /> </div>
                                  <div style={{ padding: "0px 2px", display: "flex" }}> <Download /> </div>
                                  <div style={{alignItems: "center", display: "flex", justifyContent: "end",paddingBottom: "2px", marginLeft: "8px" }} >
                                  <img style={{ marginRight: "20px",cursor: "pointer", filter: darkMode ? "brightness(0) invert(1)" : "" }} width="19" height="19" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjUlEQVR4nO2VQQqAIBBF3yUmOlJXqePmor0dxAgMhGoc01b1YHYz/4kMCj+FDMAKBKUOLD0nfGYw1AqyDRcIML8l6AAXZ1xrgSQnX4C+pUAuwmklkJvwJgJRwk1oAqkN1wRiDH90RVJw8keCKdnzfe9L500NoyG8SmDlAwJveK5DpvYM9cOpkfiY8YOZDdq3j/znzI3XAAAAAElFTkSuQmCC" alt="fullscreen" onClick={() => window.open(`${createObjectURL}`, "_blank")} title="Full Screen" />
                                  </div>
                                </>
                              );
                            }}
                          </Toolbar>
              </div>
              <div style={{ flex: 1, overflow: "hidden" }} >  {extention == "pdf" && ( <Worker workerUrl={workerUrl}> <Viewer fileUrl={createObjectURL} plugins={[toolbarPluginInstance]} /> </Worker> )} </div> </div>  ) 
              : ( <div className="rpv-core__viewer" style={{display: "flex", flexDirection: "column",  height: "97%"}} >
                        <div style={{ alignItems: "center", backgroundColor: "transparent", display: "flex", justifyContent: "center" }} >
                          <CurrentPageLabel>{(props) => ( <span>{`${props.currentPage + 1} of ${props.numberOfPages}`}</span>)} </CurrentPageLabel>
                        </div>
                        <div style={{ alignItems: "center", display: "flex", justifyContent: "end", padding: "0.25rem"}} >
                          <img style={{ marginRight: "20px", cursor: "pointer", filter: darkMode ? "brightness(0) invert(1)" : "" }} width="18" height="18" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABN0lEQVR4nN3Vv0scQRjG8Y8edyixsLQVLUSL/AViivR6ooelnahobMXGTpK/I4l/gL2VvXgK/oqNnViImkC6CwuvMMW5d7d6oHlgi5155vnOvLy7w/+uCmr4iTP8xh9cxdgCyol/u5PwWfxCo8VzjWqEZ+8t1YtvScAR1jGOD+jHGFZRbwJsqa9h/IulAOZtZr8TQDUJn2pjM09laQtQSWqe7fzVVUtqnleWwtoNwJou6TwAWbd0RY8BGHjLgMHIuOtWiUbxgMNmkz8C8MXLVMJws4n5ANQLtmk5Wr2UZ7gMyHIBwFas3cszzSS/ik8dAiZx0866nQSyknfkOPUmPiZd1FK9CaSBY2xgIlo4CxkJ72J4stL26VDTuHjmkrlFT9wNB/isoMqYw3ecxsd4jxMMFQ19X/oHPMJfdYMk80cAAAAASUVORK5CYII="  onClick={rotateImage} alt="rotate" title="Rotate Image" />
                          <a href={imageUrl1} download={"downloaded-image.jpeg"}>
                            <img style={{ marginRight: "20px", cursor: "pointer", marginBottom: "-6px", filter: darkMode ? "brightness(0) invert(1)" : "" }} width="18" height="18" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAfklEQVR4nO2SUQqAIBAF5xJK3bcumB9eJb8MwQUTC8ItiBpYUFbeyCp8hRkIQMyV1pOmIBThUqumIOY62v8C/hHtMMDS+JaxONPqeWCgUyJ0hbckHhhPepfDzyRGK1ywgMuBrlqnngqmehOVmx9J/B3hgtUcy/uIyvW8gFeyAeoqduPSsPEqAAAAAElFTkSuQmCC" title="Download" />
                          </a>
                          <img style={{ marginRight: "20px", cursor: "pointer", filter: darkMode ? "brightness(0) invert(1)" : "" }} width="19" height="19" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjUlEQVR4nO2VQQqAIBBF3yUmOlJXqePmor0dxAgMhGoc01b1YHYz/4kMCj+FDMAKBKUOLD0nfGYw1AqyDRcIML8l6AAXZ1xrgSQnX4C+pUAuwmklkJvwJgJRwk1oAqkN1wRiDH90RVJw8keCKdnzfe9L500NoyG8SmDlAwJveK5DpvYM9cOpkfiY8YOZDdq3j/znzI3XAAAAAElFTkSuQmCC" alt="fullscreen" onClick={handleClickImgDownload} title="Full Screen" />
                        </div>
                        <div>
                          <TransformWrapper defaultScale={1}  defaultPositionX={0} defaultPositionY={0} >
                            <Controls />
                            <TransformComponent contentStyle={{ width: "100% !important", height: "110%", marginBottom: "100px", marginLeft: "15px",  marginRight: "10px" }} >
                              <img src={createObjectURL} alt="test" width="95%" height="98%" style={{ transform: `rotate(${rotationAngle}deg)` }} />
                            </TransformComponent>
                          </TransformWrapper>
                        </div>
                      </div>
              )}
              </Grid>
              ) : (
                  <Grid item xs={12} sm={4}lg={4.5} ml={-2} style={{ height: "98%", overflow: "auto",  border: "2px solid #000000", backgroundColor: darkMode ? "#202940" : "#ffffff" }}>
                    <div
                      style={{ display: "flex", flexDirection: "column", height: "100%", border: "0.5px solid gray"}}>
                      <div style={{ flex: 1, overflow: "hidden",display: "flex", justifyContent: "center", marginTop: "50%", color: darkMode ? "white" : ""}} >
                        <h2> {documentSource === "QBO" ? "No image available from Quickbooks" : "No image available from Paynthr"} </h2>
                      </div>
                    </div>
                  </Grid>
              )}
              <Grid item xs={12} sm={8} lg={7.5} style={{ height: "100%", overflow: "auto" }}>
                  <Card style={{ height: "100%",overflow: "auto", backgroundColor: darkMode ? "#202940" : "#ffffff" }} >
                    <Tabs
                      sx={darkMode && { backgroundColor: "#1a2035 !important" }}
                      className={darkMode ? classes.tabs : ""}
                      value={value}
                      onChange={handleChange}
                      aria-label="tabs"
                    >
                      <Tab className={darkMode ? classes.tabs : ""}
                        label={
                          issues?.documents ? (
                            <MDBadge
                              overlap="circular"
                              badgeContent={issues?.documents?.count}
                              sx={{
                                "& .MuiBadge-badge": {
                                  color: "#ef5350",
                                  background: "transparent",
                                },
                              }}
                            >
                              Details &nbsp; &nbsp; &nbsp;
                            </MDBadge>
                          ) : (
                            "Details"
                          )
                        }
                        {...a11yProps(0)}
                      />
                      <Tab label={ issues?.items ? (
                            <MDBadge
                              overlap="circular"
                              badgeContent={issues?.items?.count}
                              sx={{
                                "& .MuiBadge-badge": {
                                  color: "#ef5350",
                                  background: "transparent ",
                                },
                              }}
                            > Invoices &nbsp; &nbsp; &nbsp; </MDBadge>) : ("Invoices") }{...a11yProps(1)}
                      />
                      <Tab label="Comments" {...a11yProps(2)} />
                      <Tab label="Audit Log" {...a11yProps(3)} />
                    </Tabs>
                    <CardHeader
                      subheader={
                        <MDBox>
                          <Grid item sx={12} sm={12} md={12} style={{ display: "flex", marginLeft: "70px", fontSize: "1rem" }} >
                            <Grid item sx={12} xs={12}>
                              <span style={{ display: "flex", justifyContent: "center", color: darkMode ? "#ffffff" : "" }} >
                                Total
                              </span>
                              <span style={{ display: "flex", justifyContent: "center", color: darkMode ? "#ffffff" : "" }} >
                                {currencySymbol}
                                {actualTotal}
                              </span>
                            </Grid>
                          </Grid>
                          {ocrStatus ? (
                            <MDBox sx={{ mb: -2, mt: 1, display: "flex", justifyContent: "center" }}>
                              <MDTypography fontSize={"1rem"} color={"warning"}>
                                Modifications cannot be made during an ongoing document upload
                                process.
                              </MDTypography>
                            </MDBox>
                          ) : ( "" )}
                        </MDBox>
                      }
                    />
                    <Divider />
                    <CardContent style={styles}>
                      <TabPanel value={value} index={0}>
                        {showDetailsForm && (
                          <MDBox my={2}>
                            <Box>
                              <Grid item xs={12} sm={12} md={12}>
                                <Formik
                                  enableReinitialize
                                  initialValues={initialValues}
                                  validationSchema={InvoiceEditSchema}
                                  onSubmit={handleSubmit}
                                >
                                  {({values, errors, touched, handleChange, handleBlur, handleSubmit,isSubmitting,setFieldValue,dirty }) => {
                                    if (dirty !== isFormDirty) {
                                      setIsFormDirty(dirty);
                                    }
                                    useEffect(() => {
                                      if(values?.documenttype){
                                        setDocumentTypeObj(values?.documenttype);
                                      }
                                      if(values?.invoicenumber){
                                        setInvoiceNumberObj(values?.invoicenumber);
                                      }
                                      if(values?.ponumber){
                                        setPonumberObj(values?.ponumber);
                                      }
                                    }, [values]);
                                    return (
                                      <Form autoComplete="off">
                                        {/* <Card sx={{ height: "100%" }}> */}
                                        <Grid container spacing={3}>
                                          <Grid item xs={12} sm={6}>
                                            <div
                                              onClick={() =>
                                                isVenorButtonDisabled()
                                                  ? setVendorEditable(false)
                                                  : setVendorEditable(true)
                                              }
                                              onBlur={() => setVendorEditable(true)}
                                            >
                                              <Autocomplete
                                                disableClearable
                                                open={openList}
                                                onOpen={() => setOpenList(true)}
                                                onClose={() => setOpenList(false)}
                                                onFocus={() => setVendorEditable(true)}
                                                sx={
                                                  darkMode
                                                    ? {
                                                        marginTop:-1,
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                          WebkitTextFillColor: "#ffffff",
                                                          borderBottom: "2px solid #ffffff",
                                                        },
                                                        "& .MuiInput-root .MuiInput-input": {
                                                          WebkitTextFillColor: "white",
                                                          borderBottom: "2px solid #ffffff",
                                                        },
                                                        "& .MuiFormLabel-root-MuiInputLabel-root": {
                                                          color: "white",
                                                        },
                                                        "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator":
                                                          {
                                                            color: "white !important",
                                                          },
                                                        "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator":
                                                          {
                                                            color: "white !important",
                                                          },
                                                      }
                                                    : {
                                                      marginTop:-1,
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                          WebkitTextFillColor: "black",
                                                        },
                                                      }
                                                }
                                                noOptionsText={
                                                  <MDTypography
                                                    sx={{color:'#000000', fontSize: "0.775rem", cursor: "pointer" }}
                                                    color="info"
                                                    onClick={handleAddNewVendor}
                                                  >
                                                    {vendorname + " " + `(New Vendor)`}
                                                  </MDTypography>
                                                }
                                                disableCloseOnSelect={false}
                                                // classes={{ option: classes.option }}
                                                forcePopupIcon={editable ? true : false}
                                                options={
                                                  finalSelect == "Ask Client" ||
                                                  finalSelect == "Classify" ||
                                                  documentStatus == "AskClient" ||
                                                  documentStatus == "Classify"
                                                    ? vendorList
                                                    : vendorList
                                                }
                                                getOptionLabel={(option) => option.name}
                                                value={{
                                                  name: vendorname2 ? vendorname2 : vendorname,
                                                }}
                                                onInputChange={(event, newInputValue) => {
                                                  setVendorname(newInputValue),
                                                    handleChangeBusiness(event, newInputValue);
                                                }}
                                                id="controllable-states-demo"
                                                disabled={
                                                  editable ? !editable : !isVenorButtonDisabled()
                                                }
                                                renderInput={(params) =>
                                                  vendorerror || novendorIssue ? (
                                                    <TextField
                                                      {...params}
                                                      label="Vendor Name *"
                                                      variant="standard"
                                                      error={vendorerror || novendorIssue}
                                                      helperText={vendorerrormsg}
                                                      InputProps={{
                                                        endAdornment: (
                                                          <Tooltip
                                                            title={novendorIssueMsg}
                                                            placement="top"
                                                          >
                                                            <InputAdornment position="end">
                                                              <ErrorOutlineIcon
                                                                sx={{
                                                                  fontSize: "1rem !important",
                                                                  mr: "14px",
                                                                  color: "red",
                                                                }}
                                                              />
                                                            </InputAdornment>
                                                          </Tooltip>
                                                        ),
                                                      }}
                                                    />
                                                  ) : (
                                                    <TextField
                                                      {...params}
                                                      label="Vendor Name *"
                                                      variant="standard"
                                                      helperText={vendorerrormsg}
                                                      InputLabelProps={
                                                        darkMode
                                                          ? {
                                                              style: { color: "#ffffff" },
                                                            }
                                                          : {}
                                                      }
                                                      InputProps={{
                                                        sx: darkMode
                                                          ? {
                                                              "& .MuiInputBase-input.Mui-disabled": {
                                                                borderBottom:
                                                                  "2px solid #ffffff !important",
                                                              },
                                                            }
                                                          : {},
                                                      }}
                                                    />
                                                  )
                                                }
                                              />
                                            </div>
                                          </Grid>
                                          {
                                          // editable ? (
                                          //   <Grid item xs={12} sm={6}>
                                          //     <FormControl
                                          //       fullWidth
                                          //       variant="standard"
                                          //       sx={{
                                          //         mt: 1,
                                          //         mb: 0,
                                          //         border: "none",
                                          //       }}
                                          //     >
                                          //       <InputLabel
                                          //         style={{
                                          //           fontSize: "0.8rem",
                                          //           lineHeight: 1,
                                          //           color: darkMode ? "#ffffff" : "#000000",
                                          //         }}
                                          //         sx={{ mt: -3 }}
                                          //       >
                                          //         Document Type
                                          //       </InputLabel>
                                          //       <Field
                                          //         as="select"
                                          //         name="documenttype"
                                          //         variant="standard"
                                          //         disabled={!editable}
                                          //         style={dottedStyle}
                                          //       >
                                          //         <option value="">Select Type</option>
                                          //         {Object.keys(DocumnetType).map((key) => (
                                          //           <option value={key}>{DocumnetType[key]}</option>
                                          //         ))}
                                          //       </Field>
                                          //       <MDBox mt={0.75}>
                                          //         <MDTypography
                                          //           component="div"
                                          //           variant="caption"
                                          //           color="error"
                                          //           fontWeight="regular"
                                          //         >
                                          //           <ErrorMessage error={true} name="documenttype" />
                                          //         </MDTypography>
                                          //       </MDBox>
                                          //     </FormControl>
                                          //   </Grid>
                                          // ) : 
                                          (
                                            <Grid item xs={12} sm={6} sx={{ mt: -1.5 }}>
                                              <FormField
                                                type="text"
                                                label="Document Type"
                                                name="documenttype"
                                                disabled={!editable}
                                                editable={!editable}
                                              />
                                            </Grid>
                                          )}
                                        </Grid>
                                        <Grid container spacing={3}>
                                          {documentSource === "QBO" ? (
                                            <Grid
                                              item
                                              xs={12}
                                              sm={6}
                                              sx={{ mt: 1 }}
                                              className={!editable ? "bg-salmon" : ""}
                                            >
                                              <FormFieldDate
                                                type="date"
                                                label="Statement Date"
                                                name="transactionDate"
                                                // error={true}
                                                disabled={!editable}
                                                editable={!editable}
                                              />
                                            </Grid>
                                          ) : 
                                          (
                                            <Grid
                                              item
                                              xs={12}
                                              sm={6}
                                              sx={{ mt: 1 }}
                                              className={
                                                !invoiceDateMismatch && !editable ? "bg-salmon" : ""
                                              }
                                            >
                                              <FormFieldDate
                                                classes={{ root: classes.InputRoot }}
                                                type="date"
                                                label="Transaction Date"
                                                name="invoicedate"
                                                value={transactionDateObj}
                                                dateMismatch={invoiceDateMismatch}
                                                // error={invoiceDateMismatch}
                                                noInvoiceDateMsg={"invoiceDateMismatch"}
                                                autoFocus={invoiceDateMismatch}
                                                // disabled={!editable}
                                                editable={!editable}
                                                onChange={handleBlurDate}
                                                // onBlur={!editable ? handleBlurDate : handleBlurTotal2}
                                              />
                                            </Grid>
                                          )}
                                          <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            sx={{ mt: 1 }}
                                            className={"bg-salmon"}
                                          >
                                            <FormFieldDate
                                              type="date"
                                              label="Upload Date"
                                              name="uploaddate"
                                              // error={true}
                                              disabled={true}
                                              editable={!editable}
                                            />
                                          </Grid>
                                        </Grid>
                                        {/* {selectTab !== 'Reconciled' &&(
                                          <Grid
                                            item
                                            xs={12}
                                            sx={{ display: "flex", justifyContent: "end" }}
                                          >
                                            {(
                                              <MDBox mt={{ xs: 2, sm: 3 }}>
                                                {!editable &&
                                                  canAccess(
                                                    (actions = "update"),
                                                    (resource = "documents")
                                                  ) && (
                                                    <MDButton
                                                      variant="gradient"
                                                      color="info"
                                                      disabled={ocrStatus}
                                                      onClick={() => SetEditable(true)}
                                                    >
                                                      Edit
                                                    </MDButton>
                                                  )}
                                              </MDBox>
                                            )}
                                            {editable && (
                                              <MDBox
                                                mt={4}
                                                width="100%"
                                                display="flex"
                                                justifyContent="end"
                                              >
                                                <MDButton
                                                  sx={{ marginLeft: "8px" }}
                                                  disabled={btnDisable}
                                                  variant="gradient"
                                                  color="light"
                                                  onClick={handleClosebtn}
                                                >
                                                  Cancel
                                                </MDButton>
                                                <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                  <MDButton
                                                    disabled={btnDisable}
                                                    type="submit"
                                                    variant="gradient"
                                                    color="info"
                                                    autoFocus
                                                  >
                                                    {btnDisable ? "Updating..." : "Update"}
                                                  </MDButton>
                                                </MDBox>
                                              </MDBox>
                                            )}
                                          </Grid>
                                        )} */}
                                      </Form>
                                    );
                                  }}
                                </Formik>
                              </Grid>
                            </Box>
                            <MDBox>
                              <Dialog
                                open={showPassDuplicate}
                                // onClose={()=>setShowPassDuplicate(false)}
                                aria-labelledby="alert-dialog-title"
                                sx={{
                                  "& .MuiDialog-container": {
                                    "& .MuiPaper-root": {
                                      width: "100%",
                                      // height: "40%",
                                      maxWidth: "600px",
                                      backgroundColor:darkMode ? '#424242 !important':'#ffffff',
                                      color: darkMode ? '#ffffff':'#7b809a',
                                    },
                                  },
                                }}
                              >
                                <DialogTitle sx={darkMode && { color: "#ffffff" }} align="center">
                                  <h3>Duplicate Document Found</h3>
                                </DialogTitle>
                                <DialogContent sx={{paddingTop:'40px !important'}} >
                                  <DialogContentText sx={{ display: "flex", justifyContent: "center"  }}>
                                  </DialogContentText>
                                  <DialogContentText sx={{color: darkMode ? '#ffffff' : '', display: "flex", justifyContent: "center" ,m:5 ,mt:-2}}>
                                      {passDuplicateMsg}
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions sx={{ justifyContent: "center" ,mt:-3 ,mb:2}}>
                                  <MDButton
                                    variant="gradient"
                                    color="success"
                                    onClick={callApiAfterPassDuplicate}
                                  >
                                    Yes
                                  </MDButton>
                                  <MDButton
                                    variant="gradient"
                                    color="error"
                                    onClick={()=>setShowPassDuplicate(false)}
                                  >
                                    No
                                  </MDButton>
                                </DialogActions>
                              </Dialog>
                            </MDBox>
                            <MDBox>
                              <MDSnackbar
                                color="success"
                                icon="done"
                                // autoHideDuration={3000}
                                title="Success"
                                content="Document Updated Successfully."
                                open={showSnackbar}
                                close={() => setShowSnackbar(false)}
                              />
                            </MDBox>
                          </MDBox>
                        )}
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <MDBox mt={-3} sx={{ pt: 3 }}>
                       {selectTab !== 'Reconciled' && <MDBox display="flex" alignItems="center" ml={-1} justifyContent="end">
                          {(canAccess((actions = "update"), (resource = "documents")) && documentStatus === "Synced") ||
                            isSynced === true ? (
                                <MDButton
                                  disabled={ocrStatus}
                                  variant="contained"
                                  color="info"
                                  sx={{ ml: 2 }}
                                  onClick={() => setOpenQboSync(true)}
                                >
                                  Add New Invoice
                                </MDButton>) :
                                (<MDButton
                                disabled={ocrStatus}
                                variant="contained"
                                color="info"
                                sx={{ ml: 2 }}
                                onClick={handleClickOpenInvoice}
                              >
                                Add New Invoice
                              </MDButton> )}
                          </MDBox>}
                          <MDBox mt={1.5} mb={-5} ml={-6} mr={-6} sx={{ padding: '24px 0px 0px 24px' ,width:'106%' }}>
                            {(
                              cetagorycolumns?.rows?.length === 0 ? (
                                <MDBox my={5} sx={{ mt: 1 }}>
                                  <ThemeProvider theme={myTheme}>
                                    <DataGrid
                                      rows={rows}
                                      columns={cetagorycolumns}
                                      components={{
                                        NoRowsOverlay: () => (
                                          <Stack
                                            height="100%"
                                            alignItems="center"
                                            justifyContent="center"
                                            color= {darkMode ? "#ffffff" : ""}
                                          >
                                            No records found
                                          </Stack>
                                        ),
                                      }}
                                      sx={
                                        darkMode
                                          ? {
                                              "@media (max-width: 1440px)": {
                                                fontWeight: `0  !important`,
                                                fontSize: `0.775rem`,
                                              },
                                              ".MuiDataGrid-virtualScroller": {
                                                minHeight:'200px',
                                            },
                                              ".MuiDataGrid-columnSeparator": {
                                                display: "none",
                                              },
                                              '& .MuiDataGrid-columnHeaders': {
                                                maxHeight: '75px !important',
                                                minHeight:'75px !important',
                                                lineHeight:'8px !important',
                                                outline:'transparent !important'
                                              },
                                              '& .MuiDataGrid-columnHeaderTitle': {
                                                overflow: 'visible !important',
                                              },
                                              ".MuiSelect-select-MuiInputBase-input.MuiSelect-select": {
                                                color: darkMode ? "#ffffff !important" : "#000000 !important",
                                              },
                                              ".MuiDataGrid-columnHeaderDraggableContainer": {
                                                width: "112% !important",
                                              },
                                              "& .MuiTablePagination-root": {
                                                color: darkMode ? "#ffffff !important" : "#000000 !important",
                                              },
                                              border: 0,
                                              ".MuiDataGrid-columnHeaderTitle": {
                                                color: "#ffffff",
                                                fontWeight: "bold !important",
                                                overflow: "visible !important",
                                                marginTop: "4px"
                                              },
                                              ".MuiTypography-root.MuiTypography-body1": {
                                                color: "#ffffff !important",
                                              },
                                              ".MuiDataGrid-columnHeader:focus": {
                                                outline: "none !important",
                                              },
                                              ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                                {
                                                  outline: "none !important",
                                                },
                                            }
                                          : {
                                              "@media (max-width: 1440px)": {
                                                fontWeight: `0  !important`,
                                                fontSize: `0.775rem`,
                                              },
                                              ".MuiDataGrid-virtualScroller": {
                                                minHeight:'200px',
                                            },
                                              ".MuiDataGrid-columnSeparator": {
                                                display: "none",
                                              },
                                              '& .MuiDataGrid-columnHeaders': {
                                                maxHeight: '75px !important',
                                                minHeight:'75px !important',
                                                lineHeight:'8px !important',
                                                outline:'transparent !important'
                                              },
                                              '& .MuiDataGrid-columnHeaderTitle': {
                                                overflow: 'visible !important',
                                              },
                                              "& .MuiTablePagination-root": {
                                                color: darkMode ? "#ffffff !important" : "#000000 !important",
                                              },
                                              ".MuiDataGrid-columnHeaderDraggableContainer": {
                                                width: "112% !important",
                                              },
                                              border: 0,
                                              ".MuiDataGrid-columnHeaderTitle": {
                                                marginTop: "4px",
                                                color: "rgb(123, 128, 154)",
                                                fontWeight: "bold !important",
                                                overflow: "visible !important",
                                              },
                                              ".MuiDataGrid-columnHeader:focus": {
                                                outline: "none !important",
                                              },
                                              ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                                {
                                                  outline: "none !important",
                                                },
                                                ".MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within" :{
                                                  outline:'transparent !importnat'
                                                }
                                            }
                                      }
                                      pageSizeOptions={[5, 10, 25, 50, 100]}
                                      paginationModel={paginationModel}
                                      onPaginationModelChange={setPaginationModel}
                                    />
                                  </ThemeProvider>
                                </MDBox>
                              ) : (
                                <ThemeProvider theme={myTheme}>
                                <Box
                                  sx={{
                                    "& .caterror": {
                                      borderBottom: `0.5px solid red !important`,
                                    },
                                  }}
                                >
                                  <DataGrid
                                    rows={itemList}
                                    columns={selectTab !== 'Reconciled' ? selectTab == 'AskClient' ? cetagorycolumnswithoutaskclient :  cetagorycolumns : reconciledColumns}
                                    getCellClassName={(params) => {
                                      return params?.row?.proderror === true && params?.field === "category"
                                        ? "caterror"
                                        : "";
                                    }}
                                    disableRowSelectionOnClick
                                    sx={
                                      darkMode
                                        ? {
                                            "@media (max-width: 1440px)": {
                                              fontWeight: `0  !important`,
                                              fontSize: `0.775rem`,
                                            },
                                            ".MuiDataGrid-columnSeparator": {
                                              display: "none",
                                            },
                                            '& .MuiDataGrid-columnHeaders': {
                                              maxHeight: '75px !important',
                                              minHeight:'75px !important',
                                              lineHeight:'8px !important',
                                              outline:'transparent !important'
                                            },
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                              overflow: 'visible !important',
                                            },
                                            ".MuiSelect-select-MuiInputBase-input.MuiSelect-select": {
                                              color: darkMode ? "#ffffff !important" : "#000000 !important",
                                            },
                                            ".MuiDataGrid-columnHeaderDraggableContainer": {
                                              width: "112% !important",
                                            },
                                            "& .MuiTablePagination-root": {
                                              color: darkMode ? "#ffffff !important" : "#000000 !important",
                                            },
                                            ".MuiDataGrid-virtualScroller": {
                                              minHeight:'200px',
                                          },
                                            border: 0,
                                            ".MuiDataGrid-columnHeaderTitle": {
                                              color: "#ffffff",
                                              fontWeight: "bold !important",
                                              overflow: "visible !important",
                                              marginTop: "4px"
                                            },
                                            ".MuiTypography-root.MuiTypography-body1": {
                                              color: "#ffffff !important",
                                            },
                                            ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                            ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                              {
                                                outline: "none !important",
                                              },
                                          }
                                        : {
                                            "@media (max-width: 1440px)": {
                                              fontWeight: `0  !important`,
                                              fontSize: `0.775rem`,
                                            },
                                            ".MuiDataGrid-virtualScroller": {
                                              minHeight:'200px',
                                          },
                                            ".MuiDataGrid-columnSeparator": {
                                              display: "none",
                                            },
                                            '& .MuiDataGrid-columnHeaders': {
                                              maxHeight: '75px !important',
                                              minHeight:'75px !important',
                                              lineHeight:'8px !important',
                                              outline:'transparent !important'
                                            },
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                              overflow: 'visible !important',
                                            },
                                            "& .MuiTablePagination-root": {
                                              color: darkMode ? "#ffffff !important" : "#000000 !important",
                                            },
                                            ".MuiDataGrid-columnHeaderDraggableContainer": {
                                              width: "112% !important",
                                            },
                                            border: 0,
                                            ".MuiDataGrid-columnHeaderTitle": {
                                              color: "rgb(123, 128, 154)",
                                              fontWeight: "bold !important",
                                              overflow: "visible !important",
                                              marginTop: "4px",
                                            },
                                            ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                            ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                              {
                                                outline: "none !important",
                                              },
                                              ".MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within" :{
                                                outline:'transparent !importnat'
                                              }
                                          }
                                    }
                                    selectionModel={selectedRows}
                                    onSelectionModelChange={setSelectedRows}
                                    pagination
                                    pageSize={paginationModel.pageSize}
                                    page={paginationModel.page}
                                    onPageChange={(page) =>
                                      setPaginationModel((prevModel) => ({ ...prevModel, page }))
                                    }
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    pageSizeOptions={[5, 10, 25, 50, 100]}
                                    cellModesModel={cellModesModel}
                                    onCellModesModelChange={handleCellModesModelChange}
                                    onCellClick={handleCellClick}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                  />
                                </Box>
                              </ThemeProvider>
                              )
                            )}
                          </MDBox>
                          <MDBox
                            display="flex"
                            sx={{ justifyContent: "end" }}
                            justify-content="end"
                            mt={6}
                          >
                            {selectTab === "Excluded" && (
                              <MDButton
                                variant="gradient"
                                color="info"
                                disabled={ocrStatus}
                                onClick={() => onButtonItemClick("Classify")}
                              >
                                Move to Classify
                              </MDButton>
                            )}
                            {selectTab === "AskClient" && (
                              <MDButton
                                variant="gradient"
                                color="info"
                                disabled={ocrStatus}
                                onClick={() => onButtonItemClick("Classify")}
                              >
                                Move to Classify
                              </MDButton>
                            )}
                          </MDBox>
                            <MDBox
                              display="flex"
                              sx={{ justifyContent: "end" }}
                              justify-content="end"
                              mt={6}
                            >
                              {selectTab === "ToReview" && (
                                //  && checkFound === true
                                <MDButton
                                  type="submit"
                                  variant="gradient"
                                  style={darkMode ? {
                                    paddingLeft: "130px",
                                    color: "#202940",
                                    backgroundColor: "#202940",
                                  }:{
                                    paddingLeft: "130px",
                                    color: "#fffff",
                                    backgroundColor: "#ffffff",
                                  }}
                                  disabled={true}
                                  onClick={() => onButtonItemClick("AskClient")}
                                ></MDButton>
                              )}
                              {selectTab === "PendingApproval" && (
                                <MDButton
                                  type="submit"
                                  variant="gradient"
                                  style={darkMode ? {
                                    paddingLeft: "130px",
                                    color: "#202940",
                                    backgroundColor: "#202940",
                                  }:{
                                    paddingLeft: "130px",
                                    color: "#fffff",
                                    backgroundColor: "#ffffff",
                                  }}
                                  disabled={true}
                                  onClick={() => onButtonItemClick("AskClient")}
                                ></MDButton>
                              )}
                              {selectTab === "ToReview" && (
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  disabled={ocrStatus}
                                  onClick={() => onButtonItemClick("Classify")}
                                >
                                  Move to Classify
                                </MDButton>
                              )}
                              {selectTab === "PendingApproval" && (
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  disabled={ocrStatus}
                                  onClick={() => onButtonItemClick("Classify")}
                                >
                                  Move to Classify
                                </MDButton>
                              )}
                              {selectTab === "Approved" && (
                                <Tooltip
                                  title={
                                    "To move this document you must delete it from your QBO account."
                                  }
                                  placement="top"
                                >
                                  <MDBox>
                                    <MDButton variant="gradient" color="info" disabled={true}>
                                      Move to Classify
                                    </MDButton>
                                  </MDBox>
                                </Tooltip>
                              )}

                              {selectTab === "Unreconciled" && checkFound && (
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  sx={{mr:3}}
                                  disabled={ocrStatus || !checkFound}
                                  onClick={() => onButtonItemClick("AskClient")}
                                >
                                  Move to Ask Client
                                </MDButton>
                              )}
                             
                              {selectTab === "Unreconciled" && (
                                <Tooltip
                                  title={
                                    !showButton || checkIssue
                                      ? `Cannot move to Review until ${totalIssue} issues are resolved`
                                      : ""
                                  }
                                  placement="top"
                                >
                                  <MDBox>
                                    <MDButton
                                      variant="gradient"
                                      color="info"
                                      disabled={!showButton || checkIssue}
                                      onClick={() => onButtonItemClick("Reconciled")}
                                    >
                                      Move To Reconciled
                                    </MDButton>
                                  </MDBox>
                                </Tooltip>
                              )}
                              {selectTab === "Reconciled" && (
                                <Tooltip
                                  title={
                                    !showButton || checkIssue
                                      ? `Cannot move to Review until ${totalIssue} issues are resolved`
                                      : ""
                                  }
                                  placement="top"
                                >
                                  <MDBox>
                                    <MDButton
                                      variant="gradient"
                                      color="info"
                                      disabled={!showButton || checkIssue}
                                      onClick={() => onButtonItemClick("Unreconciled")}
                                    >
                                      Move To Unreconciled
                                    </MDButton>
                                  </MDBox>
                                </Tooltip>
                              )}
                              {selectTab === "ToReview" && (
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  disabled={ocrStatus}
                                  onClick={() => onButtonItemClick("PendingApproval")}
                                >
                                  Move to Pending Approval
                                </MDButton>
                              )}
                              {selectTab === "PendingApproval" && (
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  disabled={ocrStatus}
                                  onClick={() => onButtonItemClick("Approved")}
                                >
                                  Move to Approved
                                </MDButton>
                              )}
                            </MDBox>
                            <MDBox>
                              <Dialog sx={
                                    darkMode
                                      ? {
                                          "& .MuiDialog-container": {
                                            "& .MuiPaper-root": {
                                              width: "100%",
                                              height: 'auto',
                                              maxHeight: "73%",
                                              maxWidth: "650px",
                                              backgroundColor: "#202940 !important",
                                              color: "white !important",
                                            },
                                          },
                                        }
                                      : {
                                          "& .MuiDialog-container": {
                                            "& .MuiPaper-root": {
                                              width: "100%",
                                              height: 'auto',
                                              maxHeight: "73%",
                                              maxWidth: "650px",
                                              // maxHeight: "600px",
                                            },
                                          },
                                        }
                                  }
                                open={openAddNewInvoice} onClose={HandleCloseInvoice} fullWidth="true">
                                <DialogTitle
                                  sx={darkMode ? { justifyContent: "center", display: "flex", mt:1, color: "#ffffff"}
                                    : { justifyContent: "center", display: "flex", mt:1}
                                    }
                                >
                                  SELECT INVOICE
                                </DialogTitle>
                                <DialogContent sx={{overflow:'hidden'}} >
                                  <MDBox my={5} sx={{ mt: 1 }}>
                                    <MDBox>
                                      {loadingCircul && (
                                        <CircularProgress
                                          size={50}
                                          sx={{
                                            color: green[500],
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-12px",
                                            marginLeft: "-12px",
                                          }}
                                        />
                                      )}
                                    </MDBox>
                                    <MDBox display='flex' justifyContent='center' >
                                    <Autocomplete
                                        sx={
                                          darkMode
                                            ? {
                                                "& .MuiInput-underline": {
                                                  borderBottom: "2px solid #ffffff",
                                                },
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                  WebkitTextFillColor: "black",
                                                },
                                                "& .MuiInput-root .MuiInput-input": {
                                                  WebkitTextFillColor: "white",
                                                },
                                                "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator":
                                                  {
                                                    color: "white !important",
                                                  },
                                                "& .MuiInput-input": {
                                                  padding: `6px 4px 4px 0px !important`,
                                                },
                                                "& .MuiInputBase-input": {
                                                  height: `1.2em !important`,
                                                },
                                                "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator":
                                                  {
                                                    color: "white !important",
                                                  },
                                                  width:'300px'
                                              }
                                            : {
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                  WebkitTextFillColor: "black",
                                                },
                                                "& .MuiInput-input": {
                                                  padding: `6px 4px 4px 0px !important`,
                                                },
                                                "& .MuiInputBase-input": {
                                                  height: `1.2em !important`,
                                                },
                                                width:'300px'
                                              }
                                        }
                                        value={{ docNumber: searchInvoice }}
                                        options={statmentDocList}
                                        getOptionLabel={(option) => option.docNumber}
                                        onInputChange={(event, newInputValue) => {
                                          handleSearch(event, newInputValue);
                                        }}
                                        InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" } }}
                                        id="controllable-states-demo"
                                        disableCloseOnSelect={false}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Invoice"
                                            variant="standard"
                                            InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" } }}
                                          />
                                        )}
                                      />
                                    {/* <MDInput
                                        type="search"
                                        label="Search Invoice"
                                        onKeyDown={handleKeyDown}
                                        variant="outlined"
                                        onChange={handleSearch}
                                        InputLabelProps={{ style: darkMode ? { color: "#ffffff" } : {} }}
                                        InputProps={{
                                          inputProps: { style: darkMode ? { color: "#ffffff" } : { color: "#495057" }},
                                          style: { marginBottom: '16px' ,width: '350px'}
                                        }}
                                      /> */}
                                      </MDBox>
                                  {/* {searchInvoice && GetInvoiceListResponse?.data?.data?.totalRecords !== 0 ? 
                                    <DataTable
                                      table={dataTableData}
                                      entriesPerPage={true}
                                      pagination={true}
                                      showTotalEntries={true}
                                      isDailog={true}
                                      // response={tableEmpty}
                                      showCheckBoxs={false}
                                      ClientHeigth={true}
                                      // allrows={allRows} 
                                      allClients={selectAllcehck}
                                      assignedClients={assignedClients}
                                    />:''} */}
                                  </MDBox>
                                </DialogContent>
                                <DialogActions sx={{display:'flex' , justifyContent:'end'}}>
                                 <MDButton
                                    variant="gradient"
                                    color="light"
                                    onClick={HandleCloseInvoice}
                                  >
                                    Close
                                  </MDButton>
                                   <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                   {searchInvoice && <MDButton
                                      disabled={!searchInvoice}
                                      variant="gradient"
                                      color="info"
                                      onClick={handleAddNewInvoice}
                                    >
                                      {isDisable ? "Submitting..." : "Submit"}
                                    </MDButton>}
                                </MDBox>
                              </DialogActions>
                              </Dialog>
                            </MDBox>
                          <MDBox>
                          <Dialog
                            open={openEditItem}
                            onClose={()=>setOpenEditItem(false)}
                            sx={{
                              "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                  width: "100%",
                                  maxWidth: "570px",
                                  backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                                  color: darkMode ? "#ffffff" : "#202940",
                                },
                              },
                            }}
                          >
                            <DialogTitle
                              style={{
                                justifyContent: 'center',
                                display: 'flex',
                                color: darkMode ? '#ffffff' : '#7b809a',
                              }}
                            >
                            Edit Invoice
                            </DialogTitle>
                            <DialogContent>
                            <EditStatementInvoiceDialog
                                  noCategory={noCategory}
                                  businessId={businessId}
                                  itemListResponse={itemListResponse}
                                  currencySymbol={currencySymbol}
                                  editItemId={editItemId}
                                  invoiceId={invoiceId}
                                  itemList={itemList}
                                  itemId={itemId}
                                  onClose={()=>setOpenEditItem(false)}
                                />
                                </DialogContent>
                              </Dialog>
                            <Dialog
                              open={alert}
                              onClose={hideAlert}
                              aria-labelledby="alert-dialog-title"
                              sx={{
                                "& .MuiDialog-container": {
                                  "& .MuiPaper-root": {
                                    width: "100%",
                                    // height: "45%",
                                    maxWidth: "450px",
                                    backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                                    color: darkMode ? "#ffffff" : "#000000",
                                  },
                                },
                              }}
                            >
                              <DialogContent sx={{ overflowY: "hidden" }}>
                                <DialogContentText
                                  sx={{ display: "flex", justifyContent: "center", mt: 4 , color: darkMode ? "#ffffff" : "#000000",
                                  }}
                                >
                                  <h3>Are you sure?</h3>
                                </DialogContentText>
                                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                  <MDTypography
                                    variant="body2"
                                    color="secondary"
                                    sx={{
                                      fontWeight: 300,
                                      fontSize: "0.90rem",
                                      textAlign: "center",
                                      mt: 4,
                                      color: darkMode ? "#ffffff" : "#000000",
                                    }}
                                  >
                                    Once you delete this Item there is no way of getting any of the
                                    documents or data back.
                                  </MDTypography>
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
                                <MDButton variant="gradient" color="success" onClick={successDelete}>
                                  Yes, delete it!
                                </MDButton>
                                <MDButton variant="gradient" color="error" onClick={hideAlert}>
                                  Cancel
                                </MDButton>
                              </DialogActions>
                            </Dialog>
                          </MDBox>
                        </MDBox>
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        <MDBox>
                          {canAccess((actions = "create"), (resource = "comments")) && (
                            <Formik
                              initialValues={{ name: "" }}
                              // validationSchema={InvoiceEditSchema2}
                              onSubmit={async (values, actions) => {
                                setsubmitcomment(true);
                                SetonChanges(false);
                                var newStr = textAreaVal.replace(/@/g, "");
                                const newstr2 = newStr.replace(/\(/g, "{{").replace(/\)/g, "}}");
                                const newStr3 = newstr2.replace(/(?:\[.*?\]|[\[\]])/g, "");
                                await CreateComentsListAPICall({
                                  payload: [
                                    {
                                      documentId: `${invoice_id}`,
                                      businessId: `${businessId}`,
                                      comment: `${newStr3}`,
                                    },
                                  ],
                                });
                                setLoading(true);
                                setBtnDisable1(true);
                                setTimeout(() => {
                                  setsubmitcomment(false);
                                  setTextAreaVal("");
                                  actions.setSubmitting(false);
                                  actions.resetForm();
                                }, 1000);
                              }}
                            >
                              {(props) => (
                                <form onSubmit={props.handleSubmit}>
                                  <MentionsInput
                                    className="custom-mentions-input"
                                    style={mentionsInputStyle}
                                    placeholder="Leave Your Comment Here"
                                    value={textAreaVal}
                                    onChange={(e) => setTextAreaVal(e.target.value)}
                                    inputStyle={{
                                      color: darkMode ? "#ffffff" : "#000000", // Text color for dark mode
                                      "&::placeholder": {
                                        color: darkMode ? "#ffffff" : "#999999", // Custom placeholder color
                                        opacity: 1, // Ensure placeholder is fully opaque
                                      },
                                    }}
                                  >
                                  <Mention data={users} displayTransform={(id, display) => `@${display}`} />
                                  </MentionsInput>
                                  {textAreaVal.length === 0 && submitcomment === true && (
                                    <MDTypography
                                      sx={{ mt: 1 }}
                                      component="div"
                                      variant="caption"
                                      color="error"
                                      fontWeight="regular"
                                    >
                                      Comment is required.
                                    </MDTypography>
                                  )}
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{ mt: 2, display: "flex", justifyContent: "end" }}
                                  >
                                    <MDButton variant="gradient" color="info" type="submit">
                                      {btnDisable1 ? "Submiting..." : "Submit"}
                                    </MDButton>
                                  </Grid>
                                </form>
                              )}
                            </Formik>
                          )}
                        </MDBox>
                        {canAccess((actions = "read"), (resource = "comments")) && (
                          <>
                            <MDBox style={{ color: darkMode ? "#ffffff" : "" }}>
                              Comment History
                            </MDBox>
                            <MDBox my={3}>
                              {CommentsResponse && commentsList.length === 0 ? (
                                <MDTypography
                                  variant="body2"
                                  color="secondary"
                                  sx={
                                    darkMode
                                      ? {
                                          marginLeft: "43%",
                                          fontSize: "1.20rem",
                                          marginTop: "10%",
                                          color: "#ffffff",
                                        }
                                      : { marginLeft: "43%", fontSize: "1.20rem", marginTop: "10%" }
                                  }
                                >
                                  No Comments
                                </MDTypography>
                              ) : (
                                commentsList
                              )}
                            </MDBox>
                          </>
                        )}
                      </TabPanel>
                      <TabPanel value={value} index={3}>
                        <MDBox my={2} sx={{ mt: 2 }}>
                          {AuditLogResponse && auditLogList.length === 0 ? (
                            <MDTypography
                              variant="body2"
                              color="secondary"
                              sx={
                                darkMode
                                  ? {
                                      color: "#ffffff",
                                      marginLeft: "43%",
                                      fontSize: "1.20rem",
                                      marginTop: "10%",
                                    }
                                  : { marginLeft: "43%", fontSize: "1.20rem", marginTop: "10%" }
                              }
                            >
                              No Logs
                            </MDTypography>
                          ) : (
                            auditLogList
                          )}
                        </MDBox>
                      </TabPanel>
                    </CardContent>
                  </Card>
                  <MDBox>{loading && <LoaderDailog />}</MDBox>
              </Grid>
              </Grid>
         
              <MDBox>
                <Dialog
                  open={openAddOwner}
                  onClose={() => setOpenAddOwner(false)}
                  aria-labelledby="alert-dialog-title"
                  sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",
                        backgroundColor:darkMode ? '#202940 !important':'#ffffff',
                        color: darkMode ? '#ffffff':'#7b809a',
                        // height: "100%"
                      },
                    },
                  }}
                >
                  {" "}
                  <DialogTitle
                    sx={{
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    Add Owner For - {ownerName}
                  </DialogTitle>
                  <DialogContent> <AddOwnerDetailsDialog businessId={businessId} /> </DialogContent>
                </Dialog>
              </MDBox>
              <MDBox>
                <Dialog
                  open={alertDeleteInvoice}
                  onClose={hideInvoiceAlert}
                  aria-labelledby="alert-dialog-title"
                  sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "450px",
                        backgroundColor:darkMode ? '#202940 !important':'#ffffff',
                        color: darkMode ? '#ffffff':'#7b809a',
                      },
                    },
                  }}
                >
                  <DialogContent sx={{ overflowY: "hidden" }}>
                    <DialogContentText sx={{color: darkMode ? '#ffffff':'#7b809a', display: "flex", justifyContent: "center", mt: 4 }}>
                      <h3>Are you sure?</h3>
                    </DialogContentText>
                    <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                      <MDTypography
                        variant="body2"
                        color="secondary"
                        sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                      >
                        Once you delete this Invoice there is no way of getting any of the documents
                        or data back?
                      </MDTypography>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
                    <MDButton variant="gradient" color="success" onClick={successInoiceDelete}>
                      Yes, delete it!
                    </MDButton>
                    <MDButton variant="gradient" color="error" onClick={hideInvoiceAlert}>
                      Cancel
                    </MDButton>
                  </DialogActions>
                </Dialog>
              </MDBox>
              <MDBox>
                <Dialog
                  open={oneItemPopup}
                  onClose={() => setOneItemPopup(false)}
                  aria-labelledby="alert-dialog-title"
                  sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "450px",
                        backgroundColor:darkMode ? '#202940 !important':'#ffffff',
                        color: darkMode ? '#ffffff':'#7b809a',
                      },
                    },
                  }}
                >
                  <DialogContent sx={{ overflowY: "hidden" }}>
                    <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                      <MDTypography
                        variant="body2"
                        color="secondary"
                        sx={{ color: darkMode ? '#ffffff':'#7b809a',fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                      >
                        At least 1 item is required in the document.
                      </MDTypography>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOneItemPopup(false)}>Close</Button>
                  </DialogActions>
                </Dialog>
              </MDBox>
              <MDBox>
                <Dialog
                  open={alertMoveInvoice}
                  onClose={hideMoveAlert}
                  aria-labelledby="alert-dialog-title"
                  sx={
                    darkMode
                      ? {
                          "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                              width: "100%",
                              maxWidth: "500px",
                              backgroundColor: "#202940 !important",
                              color: "white !important",
                            },
                          },
                        }
                      : {
                          "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                              width: "100%",
                              // height: "40%",
                              maxWidth: "500px",
                            },
                          },
                        }
                  }
                >
                  <DialogContent>
                    <DialogContentText
                      sx={{
                        mt: 3,
                        display: "flex",
                        justifyContent: "center",
                        color: darkMode ? "#ffffff" : "#000000",
                      }}
                    >
                      <h3> Are you sure you want to move this invoice? </h3>
                    </DialogContentText>
                    <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                      <MDTypography
                        variant="body1"
                        color="info"
                        sx={{
                          p: 2,
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {splitCamelCaseToString(selectTab)}
                      </MDTypography>
                      <MDTypography
                        variant="body2"
                        color="#037ffd"
                        sx={{
                          fontSize: 40,
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {" "}
                        <DriveFileMoveIcon sx={{ color: "#037ffd", fontSize: 40 }} />
                      </MDTypography>
                      <MDTypography
                        variant="body1"
                        color="info"
                        sx={{
                          p: 2,
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {splitCamelCaseToString(tabsMove)}
                      </MDTypography>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ justifyContent: "center" }}>
                    <MDButton
                      variant="gradient"
                      color="success"
                      disabled={disableMoveButton}
                      onClick={successInoiceMove}
                    >
                      Yes, Move it!
                    </MDButton>
                    <MDButton
                      variant="gradient"
                      color="error"
                      disabled={disableMoveButton}
                      onClick={hideMoveAlert}
                    >
                      Cancel
                    </MDButton>
                  </DialogActions>
                </Dialog>
              </MDBox>
              <MDBox>
                <Modal
                  open={showApprovedModel}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <MDBox sx={{ pl: 0, pb: 5, mb: -1 }} display="flex" justifyContent="center">
                      <MDTypography component="span" variant="body2" color="dark">
                        To move this document you must delete it from your QBO account.
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={{
                        justifyContent: "end",
                        display: "flex",
                        mb: -2.5,
                      }}
                    >
                      <MDButton onClick={() => setShowApprovedModel(false)}>Close</MDButton>
                    </MDBox>
                  </Box>
                </Modal>
              </MDBox>
              <MDBox>
                <Dialog
                  open={openQboSyncAskClient}
                  onClose={closeMoveDialog}
                  aria-labelledby="alert-dialog-title"
                  sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",
                      },
                    },
                  }}
                >
                  <DialogContent>
                    <DialogContentText sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                      <h3>Are you sure want to proceed with sending them to </h3>
                    </DialogContentText>
                    <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                      <h3>the ask client?</h3>
                    </DialogContentText>
                    <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                      <MDTypography
                        variant="body1"
                        color="info"
                        sx={{
                          p: 2,
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {splitCamelCaseToString(selectTab)}
                      </MDTypography>
                      <MDTypography
                        variant="body2"
                        color="#037ffd"
                        sx={{
                          fontSize: 40,
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {" "}
                        <DriveFileMoveIcon sx={{ color: "#037ffd", fontSize: 40 }} />
                      </MDTypography>
                      <MDTypography
                        variant="body1"
                        color="info"
                        sx={{
                          p: 2,
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {splitCamelCaseToString("To Review")}
                      </MDTypography>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ justifyContent: "center" }}>
                    <MDButton variant="gradient" color="success" onClick={successInoiceMove}>
                      Yes, Move it!
                    </MDButton>
                    <MDButton variant="gradient" color="error" onClick={closeMoveDialog}>
                      Cancel
                    </MDButton>
                  </DialogActions>
                </Dialog>
              </MDBox>
              <MDBox>
                <CustomDialog DialogContentText={() => QboSyncMsg()}  open={openQboSync} height={"27%"} onClose={() => setOpenQboSync(false)} Title={""} button={"Close"} />
              </MDBox>
              <MDBox>
                {loadingCircul && ( <CircularProgress size={50} sx={{ color: green[500], position: "absolute", top: "50%",left: "50%", marginTop: "-12px", marginLeft: "-12px" }} /> )}
              </MDBox>
              <MDBox>
                <MDSnackbar color="error" icon="warning" title="Failed" content={ErrorMessages} open={showErrorMoveSnackbar}  close={() => setShowErrorMoveSnackbar(false)} />
              </MDBox>
              <MDBox>
                <MDSnackbar  color="success" icon="done" title="Success" content="The item has been assigned to client for review." open={showTrueSnackbar} close={() => setShowTrueSnackbar(false)} />
              </MDBox>
              <MDBox>
                <MDSnackbar  color="success"  icon="done" title="Success" content={"The item has been unassigned to client for review"} open={showFalseSnackbar} close={() => setShowFalseSnackbar(false)} />
              </MDBox>
              <MDBox>
                <MDSnackbar color="success" icon="done" title="Success" content={"Issue Reported Successfully"} open={showreportSnackbar} close={() => setShowReportSnackbar(false)} />
              </MDBox>
              <MDBox>
                <MDSnackbar color="error" icon="warning" title="Failed" content={ErrorMessagesVendorError} open={addVendoreerror} close={() => setAddVendoreerror(false)} />
              </MDBox>
              <MDBox>
                <MDSnackbar color="error" icon="warning" title="Failed." content={ErrorMessages} open={showErrorSnackbar}  close={() => setShowErrorSnackbar(false)}  />
              </MDBox>
              <MDBox>
                <CustomDialog2 DialogContentText={() => AsignNewClientMsg()} open={assignNewClient} onClose={() => setAssignNewClient(false)} onSuccess={successAssignNewClient} Title={""} center={true} height={"27%"} buttonOk={"Assign client"} button={"Cancel"} />
              </MDBox>
            </MDBox>
          </>
        )}
      </>
    );
  }

export default EditStatementDialog;