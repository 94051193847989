import { Box, MenuItem, Pagination, PaginationItem, Select } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MDBox from "components/MDBox";
import MDPagination from "components/MDPagination";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import PropTypes from "prop-types";
import * as React from 'react';
import { useEffect, useMemo, useState } from "react";
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { eventBus } from 'utils/eventbus';
import DataTableBodyCell from "./Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "./Tables/DataTable/DataTableHeadCell";

function DataTable({ allrows,  assignedClients , entriesPerPage, canSearch, showTotalEntries, table, pagination, isSorted, noEndBorder, response, selectTab, showCheckBoxs, showCheck, footer, ClientHeigth}) {
    const limit = localStorage.getItem("PerPagelimit")
    const defaultValue = limit ? limit : 10;
    const [newpageIndex, setNewpageIndex] = React.useState(0);
    const [selectAllButton, setSelectAllButton] = useState(false);
    const [selectTotalShow, setSelectTotalShow] = useState(defaultValue);
    const [showFooter , setShoweFooter] = useState(true);
    const entries = entriesPerPage.entries ? entriesPerPage.entries.map((el) => el.toString()) : ["10", "20", "50", "100"];
    const columns = useMemo(() => table.columns, [table]);
    const data = useMemo(() => table.rows, [table]);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    let pageOptions1 = [];
    let pageOptionsFirst = [];
    useEffect(()=>{
        // console.log("footer",footer)
        if(footer == false){
            setShoweFooter(false)
        }
    },[footer])

    useEffect(() => {
        localStorage.setItem("limit", Number(pageSize));

        setSelectTotalShow(10);
        const ThisOffsethandle = localStorage.getItem("offset")
        if (newpageIndex === 0) {
            setNewpageIndex(0)
            localStorage.setItem("offset", 0)
            localStorage.setItem("newpageIndex", 0)
        } else {
            const num = ThisOffsethandle.toString();
            const str = num.split('').reverse().join('')
            const result = str.replace(/^0+/, '');
            // localStorage.setItem("newpageIndex", Number(ThisOffsethandle == 0 ? 0 : result))
            setNewpageIndex(Number(ThisOffsethandle == 0 ? 0 : result))
        }
    }, [])

    eventBus.$on('showSelectTabs', (data) => showSelectTabs(data));
    function showSelectTabs(data) {
        setNewpageIndex(0);
    }
    const totalRecords = response
    for (let i = 0; i < totalRecords;) {
        i = i + Number(selectTotalShow)
        pageOptionsFirst.push(i);
    }
    pageOptionsFirst.map((prop, key) => {
        const num = prop.toString();
        if (num >= 100) {
            const str = num.substring(0, num.length - 1);
            pageOptions1.push(str - 1)
        } else {
            const str = num.split('').reverse().join('')
            const result = str.replace(/^0+/, '');
            pageOptions1.push(result - 1)
        }
    })

    eventBus.$on('MoveMenuResponseComplete', (data) => MoveMenuResponseComplete(data));
    function MoveMenuResponseComplete(data) {
        const finaldata = (Number(data) - 10).toString()
        const str = finaldata.split('').reverse().join('')
        const result = str.replace(/^0+/, '');
        setNewpageIndex(Number(result));
    }

    eventBus.$on('showInvoiceStatus', (data) => showInvoiceStatus(data));
    function showInvoiceStatus(data) {
        const found1 = data?.find(obj => {
            return obj.ocrStatus === 'INPROGRESS';
        });
        if (found1) {
            setSelectAllButton(true);
        } else {
            setSelectAllButton(false);
        }
    }

    const tableInstance = useTable({ columns, data, selectTab, initialState: { pageIndex: 0 } }, useGlobalFilter, useSortBy, usePagination, useRowSelect, );

    const { getTableProps, getTableBodyProps,headerGroups,prepareRow, rows, setPageSize, setGlobalFilter, selectedFlatRows, state: { pageIndex, pageSize, globalFilter, selectedRowIds }, onPageChange = (eventBus.$emit('ChangePaginationPage', pageIndex)),} = tableInstance;
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedIdsForAllPage, setSelectedIdsForAllPage] = useState([]);
    const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
    // Set the default value for the entries per page when component mounts
    useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);
    useEffect(() => {
        var tempId = [];
        tempId = selectedFlatRows.map( d => d.original.id)
        // setSelectedRows(tempId);
        eventBus.$emit('clientRowSelected', selectedIds);
    }, [selectedIds]);

    const setEntriesPerPage = (value) => setPageSize(value);

    const [pages, setPages] = useState(1);

    const ThisOffsetuse = localStorage.getItem("offset");
    const finalPage = ThisOffsetuse == 0 ? 1 : pages;
    const isFirstPage = finalPage === 1;

    const handleRowSelect = (id) => {
        // Retrieve existing selected IDs from localStorage
        const IsOldSelected = localStorage.getItem('selectedIdsForAllPage');
        
        // Parse the stored string into an array
        let selectedIdsForAllPage = IsOldSelected ? IsOldSelected.split(',') : [];
    
        setSelectedIds((prevSelected) => {
            if (prevSelected.includes(id)) {
                // If the ID is already selected, remove it from the selected state
                const updatedSelected = prevSelected.filter((clientId) => clientId !== id);
                
                // Also remove the ID from localStorage's selectedIdsForAllPage
                selectedIdsForAllPage = selectedIdsForAllPage.filter((clientId) => clientId !== id);
                localStorage.setItem('selectedIdsForAllPage', selectedIdsForAllPage);
    
                return updatedSelected;
            } else {
                // If the ID is not already selected, add it to the selected state
                const updatedSelected = [...prevSelected, id];
    
                // Also add the ID to localStorage's selectedIdsForAllPage
                selectedIdsForAllPage.push(id);
                localStorage.setItem('selectedIdsForAllPage', selectedIdsForAllPage);
    
                return updatedSelected;
            }
        });
    };
    

    useEffect(() => {
        const initialSelected = assignedClients.map((client) => client._id);
        setSelectedIds(initialSelected);
      }, [assignedClients]);

    const handleSelectAll = () => {
        if (isSelectAllChecked) {
            // Get existing IDs from localStorage
            const IsOldSelected = localStorage.getItem('selectedIdsForAllPage');
            
            if (IsOldSelected) {
                // Parse the stored value into an array
                const oldSelectedIds = IsOldSelected.split(',');
        
                // Map the current rows to get allIds
                const allIds = rows.map(row => row.original.id);
        
                // Filter out the IDs that are present in allIds from oldSelectedIds
                const updatedSelectedIds = oldSelectedIds.filter(id => !allIds.includes(id));
        
                // Update localStorage and state
                localStorage.setItem('selectedIdsForAllPage', updatedSelectedIds);
                setSelectedIds(updatedSelectedIds);
            }
        
            setIsSelectAllChecked(false);
        } else {
            // Select all rows
            const IsOldSelected = localStorage.getItem('selectedIdsForAllPage');
            if (IsOldSelected) {
                // Convert the comma-separated string into an array
                const oldSelectedIds = IsOldSelected.split(',');
              
                // You can now work with oldSelectedIds as an array
                // Example: Combine with new IDs from the current rows
                const allIds = rows.map(row => row.original.id);
              
                // Combine and remove duplicates if needed
                const combinedIds = [...new Set([...oldSelectedIds, ...allIds])];
                localStorage.setItem('selectedIdsForAllPage',[combinedIds]);
                setIsSelectAllChecked(true);
                setSelectedIds(combinedIds);
              }else{
                const allIds = rows.map(row => row.original.id);
                localStorage.setItem('selectedIdsForAllPage',[allIds]);
                setSelectedIdsForAllPage(allIds);
                // console.log("allIds",allIds)
                setSelectedIds(allIds);
            }
           
            setIsSelectAllChecked(true);
        }
    };

    useEffect(() => {
        eventBus.$emit('selectedRowIds', selectedIds);
    }, [selectedIds]);

    let entriesEnd;

    if (pageIndex === 0) {
        entriesEnd = pageSize;
    } else if (pageIndex === pageOptions1.length - 1) {
        entriesEnd = rows.length;
    } else {
        entriesEnd = pageSize * (pageIndex + 1);
    }

    const handleChange = (e, p) => {
        // setIsSelectAllChecked(false);
        setPages(p);
        const dataStr = (p - 1).toString() + 0
        localStorage.setItem("offset", Number(dataStr))
        eventBus.$emit('onclickselectclientPagechnage', Number(p - 1)) || setNewpageIndex(Number(p - 1))
    };

    const changePerPageEntries = (value) => {
        setNewpageIndex(0)
        setEntriesPerPage(parseInt(value, 10));
        setSelectTotalShow(value);
        eventBus.$emit('onclickselectclientchangeTotalPerPage', Number(value))
    };

    return (
        <TableContainer sx={{ boxShadow: "none" ,backgroundColor: darkMode ? '#202940' : '#ffffff',height:'53vh' }} >
            <Table {...getTableProps()}>
                <MDBox component="thead">
                    {headerGroups.map((headerGroup) => (
                        <TableRow
                            {...headerGroup.getHeaderGroupProps()}
                            style={{
                                position: "sticky",
                                top: 0,
                                backgroundColor: darkMode ? "#202940" : "#ffffff",
                                zIndex: 100,
                            }}
                        >
                            {showCheckBoxs && (
                                <DataTableHeadCell  align="center" width="50px">
                                <Checkbox
                                    indeterminate={
                                        selectedIds.filter(id => rows.map(row => row.original.id).includes(id)).length > 0 &&
                                        selectedIds.filter(id => rows.map(row => row.original.id).includes(id)).length < rows.length
                                    }
                                    checked={
                                        selectedIds.filter(id => rows.map(row => row.original.id).includes(id)).length === rows.length
                                    }
                                    onChange={handleSelectAll}
                                    />
                                </DataTableHeadCell>
                            )}
                            {headerGroup.headers.map((column) => (
                                <DataTableHeadCell
                                {...column.getHeaderProps()}
                                width={column.width ? column.width : "auto"}
                                align={showCheckBoxs === false ? "left" : "center"}
                                sorted={false}
                                >
                                {column.render("Header")}
                                </DataTableHeadCell>
                            ))}
                        </TableRow>
                    ))}
                </MDBox>
                <TableBody {...getTableBodyProps()}>
                    {rows.map((row, key) => {
                        prepareRow(row);
                        return (
                        <TableRow {...row.getRowProps()} key={key}>
                            {/* Individual Row Checkbox */}
                            {showCheckBoxs && (
                            <DataTableBodyCell showCheckBoxs={showCheckBoxs} align="center" width="50px">
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Checkbox
                                    checked={selectedIds.includes(row.original.id)}
                                    onChange={() => handleRowSelect(row.original.id)}
                                />
                                </div>
                            </DataTableBodyCell>
                            )}
                            {row.cells.map((cell) => (
                            <DataTableBodyCell
                                noBorder={false}
                                align={showCheckBoxs === false ? "left" : "center"}
                                {...cell.getCellProps()}
                                key={cell.column.id}
                            >
                                {cell.render("Cell")}
                            </DataTableBodyCell>
                            ))}
                        </TableRow>
                        );
                    })}
                    {showFooter == true ? 
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            p={!showTotalEntries && pageOptions1.length === 1 ? 0 : 3}
                            right='33%'
                            position="fixed"  // Use "fixed" positioning
                            bottom='10%'        // Stick to the bottom of the viewport
                            zIndex={1000}     // Ensure it's above other content if necessary
                            bgcolor= {darkMode ? "#202940" :"background.paper"}
                            width="652px"
                            borderTop="1px solid #ddd"
                            height="48px"
                        >
                            <MDBox display="flex" alignItems="center" justifyContent="center" mt={2} mr={1.2}  mb={4}>
                                {response >= 1 && (
                                    <MDBox display="flex" alignItems="center" mt={2} mr={1.2}>
                                    <Select
                                        value={pageSize.toString()} // Current value
                                        onChange={(event) => {
                                            changePerPageEntries(event.target.value); // Handle selection change
                                        }}
                                        size="small"
                                        sx={{
                                            width: "5rem", // Input width
                                            backgroundColor:'#ffffff',
                                            textAlign: 'center', // Center the selected value
                                            borderRadius: "8px",
                                            '& .MuiSvgIcon-root': {
                                                color: '#000000 !important', // Custom arrow icon color
                                            },
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    width: "5rem", // Match the width of the input
                                                    backgroundColor: 'white', // Set dropdown background color to white
                                                },
                                            },
                                            anchorOrigin: {
                                                vertical: 'top', // Anchor the dropdown at the top of the select
                                                horizontal: 'center',
                                            },
                                            transformOrigin: {
                                                vertical: 'bottom', // Transform the dropdown to appear from the bottom
                                                horizontal: 'center',
                                            },
                                        }}
                                        disableUnderline
                                    >
                                    {entries.map((entry) => (
                                        <MenuItem key={entry} value={entry}  sx={{ textAlign: 'center' }}>
                                            {entry}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                        <MDTypography variant="caption" color={darkMode ? "light" :"secondary"}>
                                            {/* &nbsp;&nbsp;entries */}
                                        </MDTypography>
                                    </MDBox>
                                )}
                                {pageOptions1.length > 1 && (
                                    <MDPagination
                                        variant={pagination.variant ? pagination.variant : "gradient"}
                                        color={pagination.color ? pagination.color : "info"}
                                    >
                                        {
                                            <Pagination
                                            page={finalPage}
                                            color="primary"
                                            sx={{ justifyContent: "center", marginTop: 2, mr: 1.2 }}
                                            onChange={handleChange}
                                            count={pageOptionsFirst.length}
                                            renderItem={(item) => (
                                            <>
                                                {item.type === 'previous' && isFirstPage ? null : (
                                                <PaginationItem
                                                    {...item}
                                                    sx={{
                                                        color: item.type === 'page' ? "#237dea" : '#237dea', // Apply color only to page numbers
                                                        border: item.type === 'page' ? "1px solid #d9d9d9" : 'none', // Add border only to page numbers
                                                        margin: "0 5px", // Adds spacing between items
                                                        borderRadius: "4px", // Optional for rounded borders
                                                         "&.Mui-selected": {
                                                           backgroundColor: "#237dea",
                                                           color: "white",
                                                         },
                                                         "&&.Mui-selected:hover": {
                                                          backgroundColor: item.type === 'page' ? "#237dea" : 'inherit', // Match hover color with selected color
                                                          color: item.type === 'page' ? "white" : '#237dea', // Match hover text color with selected text color
                                                        }
                                                       }}
                                                    components={{
                                                        next: () => (
                                                            <>
                                                              {item.type === 'next' && finalPage !== pageOptionsFirst.length && 'Next'}
                                                            </>
                                                          ),
                                                    previous: () => <>{item.type === 'previous' && 'Previous'}</>,
                                                    }}
                                                />
                                                )}
                                            </>
                                            )}
                                        />
                                        }
                                    </MDPagination>
                                )}
                                {response >= 1 &&
                                    <MDBox sx={{ mr: 1.2, mb: -1.5 }}>
                                        <MDTypography variant="button" color={darkMode ? "light" :"secondary"} fontWeight="regular">
                                            Showing {(newpageIndex) == 0 ? '1' : (finalPage * Number(selectTotalShow)) - Number(selectTotalShow)} to {response < finalPage * Number(selectTotalShow) ? response : finalPage * Number(selectTotalShow)} of {response} entries
                                        </MDTypography>
                                    </MDBox>}
                            </MDBox>
                        </Box>:''
                    }
                </TableBody>
            </Table>
            {response == 0 && 
                <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ marginLeft: "43%", fontSize: "1.20rem", marginTop: "15%", marginBottom: "5%" }}
                    >
                    No records found
                </MDTypography>
            }
        </TableContainer >
    );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
    entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
    canSearch: false,
    showTotalEntries: true,
    pagination: { variant: "gradient", color: "info" },
    isSorted: false,
    noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
    entriesPerPage: PropTypes.oneOfType([
        PropTypes.shape({
            defaultValue: PropTypes.number,
            entries: PropTypes.arrayOf(PropTypes.number),
        }),
        PropTypes.bool,
    ]),
    canSearch: PropTypes.bool,
    showTotalEntries: PropTypes.bool,
    table: PropTypes.objectOf(PropTypes.array).isRequired,
    pagination: PropTypes.shape({
        variant: PropTypes.oneOf(["contained", "gradient"]),
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
    }),
    isSorted: PropTypes.bool,
    noEndBorder: PropTypes.bool,
};

export default DataTable;
